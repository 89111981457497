import { Button, Card, Col, Input, Modal, Pagination, Row, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import BannerComponent from '../../components/banner'
import Navbar from '../../components/navbar'
import PowerBy from '../../components/powerby'
import Finisherwall from './components/finisherwall'
import PodiumChart from './components/podium_chart'
import { Img } from 'react-image'

import loadingPic from './../../assets/images/Infinity.gif'
import errorPic from './../../assets/images/500error.gif'

import { axiosInstant } from '../../../service/axios'
import './style.css';
import moment from 'moment'

const { Option } = Select

export default function Home() {

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [search, setSearch] = useState("");
  const [gender, setGender] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rankData, setRankData] = useState([]);
  const [allRankData, setAllRankData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [allImageData, setAllImageData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dataLength, setDataLength] = useState(0)
  const [allLength, setAllLength] = useState(0)
  const [imageLimit, setImageLimit] = useState(14);
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [dateCount, setDateCount] = useState(1)



  const handleGenderChange = (e) => {
    setGender(e);
    setRankData(pagination(searchData(allRankData, e, search), checkCurrent(currentPage, e, search), pageSize));
    setDataLength(searchData(allRankData, e, search).length);
  }

  const checkCurrent = (current_page, gender, keyword) => {
    return current_page > searchData(allRankData, gender, keyword).length / pageSize ? Math.ceil((searchData(allRankData, gender, keyword).length / pageSize)) : current_page;
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setRankData(pagination(searchData(allRankData, gender, e.target.value), checkCurrent(currentPage, gender, e.target.value), pageSize));
    setDataLength(searchData(allRankData, gender, e.target.value).length);
  }

  const searchData = (data, gender, keyword) => {
    return searchByName(searchByGender(data, gender), keyword);
  }

  const searchByGender = (data, gender) => {
    if (gender === "ALL") {
      return data;
    }
    return data.filter(val => val.gender === gender);
  }

  const searchByName = (data, keyword) => {
    if (keyword === "") {
      return data;
    }
    return data.filter(val => val.first_name.match(keyword) || val.last_name.match(keyword) || val.nick_name.match(keyword) || val.bib_id.match(keyword));
  }

  const handleShowSizeChange = (current, page_size) => {
    setRankData(pagination(searchData(allRankData, gender, search), checkCurrent(current, gender, search), page_size));
  }

  const handleChangePage = (current, page_size) => {
    setRankData(pagination(searchData(allRankData, gender, search), checkCurrent(current, gender, search), page_size));
  }

  const fetchData = () => {
    Promise.all([axiosInstant.get(`/runnings`), axiosInstant.get(`/runnings/no/chart`), axiosInstant.get(`/runners/km`), axiosInstant.get(`/runnings/images`)]
    ).then(([{ data: { runs } }, { data }, { data: { KM } }, { data: { images } }]) => {
      setRankData(pagination(addRanking(runs), currentPage, pageSize));
      setAllRankData(addRanking(runs));
      setChartData(data.runs);
      setIsLoading(false);
      setDataLength(runs.length);
      setAllLength(KM.total_km);
      setAllImageData(images);
      setImageData(images.filter((image, key) => key < imageLimit));
    }).catch(error => {
      setIsError(true)
      setIsLoading(false);
    })
  }

  const addRanking = (data) => {
    return data.map((val, index) => {
      return { ...val, ranking: index + 1 }
    })
  }

  const pagination = (data, current, size) => {
    setCurrentPage(current === 0 ? 1 : current);
    setPageSize(size);
    return data.filter((val, index) => ((index < size * (current === 0 ? 1 : current)) && (index >= size * ((current === 0 ? 1 : current) - 1))));
  }

  const loadImageMore = () => {
    setImageData(allImageData.filter((image, key) => key < (imageLimit + 14)));
    setImageLimit(imageLimit + 14);
  }

  const handleClickImage = (image) => {
    setModalImage(image);
    setShowImageModal(true);
  }

  const countDate = () => {
    var given = moment("2021-03-31", "YYYY-MM-DD");
    var current = moment().startOf('day');

    //Difference in number of days
    return Math.abs(moment.duration(given.diff(current)).asDays());

  }

  useEffect(() => {
    fetchData();
    setDateCount(countDate());
  }, [])

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <BannerComponent />
      <Navbar />

      {
        isError &&
        <div>
          <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
        </div>
      }

      {
        isLoading &&
        <div>
          <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
        </div>
      }

      {
        !isLoading && !isError &&
        <>
          <Row style={{ marginTop: "50px" }} type="flex" justify="center" >
            <Col lg={18} xs={22}>
              <Card bodyStyle={{ paddingInline: "5px" }}>
                <PodiumChart data={chartData} />
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }} type="flex" justify="center" >
            <Col lg={6} xs={22}>
              <Card title="ຈຳນວນຄົນລົງທະບຽນ" style={{ marginBlock: "10px", marginRight: isMobile ? "0" : "10px" }}>
                <Title style={{ textAlign: "center" }} level={3}>{allRankData.length} ຄົນ</Title>
              </Card>
            </Col>
            <Col lg={6} xs={22}>
              <Card title="ໄລຍະທາງລວມ" style={{ marginBlock: "10px", marginInline: isMobile ? "0" : "5px" }}>
                <Title style={{ textAlign: "center" }} level={3}>{allLength} Km</Title>
              </Card>
            </Col>
            <Col lg={6} xs={22}>
              <Card title="ໄລຍະເວລາ" style={{ marginBlock: "10px", marginLeft: isMobile ? "0" : "10px" }}>
                <Title style={{ textAlign: "center" }} level={3}>{dateCount} ມື້</Title>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "50px" }} type="flex" justify="center" >
            <Col lg={18} xs={22}>
              <Row justify="end">
                <Col>
                  <Input.Group compact>
                    <Select defaultValue={gender} onChange={handleGenderChange} style={{ width: isMobile ? "20%" : "7%" }}>
                      <Option value="ALL">ທັງໝົດ</Option>
                      <Option value="FEMALE">ຍິງ</Option>
                      <Option value="MALE">ຊາຍ</Option>
                    </Select>
                    <Input style={{ width: isMobile ? "80%" : "30%" }}
                      placeholder="ຄົ້ນຫາຊື່-ນາມສະກຸນ/ເລກບິບ"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Input.Group>
                </Col>
              </Row>
              <Card style={{ marginBlock: "10px" }} bodyStyle={{ padding: "0" }}>
                <Finisherwall data={rankData} />
              </Card>
              <Row type="flex" justify="center">
                <Col>
                  <Pagination
                    current={currentPage}
                    total={dataLength} size={isMobile ? "small" : ""}
                    pageSize={pageSize}
                    showSizeChanger
                    onShowSizeChange={handleShowSizeChange}
                    onChange={handleChangePage}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "40px" }} type="flex" justify="center">
                {imageData.map((image, key) => {
                  return (
                    <Col className="imageContainner" key={key} xl={3} lg={3} md={7} sm={10} xs={10} style={{ margin: "5px" }}
                      onClick={() => handleClickImage(image.image)}
                    >
                      <Img src={image.image} style={{ width: "100%", height: "auto", maxHeight: "250px", minHeight: "250px", objectFit: "cover" }} />
                      <div
                        className="overlayImage"
                      >
                        <span style={{ fontSize: isMobile ? "10pt" : "12pt" }}> BIB : {image.bib_id}</span><br />
                        <span style={{ fontSize: isMobile ? "10pt" : "12pt" }}> ໄລຍະທາງ : {image.distance} Km</span>
                      </div>
                    </Col>
                  )
                })}
              </Row>
              <Modal
                visible={showImageModal}
                onCancel={() => { setShowImageModal(false) }}
                okButtonProps={{ hidden: true }}
              >
                <img style={{ width: '100%' }} src={modalImage} alt="" />
              </Modal>
              {
                imageData.length < allImageData.length &&
                <Row type="flex" justify="center">
                  <Col>
                    <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '40px', borderRadius: '5px' }}
                      htmlType="button"
                      onClick={loadImageMore}
                    >
                      <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ເພີ່ມເຕີມ / More</span>
                    </Button>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </>
      }
      <PowerBy />
    </div >
  )
}
