import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";
import { axiosInstant } from "./../../../service/axios";
import HeaderBar from "./HeaderBar";
import SideBar from "./SideBar";
import Users from "./user/userIndex";
import Coupon from './coupon/CouponIndex';
import Sizes from './shirt/ShirtSize'
import Distance from "./distance/Distance";
import Registration from "./registration/Registration";
import ReportRegister from "./report/ReportRegister";
import Dashboard from "./dashboard/Dashboard";
const { Content } = Layout;
class Admin extends Component {
  state = {
    user: {},
    isLoad: true
  };
  componentDidMount() {
    axiosInstant
      .get("/users/profile")
      .then(({data: {user}}) => {
        localStorage.setItem("id", user.id);
        localStorage.setItem("first_name", user.username)
        this.setState({ user: user, isLoad: true });
      })
      .catch(e => {
        // localStorage.clear();
        // this.props.history.push("/nimda/login");
      });
  }
  render() {
    let style = {
      marginLeft: 200
    };
    if (this.props.collapsed) {
      style = {
        marginLeft: 80
      };
    }
    return (
      <>
        {this.state.isLoad && (
          <Layout style={{ minHeight: "100vh", fontFamily: "Phetsarath OT" }}>
            <SideBar user={this.state.user}/>
            <Layout id="content" style={style}>
              <HeaderBar {...this.props} state={this.state.user} />
              <Content
                style={{
                  margin: 24
                }}
              >
                <Switch>
                  <Route path="/nimda/" exact={true} component={Dashboard} />
                  <Route path="/nimda/dashboard" exact component={Dashboard} />
                  <Route path="/nimda/users" exact component={Users} />
                  {/* <Route path="/admin/users/add" exact component={AddUser}/> */}
                  <Route path="/nimda/tshirt_sizes" exact component={Sizes} />
                  <Route path="/nimda/coupons" exact component={Coupon} />
                  <Route path="/nimda/distances" exact component={Distance} />
                  <Route path="/nimda/registrations" exact component={Registration} />
                  <Route path="/nimda/report_registers" exact component={ReportRegister} />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        )}
      </>
    );
  }
}
const mapStateToProp = state => {
  return {
    collapsed: state.collapsed
  };
};
export default connect(mapStateToProp)(Admin);
