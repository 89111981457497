import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import Image from 'react-image-webp'
import BannerComponent from '../../components/banner'
import { isMobile } from 'react-device-detect'

import logo_bcelpng from './../../assets/images/logo_bcel.png'
import logo_bcelwebp from './../../assets/images/logo_bcel.webp'
// import kcbuildingpng from './../../assets/images/KC_building.png'
// import kcbuildingwebp from './../../assets/images/KC_building.webp'

import { changeCurrent } from './../registration/actions'

class PaymentMethod extends Component {

  openOnepay = () => {

    const code = this.props.payment.paymentDetails.book.id
    const path = `/invoices?bookingcode=${code}`
    setTimeout(() => { window.location.href = path }, 1000)
  }


  render() {

    // const { code } = this.props.payment.paymentDetails

    // const path = `/invoices?bookingcode=${code}`

    return (
      <div>
        <BannerComponent />

        <div className="mg-top-20 mg-bottom-20">
          <Row type="flex" justify="center">
            <Col>
              <span className="form-title-blue txt-center">ຮູບແບບຊຳລະເງິນ / Payment Method</span>
            </Col>
          </Row>
        </div>

        <div className="container-round-border mg-top-20 mg-left-50 mg-right-50" style={{ padding: '20px' }}>

          <Row type="flex" justify="center">
            <div className="container-round-border" style={{ boxShadow: '3px 3px', padding: '10px', width: '50vw' }}>
              <Row type="flex" justify="center">
                <span className="txt-header-bold txt-center txt-bold"><u>ຊໍາລະເງິນຜ່ານ BCEL OnePay / Payment via BCEL OnePay</u></span>
              </Row>

              <Row type="flex" align="middle" justify="center" style={{ padding: '20px' }}>
                {!isMobile &&
                  <div style={{ backgroundColor: '#ffffff', }}>
                    <Row align="middle">
                      <Image
                        src={logo_bcelpng}
                        webp={logo_bcelwebp}
                        alt="BCEL OnePay"
                        style={{ width: '5vw', height: '5vw' }}
                      />
                    </Row>
                  </div>
                }

                {isMobile &&
                  <div style={{ backgroundColor: '#ffffff', }}>
                    <div style={{ width: '5vw', height: '5vw' }}>
                      <Image
                        src={logo_bcelpng}
                        webp={logo_bcelwebp}
                        alt="BCEL OnePay"
                        style={{ width: '5vw', height: '5vw' }}
                      />
                    </div>
                  </div>
                }
              </Row>
              <Row type="flex" justify="center" style={{ padding: '10px' }}>
                <Button style={{ backgroundColor: '#de224c', boxShadow: '2px 2px' }} htmlType="submit" onClick={() => this.openOnepay()}>
                  <span className="txt-header txt-center txt-white">ກົດເພື່ອຮັບ QR Code / Generate QR Code</span>
                </Button>
              </Row>
            </div>
          </Row>

          <div style={{ paddingTop: "25px" }}>
            <Row type="flex" justify="center" className="powerby-label">
              <span style={{ color: '#707070' }}>
                ຂໍອະໄພ ເນື່ອງຈາກທາງເຮົາບໍ່ມີນະໂຍບາຍໃນການຄືນເງິນ ພາຍຫຼັງທີ່ທ່ານຊໍາລະເງິນແລ້ວ.
              </span>
            </Row>
            <Row type="flex" justify="center" className="powerby-label">
              <span style={{ color: '#707070' }}>
                Sorry, we don't have a refund policy after you make a payment.
              </span>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  changeCurrent,
}

const mapStateToProps = (state) => ({
  payment: state.payment
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)