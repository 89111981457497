import * as actionTypes from './../../../constants/actionTypes'

export const register = (formvalue) => ({
  type: actionTypes.REGISTRATION,
  payload: formvalue
})

export const race = (race) => ({
  type: actionTypes.RACE,
  payload: race
})

export const finisher_shirt_size = (finisher_shirt_size) => ({
  type: actionTypes.FINISHER_SHIRT_SIZES,
  payload: finisher_shirt_size
})

export const t_shirt_size = (t_shirt_size) => ({
  type: actionTypes.T_SHIRT_SIZES,
  payload: t_shirt_size
})

export const savePanes = (panes) => ({
  type: actionTypes.SAVE_PANES,
  payload: panes
})

export const enableNext = (enable) => ({
  type: actionTypes.ENABLENEXT,
  payload: enable
})

export const changeCurrent = (current) => ({
  type: actionTypes.CHANGE_AFTERSUBMIT,
  payload: current
})

export const saveRaceCate = (raceCate) => ({
  type: actionTypes.SAVERACECATE,
  payload: raceCate
})