import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

// import Approot
import AppRoot from './AppRoot';

// imported css
// import './index.css'
import './App.css'
import 'antd/dist/antd.css';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import './registration/assets/css/text_style.css'
import './registration/assets/css/default_regis.css'
import logo from './registration/assets/images/sponsor/lao_virtual_run_logo.png';
import logoWebp from './registration/assets/images/sponsor/lao_virtual_run_logo.webp';
import Image from 'react-image-webp'

ReactDOM.render(<Image
    src={logo}
    webp={logoWebp}
    alt="LaosVirtualRun"
    style={{ width: '50%', height: 'auto', display: "block", marginLeft: "auto", marginRight: "auto" }} />
    , document.getElementById('root'));

setTimeout(() => {
    ReactDOM.render(<AppRoot />, document.getElementById('root'));
}, 1000)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
