import React from "react";
import { Col, Row, Icon, Typography, Card } from "antd";
import { axiosInstant } from "./../../../../service/axios";
import { numberFormat } from "./../../../assets/js/custom";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import moment from "moment";
import loadingPic from '../../../assets/images/Infinity.gif'
import errorPic from '../../../assets/images/500error.gif'
import { isMobile } from "react-device-detect";

const { Title, Paragraph } = Typography;
class Dashboard extends React.Component {
  state = {
    totalRegister: null,
    totalRegisterPayment: null,
    totalRegisterUseCoupon: null,
    totalTotalMoney: null,
    payment: null,
    labelBar: [],
    labelCheckInbar: [],
    countDataBar: [],
    countCheckIn10KmBar: [],
    countCheckIn5KmBar: [],
    countCheckIn21KmBar: [],
    paymentStatus: {
      Pending: 0,
      Success: 0,
      Denied: 0,
      Complete: 0
    },
    paymentStatusName: {
      Pending: "ລໍຖ້າການຈ່າຍເງິນ",
      Success: "ຈ່າຍເງິນສຳເລັດ",
      Denied: "Confirm",
      Complete: "ສຳເລັດແລ້ວ",
      BIB: "ຮັບບິບແລ້ວ"
    },
    paymentStatusColor: {
      Pending: "",
      Success: "",
      Denied: "",
      Complete: "",
      BIB: ""
    },
    man: 0,
    woman: 0,
    labelBikePrice: [],
    countDataBikePirce: [],
    colors: [],
    labelFinisherShirtSize: [],
    labelTShirtSize: [],
    countDataFinisherShirtSize: [],
    countDataTShirtSize: [],
    sizecolor: [],
    labelCoupon: [],
    countActive: [],
    countUsed: [],
    countExpired: [],
    labelAge: [],
    countAge: [],
    checkedInTrue: 0,
    checkedInFalse: 0,
    isLoading: true,
    isError: false
  };
  sortObject = o => {
    var sorted = {},
      key,
      a = [];

    for (key in o) {
      if (o.hasOwnProperty(key)) {
        a.push(key);
      }
    }

    a.sort();
    for (key = 0; key < a.length; key++) {
      sorted[a[key]] = o[a[key]];
    }
    return sorted;
  };
  paymentStatus = status => {
    let name = "",
      statusName = "";
    switch (status) {
      case "Pending":
        name = "ລໍຖ້າການຊຳລະເງິນ";
        statusName = "Pending";
        break;
      case "Success":
        name = "ຈ່າຍເງິນແລ້ວ";
        statusName = "Success";
        break;
      case "Denied":
        name = "Confirm";
        statusName = "Denied";
        break;
      case "Complete":
        statusName = "Complete";
        name = "ສຳເລັດແລ້ວ";
        break;
      case "BIB":
        statusName = "BIB";
        name = "ຮັບບິບແລ້ວ";
        break;
      default:
        break;
    }
    return { name: name, status: statusName, counting: 1 };
  };
  randomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };
  componentDidMount() {
    const report = '/books';
    Promise.all([axiosInstant.get(report), axiosInstant.get('/coupons')])
      .then(([{ data: { books } }, { data: { coupons } }]) => {
        const getData = books.filter(books => books.status !== "Cancel").reduce((prev, cur) => {
          const { status, created_at, updated_at, id } = cur;
          const newItem = { attendee: cur.attendee, status, created_at, updated_at, id }
          return prev.concat(newItem)
        }, [])
        const reportRegister = getData.filter(item => {
          return item.status !== "Cancel";
        });
        let money = 0,
          payment = 0,
          labelBar = [],
          labelCheckInbar = [],
          countDataBar = [],
          countCheckIn10KmBar = [],
          countCheckIn21KmBar = [],
          countCheckIn5KmBar = [],
          checkDay = [],
          paymentStatus = {
            Pending: 0,
            Success: 0,
            // Denied: 0,
            Complete: 0,
            BIB: 0
          },
          paymentStatusColor = {
            Pending: "",
            Success: "",
            // Denied: "",
            Complete: "",
            BIB: ""
          },
          paymentStatusName = {
            Pending: "ລໍຖ້າການຈ່າຍເງິນ",
            Success: "ຈ່າຍເງິນສຳເລັດ",
            // Denied: "ຈ່າຍເງິນຖືກປະຕິເສດ",
            Complete: "ສຳເລັດແລ້ວ",
            BIB: "ຮັບບິບແລ້ວ"
          },
          woman = 0,
          man = 0,
          checkBikePrice = [],
          labelBikePrice = [],
          countDataBikePirce = [],
          color = [],
          inStatus = ["Success", "Pending", "Denied", "Complete", "BIB"],
          checkTShirtSize = [],
          checkFinisherShirtSize = [],
          countDataFinisherShirtSize = [],
          countDataTShirtSize = [],
          labelFinisherShirtSize = [],
          labelTShirtSize = [],
          sizecolor = [],
          labelAge = [],
          countAge = [],
          checkedInTrue = 0,
          checkedInFalse = 0;
        getData.forEach(item => {
          // console.log(item);
          // Loop
          money += // all money
            item.status !== "Cancel" &&
              item.status !== "Denied"
              ? item.attendee.race !== undefined
                ? item.attendee.race.price
                : 0 : 0;

          payment += // payment
            (item.status !== "Pending" &&
              item.status !== "Cancel" &&
              item.status !== "Denied")
              ? item.attendee.race !== undefined
                ? item.attendee.race.price
                : 0
              : 0;
          /* date register */
          let day = moment(item.created_at).format("DD/MM");
          let checkInclude = labelBar.includes(day);
          if (!checkInclude) {
            labelBar.push(day);
            countDataBar["day" + day] = 1;
          } else {
            countDataBar["day" + day] = countDataBar["day" + day] + 1;
          }


          // console.log(item);
          let checkInDay = moment(item.updated_at).format("DD/MM");
          checkInclude = checkDay.includes(checkInDay);

          if (item.status === "Complete"
            && checkInDay !== day) {
            if (!checkInclude) {
              labelCheckInbar.push(checkInDay);
              checkDay.push(checkInDay);
              countCheckIn10KmBar["10Km" + checkInDay] = 0;
              countCheckIn21KmBar["21Km" + checkInDay] = 0;
              countCheckIn5KmBar["5Km" + checkInDay] = 0;

              if (item.attendee.race.name === "10 KM") {
                countCheckIn10KmBar["10Km" + checkInDay] = 1;
              } else if (item.attendee.race.name === "21 KM") {
                countCheckIn21KmBar["21Km" + checkInDay] = 1;
              } else {
                countCheckIn5KmBar["5Km" + checkInDay] = 1;
              }

            } else {
              if (item.attendee.race.name === "10 KM") {
                countCheckIn10KmBar["10Km" + checkInDay] += 1;
              } else if (item.attendee.race.name === "21 KM") {
                countCheckIn21KmBar["21Km" + checkInDay] += 1;
              } else {
                countCheckIn5KmBar["5Km" + checkInDay] += 1;
              }
            }

          }

          /* date register */

          /* payment status */
          const resultPaymentStatus = this.paymentStatus(item.status);
          paymentStatus[resultPaymentStatus.status] +=
            resultPaymentStatus.counting;
          paymentStatusName[resultPaymentStatus.status] =
            resultPaymentStatus.name;
          paymentStatusColor[resultPaymentStatus.status] = this.randomColor();
          /* payment status */

          /* Gender */
          if (item.attendee.gender === "FEMALE") {
            woman += 1;
          } else {
            man += 1;
          }
          /* Gender */
          /* Bike Price */
          let checkStatus = inStatus.includes(item.status);
          if (checkStatus) {
            if (item.attendee.race !== undefined) {
              let categoryType = item.attendee.race.name;
              checkInclude = checkBikePrice.includes(categoryType);
              if (!checkInclude) {
                color.push(this.randomColor());

                checkBikePrice.push(categoryType);
                labelBikePrice.push(categoryType);
                countDataBikePirce["price" + categoryType] = 1;
              } else {
                countDataBikePirce["price" + categoryType] =
                  countDataBikePirce["price" + categoryType] + 1;
              }
            }

            // Count Finisher shirt : 
            let finisherShirtSize = item.attendee.shirt_size.name_finisher;
            checkInclude = checkFinisherShirtSize.includes(finisherShirtSize);
            if (!checkInclude) {
              sizecolor.push(this.randomColor());
              checkFinisherShirtSize.push(finisherShirtSize);
              labelFinisherShirtSize.push(item.attendee.shirt_size.name_finisher);
              countDataFinisherShirtSize["size" + finisherShirtSize] = 1;
            } else {
              countDataFinisherShirtSize["size" + finisherShirtSize] =
                countDataFinisherShirtSize["size" + finisherShirtSize] + 1;
            }

            // Count Tshirt : 
            let tShirtSize = item.attendee.shirt_size.name_tshirt;
            checkInclude = checkTShirtSize.includes(tShirtSize);
            if (!checkInclude) {
              sizecolor.push(this.randomColor());
              checkTShirtSize.push(tShirtSize);
              labelTShirtSize.push(item.attendee.shirt_size.name_tshirt);
              countDataTShirtSize["size" + tShirtSize] = 1;
            } else {
              countDataTShirtSize["size" + tShirtSize] =
                countDataTShirtSize["size" + tShirtSize] + 1;
            }


          }
          /* Bike Price */
          /* Age Register */
          let age = this.getAge(moment(), moment(item.attendee.birth_day));
          checkInclude = labelAge.includes("ອາຍຸ-" + age);
          if (!checkInclude) {
            labelAge.push("ອາຍຸ-" + age);
            countAge["age" + age] = 1;
          } else {
            countAge["age" + age] = countAge["age" + age] + 1;
          }
          /* Age Register */

          /* CheckedIn */
          if (item.status === "Complete" || item.status === "BIB") {
            checkedInTrue++;
          } else {
            checkedInFalse++;
          }
          /* CheckedIn */
        });

        let checkCouponName = [],
          labelCoupon = [],
          countActive = [],
          countUsed = [],
          checkInclude,
          countExpired = [];
        coupons.forEach(item => {
          let couponName = item.name + " -" + item.discount;
          checkInclude = checkCouponName.includes(couponName);
          if (!checkInclude) {
            checkCouponName.push(couponName);
            labelCoupon.push(couponName);
            countActive["active" + couponName] = 0;
            countUsed["used" + couponName] = 0;
            countExpired["expired" + couponName] = 0;
            if (
              moment().format("YYYY-MM-DD HH:mm:s") <
              moment(item.expired_at).format("YYYY-MM-DD HH:mm:s") && item.used_at === null
            ) {
              countActive["active" + couponName] = 1;
            } else if (item.used_at !== null) {
              countUsed["used" + couponName] = 1;
            } else {
              countExpired["expired" + couponName] = 1;
            }
          } else {
            if (
              moment().format("YYYY-MM-DD HH:mm:s") <
              moment(item.expired_at).format("YYYY-MM-DD HH:mm:s") && item.used_at === null
            ) {
              countActive["active" + couponName] =
                countActive["active" + couponName] + 1;
            } else if (item.used_at !== null) {
              countUsed["used" + couponName] =
                countUsed["used" + couponName] + 1;
            } else {
              countExpired["expired" + couponName] =
                countExpired["expired" + couponName] + 1;
            }
          }
        });
        this.setState({
          totalRegister: reportRegister.length,
          totalRegisterPayment: reportRegister.filter(val => (val.status !== "Pending" && val.status !== "Denied" && val.status !== "Cancel") && val.attendee.race.price !== 0).length,
          totalRegisterUseCoupon: reportRegister.filter(val => (val.status !== "Pending" && val.status !== "Denied" && val.status !== "Cancel") && (val.attendee.race.price === 0 || val.attendee.race.price < 330000)).length,
          totalTotalMoney: money,
          payment: payment,
          labelBar: labelBar,
          labelCheckInbar: labelCheckInbar,
          countDataBar: countDataBar,
          countCheckIn10KmBar: countCheckIn10KmBar,
          countCheckIn21KmBar: countCheckIn21KmBar,
          countCheckIn5KmBar: countCheckIn5KmBar,
          paymentStatusName: paymentStatusName,
          paymentStatus: paymentStatus,
          paymentStatusColor: paymentStatusColor,
          woman: woman,
          man: man,
          labelBikePrice: labelBikePrice,
          countDataBikePirce: Object.assign({}, countDataBikePirce),
          colors: color,
          labelTShirtSize: labelTShirtSize,
          labelFinisherShirtSize: labelFinisherShirtSize,
          countDataFinisherShirtSize: countDataFinisherShirtSize,
          countDataTShirtSize: countDataTShirtSize,
          colorSize: sizecolor,
          labelCoupon: labelCoupon,
          countActive: Object.assign({}, countActive),
          countUsed: Object.assign({}, countUsed),
          countExpired: Object.assign({}, countExpired),
          labelAge: labelAge.sort(),
          countAge: this.sortObject(countAge),
          checkedInTrue: checkedInTrue,
          checkedInFalse: checkedInFalse,
          isLoading: false
        });
      }).catch(error => {
        this.setState(
          {
            isError: true,
            isLoading: false
          }
        )
      })

  }
  render() {

    const dataBar = {
      labels: this.state.labelBar,
      datasets: [
        {
          label: "ຈຳນວນ",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: Object.values(this.state.countDataBar)
        }
      ]
    };
    const allPayment = {
      labels: Object.values(this.state.paymentStatusName),
      datasets: [
        {
          data: Object.values(this.state.paymentStatus),
          backgroundColor: Object.values(this.state.paymentStatusColor),
          hoverBackgroundColor: Object.values(this.state.paymentStatusColor)
        }
      ]
    };

    const dataCouponBar = {
      labels: this.state.labelCoupon,
      datasets: [
        {
          label: "Active",
          backgroundColor: "rgba(50,255,100,0.2)",
          borderColor: "rgba(50,255,100,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(50,255,100,0.4)",
          hoverBorderColor: "rgba(50,255,100,1)",
          data: Object.values(this.state.countActive)
        },
        {
          label: "Used",
          backgroundColor: "rgba(0,99,132,0.2)",
          borderColor: "rgba(0,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(0,99,132,0.4)",
          hoverBorderColor: "rgba(0,99,132,1)",
          data: Object.values(this.state.countUsed)
        },
        {
          label: "Expired",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: Object.values(this.state.countExpired)
        }
      ]
    };

    const dataGender = {
      labels: ["ເພດຊາຍ", "ເພດຍິງ"],
      datasets: [
        {
          data: [this.state.man, this.state.woman],
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"]
        }
      ]
    };

    const dataTShirtSize = {
      labels: this.state.labelTShirtSize,
      datasets: [
        {
          data: Object.values(this.state.countDataTShirtSize),
          backgroundColor: this.state.colorSize,
          hoverBackgroundColor: this.state.colorSize
        }
      ]
    };

    const dataFinisherShirtSize = {
      labels: this.state.labelFinisherShirtSize,
      datasets: [
        {
          data: Object.values(this.state.countDataFinisherShirtSize),
          backgroundColor: this.state.colorSize,
          hoverBackgroundColor: this.state.colorSize
        }
      ]
    };

    const dataAge = {
      labels: this.state.labelAge,
      datasets: [
        {
          label: "ຈຳນວນ",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: Object.values(this.state.countAge)
        }
      ]
    };

    const { isLoading, isError } = this.state;

    return (
      <>
        {
          isError &&
          <div>
            <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
          </div>
        }

        {
          isLoading &&
          <div>
            <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
          </div>
        }

        {
          !isLoading && !isError &&
          <>
            <Row gutter={16}>
              <Col className="guttar-row" lg={8} md={8} sm={12} xs={24}>
                <div className="wiget">
                  <div className="wiget-icon">
                    <Icon type="user" style={{ fontSize: "50px" }} />
                  </div>
                  <div className="wiget-body">
                    <Title level={4}>ລົງທະບຽນທັງໝົດ</Title>
                    <Paragraph className="wiget-number">
                      {this.state.totalRegister}
                    </Paragraph>
                  </div>
                </div>
              </Col>
              <Col className="guttar-row" lg={8} md={8} sm={12} xs={24}>
                <div className="wiget">
                  <div className="wiget-icon">
                    <Icon type="dollar" style={{ fontSize: "50px" }} />
                  </div>
                  <div className="wiget-body">
                    <Title level={4}>ເງິນທີ່ຈະໄດ້ຮັບທັງໝົດ</Title>
                    <Paragraph className="wiget-number">
                      {numberFormat(this.state.totalTotalMoney)}
                    </Paragraph>
                  </div>
                </div>
              </Col>
              <Col className="guttar-row" lg={8} md={8} sm={12} xs={24}>
                <div className="wiget">
                  <div className="wiget-icon">
                    <Icon type="user" style={{ fontSize: "50px" }} />
                  </div>
                  <div className="wiget-body">
                    <Title level={4}>ລົງທະບຽນທີ່ໄດ້ຮັບເງິນແລ້ວ</Title>
                    <Paragraph className="wiget-number">
                      {this.state.totalRegisterPayment}
                    </Paragraph>
                  </div>
                </div>
              </Col>
              <Col className="guttar-row" lg={8} md={8} sm={12} xs={24}>
                <div className="wiget">
                  <div className="wiget-icon">
                    <Icon type="user" style={{ fontSize: "50px" }} />
                  </div>
                  <div className="wiget-body">
                    <Title level={4}>ເງິນທີ່ໄດ້ຮັບຈິງທັງໝົດ</Title>
                    <Paragraph className="wiget-number">
                      {numberFormat(this.state.payment)}
                    </Paragraph>
                  </div>
                </div>
              </Col>
              <Col className="guttar-row" lg={8} md={8} sm={12} xs={24}>
                <div className="wiget">
                  <div className="wiget-icon">
                    <Icon type="user" style={{ fontSize: "50px" }} />
                  </div>
                  <div className="wiget-body">
                    <Title level={4}>ລົງທະບຽນດ້ວຍຄູປ໋ອງ</Title>
                    <Paragraph className="wiget-number">
                      {this.state.totalRegisterUseCoupon}
                    </Paragraph>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={16} style={{ margin: "10px 0" }}>
              <Col className="guttar-row" lg={24}>
                <Card title="ລາຍການຜູ່ສະໝັກປະຈຳວັນ">
                  <Bar
                    data={dataBar}
                    width={100}
                    height={265}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </Card>
              </Col>
              <Col className="guttar-row" lg={12} md={12} sm={24} xs={24}>
                <Card title="ສະຖານະການຈ່າຍເງິນ">
                  <Doughnut data={allPayment} />
                </Card>
              </Col>
              <Col className="guttar-row" lg={12} md={12} sm={24} xs={24}>
                <Card title="ຈຳນວນເພດ">
                  <Doughnut data={dataGender} />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ margin: "10px 0" }}>
              <Col className="guttar-row" lg={12} md={12} sm={24} xs={24}>
                <Card title="ເສື້ອ Finisher">
                  <Pie data={dataFinisherShirtSize} />
                </Card>
              </Col>
              <Col className="guttar-row" lg={12} md={12} sm={24} xs={24}>
                <Card title="ເສື້ອແລ່ນ">
                  <Pie data={dataTShirtSize} />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ margin: "10px 0" }}>
              <Col className="guttar-row" lg={24}>
                <Card title="ອາຍຸຜູ່ສະໝັກ">
                  <Bar
                    data={dataAge}
                    width={100}
                    height={265}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ margin: "10px 0" }}>
              <Col className="guttar-row" lg={24}>
                <Card title="ລາຍການໃຊ້ງານຄູປ໋ອງທັງໝົດ">
                  <Bar
                    data={dataCouponBar}
                    width={100}
                    height={265}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </>
        }
      </>
    );
  }
}
export default Dashboard;
