import React from 'react'

import { Col, Descriptions, Row } from 'antd'

import moment from 'moment'
import { isMobile } from 'react-device-detect'

export const Summary = ({
  firstname,
  lastname,
  nick_name,
  gender,
  dateofbirth,
  contactnumber,
  email,
  nationality,
  identity,
  address,
  city,
  province,
  country,
  finisher,
  tshirt,
  race
  // classlabel
}) => {
  // console.log(dateofbirth);
  return (
    <Row type="flex" justify="center">
      <Col>
        {
          !isMobile &&
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="ຊື່ / First Name">
              <b>{firstname}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ນາມສະກຸນ / Last Name">
              <b>{lastname}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຊື່ທີ່ສະແດງໃນ BIB / Name in BIB">
              <b>{nick_name}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເພດ / Gender">
              <b>{gender}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ວັນເດືອນປີເກີດ / Date of Birth">
              <b>{moment(dateofbirth).format('DD / MM / YYYY')}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເບີໂທຕິດຕໍ່ / Contact Number">
              <b>{contactnumber}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ອີເມລ / E-Mail">
              <b>{email}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ສັນຊາດ / Nationality">
              <b>{nationality}</b>
            </Descriptions.Item>

            <Descriptions.Item label="ທີ່ຢູ່ / Address">
              <b>{address}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເມືອງ / City">
              <b>{city}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ແຂວງ / Province">
              <b>{province}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ປະເທດ / Country">
              <b>{country}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເລກບັດ ຫຼື ພາສປອດ / ID or Passport">
              <b>{identity}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຂະໜາດເສື້ອ Finisher / Finisher Shirt size" span={3}>
              <b>{finisher}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຂະໜາດເສື້ອແລ່ນ / Running-Shirt size">
              <b>{tshirt}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ໄລຍະທາງ / Distance">
              <b>{race}</b>
            </Descriptions.Item>
          </Descriptions>

        }

        {
          isMobile &&
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="ຊື່ / First Name">
              <b>{firstname}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ນາມສະກຸນ / Last Name">
              <b>{lastname}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຊື່ທີ່ສະແດງໃນ BIB / Name in BIB">
              <b>{nick_name}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເພດ / Gender">
              <b>{gender}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ວັນເດືອນປີເກີດ / Date of Birth">
              <b>{moment(dateofbirth).format('DD / MM / YYYY')}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເບີໂທຕິດຕໍ່ / Contact Number">
              <b>{contactnumber}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ອີເມລ / E-Mail">
              <b>{email}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ປະເທດ / Country">
              <b>{country}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ສັນຊາດ / Nationality">
              <b>{nationality}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ແຂວງ / Province">
              <b>{province}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເມືອງ / City">
              <b>{city}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ທີ່ຢູ່ / Address">
              <b>{address}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ເລກບັດ ຫຼື ພາສປອດ / ID or Passport">
              <b>{identity}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຂະໜາດເສື້ອ Finisher / Finisher Shirt size">
              <b>{finisher}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ຂະໜາດເສື້ອແລ່ນ / Running-Shirt size">
              <b>{tshirt}</b>
            </Descriptions.Item>
            <Descriptions.Item label="ໄລຍະທາງ / Distance">
              <b>{race}</b>
            </Descriptions.Item>
          </Descriptions>
        }
      </Col>
    </Row>
  )
}