import React, { Component } from 'react';

import { Row, Col } from 'antd'
import Image from 'react-image-webp'
// import litd_logo from './../assets/images/sponsor/logo-03.png'
// import litd_logo_webp from './../assets/images/sponsor/logo-03.webp'
import footer from './../assets/images/banner/LVR_FOOTER.png'
import footer_webp from './../assets/images/banner/LVR_FOOTER.webp'
import { isMobile } from 'react-device-detect';

// import { isMobile } from 'react-device-detect';

class PowerBy extends Component {

  render() {
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#fff", paddingBlock: "30px", marginTop: "20px" }}>
          <Row type="flex" justify="center" className="mg-top-20">
            <Col span={24}>
              <Row type="flex" justify="center">
                <span style={{ fontSize: isMobile ? "11pt" : "16pt", fontWeight: "bold", color: "#de224c" }}>ຕິດຕໍ່</span>
              </Row>
              <Row type="flex" justify="center">
                <span style={{ fontSize: isMobile ? "11pt" : "16pt", fontWeight: "bold", color: "#de224c" }}>Contact</span>
              </Row>

              <Row type="flex" justify="center" className="mg-top-20">
                <span className="txt-header txt-center"><b>Whatsapp</b>: <a href="https://wa.me/8562098772363" target="_blank" rel="noopener noreferrer" >020 98772363</a> </span>
              </Row>

              <Row type="flex" justify="center">
                <span
                  className="txt-header txt-center"
                >
                  <b>Facebook Page</b>: <a href="https://www.facebook.com/Laos-Virtual-Run-104632875004309" target="_blank" rel="noopener noreferrer">Laos Virtual Run</a>
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <span className="form-label-10">Copyright © <a href="https://laoitdev.com/" target="_blank" rel="noopener noreferrer">LAOITDEV</a></span>
          </Row>
        </div>

        <Image
          src={footer}
          webp={footer_webp}
          alt="Lao IT Development Co., Ltd."
          style={{ width: '100%', height: 'auto' }}
        />

      </React.Fragment >
    )
  }
}

export default PowerBy