import React, { Component } from 'react'
import BannerComponent from '../../components/banner'
import { Row, Button, Col, Input } from 'antd'
import PowerBy from '../../components/powerby'
import './qr.css'
import history from '../../../history'
import { isMobile } from 'react-device-detect'
import { axiosInstant } from '../../../service/axios'
import { OnePay } from '../../assets/js/onepay'
import bg_onepay from '../../assets/images/onepay_bg.png'

// import moment from "moment";


class QR_NormalPage extends Component {
  state = {
    books: '',
    paid_data: {},
    disabled: false,
    qr_img_path: '',
    coupon: {},
    isLoading: true,
    hasCoupon: false,
    isFreeCoupon: false,
    total_price: 0,
    discount: 0,
    couponValue: '',
    couponStatus: '',
    emv_code: '',
    falsed: false,
  }

  check = () => {
    history.push({
      pathname: '/checkpayment',
      state: { books: this.state.books }
    })
  }

  couponChange = (e) => {
    this.setState({
      couponValue: e.target.value
    })
  }

  onCouponClick = (e) => {

    this.setState({
      loading: true,
      isCouponsubmit: true
    }, () => {
      this.setState({
        qr_data: {}
      })

      if (this.state.couponValue !== '') {

        Promise.all([axiosInstant.get(`/books/${this.state.books}`),
        axiosInstant.get(`/coupons/${this.state.couponValue}`),
        axiosInstant.get(`/books/${this.state.books}/qr-code?couponCode=${this.state.couponValue === undefined ? ''
          : this.state.couponValue}`)
        ])
          .then(([{ data: { book } }, { data: { coupon } }, { data }]) => {
            if (coupon.type === "FREE") {
              this.setState({ isFreeCoupon: true, isLoading: false, })
            }
            else {
              this.setState({
                paid_data: book,
                isLoading: false,
                total_price: book.attendee.race.price - (coupon.type === "PERCENT" ? (book.attendee.race.price * (coupon.discount / 100)) : coupon.discount),
                hasCoupon: true,
                isFreeCoupon: false,
                coupon,
                discount: (coupon.type === "PERCENT" ? (book.attendee.race.price * (coupon.discount / 100)) : coupon.discount)
              })

              if (data !== null) {
                this.setState({
                  emv_code: data.emv_code,
                  qr_img_path: `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${data.emv_code}&choe=UTF-8`
                })
              }
            }

          }).catch((err) => {
            console.log(err);
            if (err) {
              this.setState({ couponStatus: 'faild', isFreeCoupon: false, })
            }
          })

      }
    })
  }

  onConfirmFreeCoupon = () => {

    var qry_params = window.location.search
    var qry = new URLSearchParams(qry_params)
    var books = qry.get('bookingcode')

    axiosInstant.post(`/books/approve`, { book_id: books, coupon_code: this.state.couponValue }).then((res) => {
      if (res.status === 204) {
        setTimeout(
          () => {
            window.location
              .assign(`http://lvr.ewent.la/checkpayment?bookingcode=${books}`)
          }
          , 2000)
      }
      else {

      }
    })
  }

  cancleRegister() {
    localStorage.removeItem("bookingCode");
    history.push({
      pathname: '/'
    })
  }


  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };



  componentDidMount = () => {
    var qry_params = window.location.search
    var qry = new URLSearchParams(qry_params)
    var books = qry.get('bookingcode')

    axiosInstant
      .get(`/books/${books}/qr-code?couponCode=${this.state.couponValue === undefined ? '' : this.state.couponValue}`)
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            books: books,
            emv_code: res.data.emv_code,
            qr_img_path: `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${res.data.emv_code}&choe=UTF-8`,
            falsed: false
          })

        }
      }).catch((err) => {
        if (err) {
          this.setState({ falsed: true })
        }
      })


    axiosInstant.get(`/books/${books}`).then((res) => {
      if (res.data !== null) {
        if (res.data.book.status === "Success") {
          localStorage.clear("bookingCode")
          setTimeout(
            () => { window.location.assign(`https://lvr.ewent.la/checkpayment?bookingcode=${books}`) }
            , 2000)
        }
        else {
          this.setState({
            paid_data: res.data.book,
            isLoading: false,
            total_price: res.data.book.attendee.race.price
          })
          localStorage.setItem("bookingCode", books)
        }
      }
    }).catch((err) => {
      if (err) {
        this.setState({ falsed: true })
      }
    })

    const mcid = 'mch5fa4f6d2ced76'; // merchant id
    const uuid = books; // transaction id (please define as unique key)
    const onePay = new OnePay(mcid); // create new OnePay instance

    onePay.debug = false; // enable OnPay debug(Please looking on console log)

    /* define subscribe parameter(s) */
    const subParams = {
      uuid: uuid, // please specified uuid if would like to receive callback data only the transaction (for front-end subscribe)
    };
    /* subscribe to receiving OnePay callback*/
    onePay.subscribe(subParams, function (res) {
      setTimeout(
        () => { window.location.assign(`https://lvr.ewent.la/checkpayment?bookingcode=${books}`) }
        , 2000)
    })

  }

  componentWillUnmount = () => {
    // localStorage.clear()
  }

  render() {

    const { paid_data, isFreeCoupon, total_price } = this.state
    return (
      < div >
        <BannerComponent />
        {!this.state.isLoading && (
          <div className="container-round-border mg-left-20 mg-right-20" style={{ padding: '20px' }}>

            <Row type="flex" justify="center">
              <span style={{ color: "#de224c", fontSize: "14pt", fontWeight: "bold" }}>
                ສະແກນ QR Code ເພື່ອຊຳລະຜ່ານ BCEL Onepay
                </span>
            </Row>
            {/* <br /> */}

            <Row type="flex" justify="center">
              <span style={{ color: "#de224c", fontSize: "16pt", fontWeight: "bold" }}>
                ລາຄາ {Intl.NumberFormat('ja-JP').format(this.state.paid_data.attendee.race.price)} ກີບ</span>
            </Row>

            {/* <br /> */}
            <br />
            {this.state.hasCoupon && (
              <Row type="flex" justify="center">
                <span style={{ color: "black", fontSize: "14pt" }} >ຮັບສ່ວນຫຼຸດ {Intl.NumberFormat('ja-JP').format(this.state.discount)} ກີບ</span>
              </Row>
            )}

            <hr style={{ width: "50%" }} />

            <Row type="flex" justify="center">
              <span style={{ color: "black", fontSize: isMobile ? "12pt" : "18pt", color: "#de224c" }} >{paid_data.attendee.first_name} {paid_data.attendee.last_name}</span>
            </Row>

            {/* <Row type="flex" justify="center">
              <span style={{ color: "black" }} >{paid_data.attend,color:"#de224c"ee.shirt_size} ; {paid_data.attendee.race.name} ; {paid_data.attendee.race.name === "5 KM" ? "ບໍ່ຈຳກັດຊ່ວງອາຍຸ"
                : this.getAge(moment(), moment(paid_data.attendee.birth_day)) >= 16 && this.getAge(moment(), moment(paid_data.attendee.birth_day)) <= 29 ? "16-29 ປີ"
                  : this.getAge(moment(), moment(paid_data.attendee.birth_day)) >= 30 && this.getAge(moment(), moment(paid_data.attendee.birth_day)) <= 39 ? "29-39 ປີ"
                    : this.getAge(moment(), moment(paid_data.attendee.birth_day)) >= 40 && this.getAge(moment(), moment(paid_data.attendee.birth_day)) <= 49 ? "39-49 ປີ"
                      : this.getAge(moment(), moment(paid_data.attendee.birth_day)) >= 50 ? "50+" : "ອາຍຸບໍ່ຖືກຕ້ອງ"}</span>
            </Row> */}
            <br />
            {
              !isMobile && !isFreeCoupon &&
              <Row type="flex" justify="center">
                <span style={{ fontWeight: 'bold', fontSize: "20pt", color: "#de224c" }}>ລວມເປັນເງິນທັງໝົດ {Intl.NumberFormat('ja-JP').format(total_price)} ກີບ</span>
              </Row>
            }

            {
              isMobile && !isFreeCoupon &&
              <Row type="flex" justify="center">
                <span style={{ fontWeight: 'bold', fontSize: "12pt", color: "#de224c" }}>ລວມເປັນເງິນທັງໝົດ {Intl.NumberFormat('ja-JP').format(total_price)} ກີບ</span>
              </Row>
            }

            <Row>
              <Row type="flex" justify="center">
                {
                  !isMobile &&
                  <Col xs={20} sm={20} md={8} lg={8} xl={8}>
                    <Input type="text" name="coupon"
                      placeholder="ປ້ອນລະຫັດສ່ວນຫຼຸດ / Discount Coupon (ຖ້າມີ / if have)"
                      onChange={(e) => this.couponChange(e)}
                      style={{ fontFamily: 'Noto Sans Lao Defago', fontSize: 'calc(0.5em + 0.5vw)' }}
                    />
                    <Input
                      style={{ backgroundColor: "#de224c", color: "#fff" }}
                      type="submit" value="ນຳໃຊ້ / USE"
                      onClick={(e) => this.onCouponClick(e)}
                    />
                  </Col>
                }
                {
                  isMobile &&
                  <Row type="flex" justify="center">
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Input type="text" name="coupon" placeholder="ປ້ອນລະຫັດສ່ວນຫຼຸດ / Discount Coupon (ຖ້າມີ / if have)" allowClear onChange={(e) => this.couponChange(e)} style={{ lineHeight: '20px', fontFamily: 'Noto Sans Lao Defago', fontSize: 'calc(0.5em + 0.5vw)' }} />
                    </Col>
                    <Row type="flex" justify="center" style={{ marginTop: '5px' }}>
                      <Button onClick={(e) => this.onCouponClick(e)} style={{ backgroundColor: '#de224c', height: isMobile ? '40px' : '40px', borderRadius: '10px' }} >
                        <span style={{ color: '#fff', fontFamily: 'Noto Sans Lao Defago', fontSize: 'calc(0.5em + 0.8vw)' }}>ນຳໃຊ້ / USE</span>
                      </Button>
                    </Row>
                  </Row>
                }
              </Row>

            </Row>


            <br />
            {isFreeCoupon && (
              <>
                <Row type="flex" justify="center">
                  <span style={{ color: "green", fontSize: "14pt" }} >
                    ຄູປ໋ອງນີ້ແມ່ນຄູປ໋ອງຟຣີ ກະລຸນາກົດຢືນຢັນການນຳໃຊ້ຄູປ໋ອງເພື່ອສຳເລັດການຊຳລະເງິນ
                  </span>
                </Row>

                <Row type="flex" justify="center" gutter={48}>
                  <Col>
                    <Button
                      onClick={() => { this.onConfirmFreeCoupon() }}
                      style={{
                        backgroundColor: 'green', width: 'auto', height: '50px', borderRadius: '10px'
                      }}
                    >
                      <span className="white-txt txt-bold txt-submit-btn">
                        ຢືນຢັນການນຳໃຊ້ຄູປ໋ອງ
                      </span>
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            {this.state.couponStatus === "used" && (
              <Row type="flex" justify="center">
                <span style={{ color: "black", fontSize: "14pt" }} >ຂໍອະໄພ ຄູປ໋ອງຖືກໃຊ້ແລ້ວ!!!</span>
              </Row>
            )}

            {this.state.couponStatus === "faild" && (
              <Row type="flex" justify="center">
                <span style={{ color: "black", fontSize: "14pt" }} >ຂໍອະໄພ ຄູປ໋ອງບໍ່ຖືກຕ້ອງ!!!</span>
              </Row>
            )}

            {!isFreeCoupon && (
              <>
                <Row type="flex" justify="center">
                  <span>LAO IT DEVELOPMENT-E</span>
                </Row>
                <Row type="flex" justify="center">
                  <div className="qr-img" style={{ backgroundImage: "url(" + bg_onepay + ")", backgroundSize: "cover" }}>
                    <img style={{ width: "70%", paddingTop: "45%", paddingBottom: "20%", marginInline: "auto", display: "block" }} src={this.state.qr_img_path} alt="" />
                  </div>
                </Row>
              </>
            )}
            {isMobile && !isFreeCoupon &&
              <>
                <Row type="flex" justify="center" gutter={48}>
                  <Col>
                    <Button
                      href={`onepay://qr/${this.state.emv_code}`}
                      style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }}>
                      <span className="white-txt txt-bold txt-submit-btn">ກົດເພື່ອຊຳລະເງິນຜ່ານ BCEL One</span>
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row type="flex" justify="center">
                  <span style={{ fontSize: "6pt", color: "red" }} >
                    ກໍລະນິທີ່ປຸ່ມ "ກົດເພື່ອຊຳລະເງິນຜ່ານ BCEL One" ແລ້ວພົບບັນຫາໃນການຊຳລະເງິນ, ກະລຸນາ Screenshot ໜ້າຈໍ QR Code ນີ້ເພື່ອໄປເປີດໃນແອັບ BCEL One ອີກຄັ້ງ.
                    </span>
                </Row>
              </>
            }

          </div>
        )
        }

        {this.state.falsed && (
          <>
            <br />
            <Row type="flex" justify="center">
              <span style={{ color: "red " }} >ເກີດຂໍ້ຜິດພາດ ກະລຸນາລົງທະບຽນໃໝ່!!!</span>
            </Row>
          </>
        )}
        <br />
        <Row type="flex" justify="center" gutter={48}>
          <Col>
            <a href="#" onClick={e => { this.cancleRegister() }} ><span style={{ color: "#FA4700", textDecoration: "underline" }} className="txt-bold txt-submit-btn">ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form</span></a>
          </Col>
        </Row>
        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div >
    )
  }
}

export default QR_NormalPage