import { Button, Card, Col, Form, Icon, Input, notification, Row } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import Search from 'antd/lib/input/Search'
import Title from 'antd/lib/typography/Title'
import React, { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import history from '../../../history'
import { axiosInstant, axiosInstantMultipart } from '../../../service/axios'
import BannerComponent from '../../components/banner'
import Navbar from '../../components/navbar'
import PowerBy from '../../components/powerby'

import loadingPic from './../../assets/images/Infinity.gif'

export default Form.create()(function SubmitDistance(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [isBib, setIsBib] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [name, setName] = useState("")
  const [bookid, setBookid] = useState("")


  const fileInputRef = useRef(null);

  const onSearchByBib = () => {

    setIsLoading(false);

    props.form.validateFields((err, values) => {
      Promise.all([axiosInstant.get(`/seacrh/bib?bib=${values.bib}`)]
      ).then(([{ data: { result } }]) => {

        // console.log(result);
        setIsLoading(false);
        setIsBib(true);
        setName(`${result.first_name} ${result.last_name} (${result.nick_name})`)

        const { setFieldsValue } = props.form
        setFieldsValue({
          ["book_id"]: result.book_id,
        });

      }).catch(error => {
        setIsBib(false);
        notification.warning({
          message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
          description: <span className="txt-alert">ເລກບິບທີ່ທ່ານປ້ອນບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ !!<br />Your BIB number is wrong</span>,
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px red'
          },
          duration: 5
        })
      })
    });
  }

  const onClickUploadFile = () => {
    fileInputRef.current.click();
  }

  const handleFileUplaod = e => {
    setFileUpload(URL.createObjectURL(e.target.files[0]));
    setIsFileUploaded(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {

        var formData = new FormData();

        formData.append("bib", values.bib);
        formData.append("length", values.length);
        formData.append("book_id", values.book_id);
        formData.append("image", fileInputRef.current.files[0]);

        Promise.all([axiosInstantMultipart.post(`/submit/run`,
          formData
        )]
        ).then(([{ data: { result } }]) => {
          notification.success({
            message: <span className="txt-alert">ເພີ່ມໄລຍະທາງສຳເລັດ / Submit Result Successfully</span>,
            description: <span className="txt-alert">ທ່ານສາມາດສົ່ງຜົນອີກຄັ້ງພາຍຫຼັງ 3 ຊົ່ວໂມງ<br /></span>,
            style: {
              backgroundColor: '#ffffffff',
              border: 'solid 1px green'
            },
            duration: 5
          })
          setIsLoading(true);
          setTimeout(() => {
            history.push({
              pathname: '/result',
              state: { bib_id: values.bib }
            })
          }, 4000)

        }).catch(error => {
          notification.warning({
            message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error! ຫຼຶ ທ່ານສົ່ງຜົນຖີ່ເກິນໄປ</span>,
            description: <span className="txt-alert">ກະລຸນາລອງໃໝ່ອີກຄັ້ງ !!<br /></span>,
            style: {
              backgroundColor: '#ffffffff',
              border: 'solid 1px red'
            },
            duration: 5
          })
        })
      }
    });
  }

  useEffect(() => {
    setIsLoading(false);
  }, [])

  const { getFieldDecorator } = props.form;

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <BannerComponent />
      <Navbar />

      {
        isLoading &&
        <div>
          <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
        </div>
      }

      {
        !isLoading &&
        <>
          <Form onSubmit={handleSubmit}>
            <Row style={{ marginTop: "50px" }} type="flex" justify="center" >
              <Col lg={18} xs={22}>
                <Card title={<Title level={3}>ສົ່ງຜົນການແລ່ນ</Title>}>
                  <Row type="flex" gutter={48}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row type="flex" gutter={48}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                          <Row>ໝາຍເລກບິບ / BIB Number. <span style={{ color: "red" }}>*</span></Row>
                          <Row>
                            <FormItem  >
                              {getFieldDecorator(`bib`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [{
                                  required: true, message:
                                    <Row type="flex" gutter={12}>
                                      <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                                      <Col>
                                        <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ຊື່.</span></Row>
                                        <Row><span className="form-label-15 spn-red">Please input your firstname.</span></Row>
                                      </Col>
                                    </Row>
                                }]
                              })(
                                <Search
                                  placeholder="ປ້ອນເລກບິບ / BIB No."
                                  onSearch={onSearchByBib}
                                  style={{ width: isMobile ? "100%" : "50%" }}
                                  size="large"
                                />
                              )}
                            </FormItem>
                          </Row>
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                          <Row>
                            <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '40px', borderRadius: '5px' }}
                              htmlType="button" onClick={onSearchByBib}>
                              <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ຄົ້ນຫາ / Search</span>
                            </Button>
                          </Row>
                        </Col> */}
                      </Row>
                    </Col>
                    {
                      isBib &&
                      <>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Row type="flex" gutter={48}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                              <Row>ຊື່ເຕັມ/ Fullname. <span style={{ color: "red" }}>*</span></Row>
                              <Row>
                                <Input value="name" value={name} readOnly />
                              </Row>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                              <Row>ໄລຍະທາງ / Distance. <span style={{ color: "red" }}>*</span></Row>
                              <Row>
                                <FormItem  >
                                  {getFieldDecorator(`length`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [{
                                      required: true, message:
                                        <Row type="flex" gutter={12}>
                                          <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                                          <Col>
                                            <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ໄລຍະທາງ.</span></Row>
                                            <Row><span className="form-label-15 spn-red">Please input your distance.</span></Row>
                                          </Col>
                                        </Row>
                                    }]
                                  })(
                                    <Input key={0} type="number" step={0.10} min={1} max={200} placeholder="xx Km" />
                                  )}
                                </FormItem>

                                <FormItem  >
                                  {getFieldDecorator(`book_id`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [{
                                      required: true,
                                    }]
                                  })(
                                    <Input key={0} hidden />
                                  )}
                                </FormItem>
                              </Row>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                              <Row>ອັບໂຫຼດຮູບພາບ / Upload Photos. <span style={{ color: "red" }}>*</span></Row>
                              <Row>

                                <FormItem  >
                                  {getFieldDecorator(`image`, {
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [{
                                      required: true,
                                    }]
                                  })(
                                    <input ref={fileInputRef} onChange={handleFileUplaod} type="file" style={{ display: "none" }} accept="image/gif, image/jpeg, image/png" multiple />
                                  )}
                                </FormItem>

                                {
                                  isFileUploaded &&
                                  <img src={fileUpload} alt="loading" style={{ display: "block", width: isMobile ? "200px" : "400px" }} />
                                }

                                <Button style={{ backgroundColor: '#66666E', width: isMobile ? "200px" : "400px", height: '35px', borderRadius: '5px' }}
                                  htmlType="button" onClick={onClickUploadFile}>
                                  <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ອັບໂຫຼດຮູບພາບ / Upload Photos</span>
                                </Button>
                              </Row>
                            </Col>
                          </Row>

                          {
                            isFileUploaded &&
                            <>
                              <hr style={{ marginBlock: "20px" }} />
                              <Row type="flex" gutter={48}>
                                <Col>
                                  <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '5px' }}
                                    htmlType="submit">
                                    <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ສົ່ງຜົນ / Submit</span>
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          }
                        </Col>
                      </>
                    }
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      }
      <PowerBy />
    </div>
  )
}
);
