import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Divider, Tag, Skeleton } from 'antd'
import BannerComponent from '../../components/banner';
import { Summary } from './components/summary';
// import Shirtsize from './../../data/shirtsize'

const RenderSummary = ({
  firstname,
  lastname,
  nick_name,
  gender,
  dateofbirth,
  contactnumber,
  email,
  nationality,
  identity,
  address,
  city,
  province,
  country,
  finisher,
  tshirt,
  race
}) => {
  return <Summary
    firstname={firstname}
    lastname={lastname}
    nick_name={nick_name}
    gender={gender}
    dateofbirth={dateofbirth}
    // bloodtype={bloodtype}
    contactnumber={contactnumber}
    email={email}
    nationality={nationality}
    identity={identity}
    address={address}
    city={city}
    province={province}
    country={country}
    finisher={finisher}
    tshirt={tshirt}
    race={race}
  />
}

class SummPage extends Component {

  constructor(props) {
    super(props)
    const { panes } = this.props.register

    // console.log(this.props.register);

    this.state = {
      loading: false,
      panes,
      activeKey: "0",
      race: []
    }
  }

  onChange = value => {
    this.setState({ activeKey: value })
  }

  componentDidMount = async () => {
    // setTimeout(this.setState({ loading: false }), 5000)
    // await axiosInstant.get('/bloods').then((res) => {
    //   this.setState({
    //     bloodtype: res.data
    //   })
    // })

    // await axiosInstant.get('/shirtsizes').then((res) => {
    //   this.setState({
    //     shirt_data: res.data
    //   })
    // })
  }

  render() {
    const { formvalue, race, finisher_shirt_size, t_shirt_size } = this.props.register

    return (
      <>
        <BannerComponent />
        <div className="mg-top-20 mg-bottom-20">
          <Row type="flex" justify="center">
            <Col>
              <Tag color="orange">
                <div className="pd-top-20 pd-bottom-20">
                  <span className="form-title-blue txt-center">ຢືນຢັນຂໍ້ມູນ / Confirm Info</span>
                </div>
              </Tag>
            </Col>
          </Row>
        </div>

        <Divider />
        <Skeleton loading={this.state.loading}>

          <Row type="flex" className="mg-top-20">
            <RenderSummary
              firstname={formvalue.attendee[0].first_name}
              lastname={formvalue.attendee[0].last_name}
              nick_name={formvalue.attendee[0].nick_name}
              gender={formvalue.attendee[0].gender}
              nationality={formvalue.attendee[0].nationality}
              identity={formvalue.attendee[0].identity}
              dateofbirth={formvalue.attendee[0].birth_day}
              contactnumber={formvalue.attendee[0].contact.phone}
              email={formvalue.attendee[0].contact.email}
              address={formvalue.attendee[0].address.street}
              city={formvalue.attendee[0].address.city}
              province={formvalue.attendee[0].address.state}
              country={formvalue.attendee[0].address.country}
              finisher={finisher_shirt_size.data.filter(val => val.code === formvalue.attendee[0].shirt_size.finisher)[0].name}
              tshirt={t_shirt_size.data.filter(val => val.code === formvalue.attendee[0].shirt_size.tshirt)[0].name}
              race={race.data[0].name}
            />
          </Row>
        </Skeleton>

      </>
    )
  }
}

const mapStateToProps = state => ({
  register: state.register
})

export default connect(mapStateToProps)(SummPage)