import React, { Component } from 'react'


import { Row, Col } from 'antd'
import BannerComponent from '../../components/banner'
import PowerBy from '../../components/powerby'
import history from '../../../history'




class Terms_Conditions extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }




    }
    render() {
        return (
            <div>
                <BannerComponent />

                <div className="container-round-border mg-top-20 mg-left-50 mg-right-50" style={{ padding: '20px' }}>

                    <Row type="flex" justify="center">
                        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                            <Row type="flex" style={{ paddingTop: "10px" }} justify="center">
                                <span style={{ fontSize: "30px", fontWeight: 'bold', fontFamily: "Noto Sans Lao Defago" }}>ເງື່ອນໄຂການເຂົ້າຮ່ວມກິດຈະກຳ :</span>
                            </Row>
                            <br />
                            <Row type="flex" style={{ paddingTop: "10px" }}>
                                <span style={{ fontSize: "16px", fontFamily: "Noto Sans Lao Defago" }}>ກະຕິກາກໍຄື ແລ່ນ 40k 50k 60k ລວມ 150k ພາຍໃນໄລຍະເວລາ 90ມື້ ສິແລ່ນເທື່ອດຽວຈົບກໍໄດ້ ຖ້າບໍ່ໄຫວກໍຄ່ອຍໆ ແລ່ນສະສົມໄລຍະໄປເລື້ອຍໆ ສະດວກບ່ອນໄດກໍແລ່ນບ່ອນນັ້ນ ແຖວບ້ານ ສະຫນາມກີລາ ຫລືເທິງເຄື່ອງແລ່ນ ຂໍໃຫ້ອອກມາແລ່ນຫລືຍ່າງ ຊື່ສັດກັບໂຕເອງເປັນພໍ ຈັບໄລຍະດ້ວຍໂມງແລ່ນ ຫລືແອັບມືຖື ແຄັບຫນ້າຈໍ ຈື່ເບີ e-bib ຂອງທ່ານໄວ້ ແລ້ວສົ່ງຜົນການແລ່ນມາທາງລິ້ງສົ່ງຜົນ ຫລັງຈາກນັ້ນເຂົ້າມາຮັບຂອງລາງວັນ ຕາມວັນແລະເວລາທີ່ທາງເຮົາໄດ້ກຳນົດໄວ້
ເງື່ອນໄຂ</span>
                            </Row>
                            <br />
                            <Row type="flex" style={{ paddingTop: "10px" }}>
                                <span style={{ fontSize: "16px", fontFamily: "Noto Sans Lao Defago" }}>1. ຫາກນັກແລ່ນ ບໍ່ສາມາດແລ່ນໄດ້ຄົບຕາມໄລຍະທາງທີ່ກຳນົດ ຈະບໍ່ໄດ້ຮັບຂອງລາງວັນ ແລະ e-certificate.</span>
                            </Row>
                            <Row type="flex" style={{ paddingTop: "10px" }}>
                                <span style={{ fontSize: "16px", fontFamily: "Noto Sans Lao Defago" }}>2. ຫາກນັກແລ່ນ ບໍ່ສາມາດແລ່ນໄດ້ຄົບຕາມໄລຍະທາງທີ່ກຳນົດ ເນື່ອງຈາກບາດເຈັບຫລືດ້ວຍເຫດຜົນໄດໆກໍຕາມ ສາມາດເເຈ້ງແອດມິ້ນເພື່ອຮັບຂອງລາງວັນ ແຕ່ທັງນີ້ກໍຂື້ນກັບດຸນພິນິດຂອງທາງທີມງານຕັດສິນອີກເທື່ອຫນື່ງ.</span>
                            </Row>
                            <Row type="flex" style={{ paddingTop: "10px" }}>
                                <span style={{ fontSize: "16px", fontFamily: "Noto Sans Lao Defago" }}>3. ຜູ້ສະມັກ ບໍ່ສາມາດຂໍຄ່າສະມັກຄືນໄດ້ ບໍ່ວ່າໃນກໍລະນີໄດກໍຕາມ. </span>
                            </Row>

                            <br />
                            <br />
                            <Row type="flex" justify="center" gutter={48}>
                                <Col>
                                    <a onClick={e => {
                                        history.push({
                                            pathname: '/register'
                                        })
                                    }} ><span style={{ color: "orangered", fontSize: "16pt", textDecoration: "underline" }} className="txt-bold txt-submit-btn">ກັບຄືນ</span></a>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                </div>

                <div className="mg-top-20 mg-bottom-20 footer-container">
                    <PowerBy />
                </div>
            </div >
        )
    }
}

export default Terms_Conditions