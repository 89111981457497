import { Card, Col, Progress, Row } from 'antd'
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React from 'react'
import { isMobile } from 'react-device-detect';

function getPercentage(distance) {

  let percent = distance * 100 / 150;

  return Math.floor(percent);
}

export default function Finisherwall({ data }) {
  // console.log(data);
  return (
    <div>
      {data.map((rank, key) => {
        return (
          <Card key={key} bodyStyle={{ padding: "10px" }}>
            <Row style={{ marginTop: "10px" }} type="flex" align="middle" >
              <Col lg={2} xs={5}>
                <Title level={3} style={{ marginBottom: "0" }}>{rank.ranking}</Title>
              </Col>
              <Col lg={6} xs={17}>
                <p style={{ fontSize: "12pt", fontWeight: "bold", marginBottom: "0" }} >{rank.first_name} {rank.last_name} ({rank.nick_name})</p>
              </Col>
              <Col lg={6} xs={22}>
                <Progress percent={getPercentage(rank.accumulated)} style={{ marginBottom: "5px" }} />
              </Col>
              <Col lg={6} xs={22}>
                <p style={{ fontSize: "13pt", paddingLeft: isMobile ? "0" : "20px", fontWeight: "bold", marginBottom: "0" }} >ສົ່ງຜົນການແລ່ນລວມ : {rank.accumulated} Km</p>
                <p style={{ fontSize: "12pt", paddingLeft: isMobile ? "0" : "20px", marginBottom: "0" }}>ສົ່ງຜົນການແລ່ນລ່າສຸດ : {rank.submit_last ? moment(rank.submit_last).format("YYYY-MM-DD HH:mm:s") : "ຍັງບໍ່ໄດ້ເພິ່ມໄລຍະທາງ"}</p>
              </Col>
            </Row>
          </Card>
        );
      })}
    </div>
  )
}
