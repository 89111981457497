import React, { Component } from 'react'
import loadingPic from './../../../assets/images/Infinity.gif'
import { Row, Col, Button } from 'antd'
import history from '../../../../history'
import km5 from '../../../assets/images/5km.png'
import km10 from '../../../assets/images/10km.png'
import km21 from '../../../assets/images/21km.png'
import { isMobile } from 'react-device-detect'
import Barcode from 'react-barcode'
import moment from "moment";
import { connect } from 'react-redux'
import BannerComponent from '../../../components/banner'
import Navbar from '../../../components/navbar'

import LVR from '../../../assets/images/LVR_5Km.png'
import * as htmlToImage from 'html-to-image'
import PowerBy from '../../../components/powerby'
import download from 'downloadjs'


class SearchResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      books: '',
      paid_status: 'SUCCESS',
      // paid_status: 'FAILED',
      book: {}
      // searchvalue : {result:0,id:3597112,txtime:"29/08/2020 14:26:29",age:434391.0000000000000000000000000000000012,name:"JALANA VONGXAY",phone:77805085,userid:"3AE0D7675BFB287FE054D8D385BC8CF0",ticket:"X59MNZK07WMT",fccref:"160FTBP20242A2K7",amount:5000,ccy:"LAK",lakamount:5000,iid:"59SWX6RZ",uuid:"HJQ8O6GU",description:"HJQ8O6GU",voidable:1}
    }

  }

  cancleRegister() {
    history.push({
      pathname: '/'
    })
  }

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  onDownloadBib() {
    var bib_div = document.getElementById('bib')
    htmlToImage.toJpeg(bib_div, { width: bib_div.offsetWidth, pixelRatio: 1 })
      .then(function (dataUrl) {
        download(dataUrl, 'bib.jpg');
      });
  }



  componentDidMount = () => {


  }
  render() {
    const { loading, paid_status } = this.state
    const { searchvalue } = this.props.search
    console.log(searchvalue);
    return (
      <div >
        {!isMobile &&
          <div>

            <Row type="flex" justify="center">
              {
                loading &&
                <img src={loadingPic} alt="loading" />
              }
              {
                !loading && paid_status === "SUCCESS" &&
                <>
                  <div style={{ paddingBottom: "50px", paddingTop: "30px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                    </Row>
                    <Row type="flex" justify="center">
                      <span style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                    </Row>
                    <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                    <div style={{
                      backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "400px", height: "400px", paddingLeft: "25px"
                    }}
                      id="certificate"
                    >
                      <br />
                      <Row type="flex" style={{ paddingTop: "80px" }}>
                        <span
                          style={{ fontSize: "35pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center", }}
                        >
                          {searchvalue.book.attendee.bib_id}
                        </span>
                      </Row>
                      <Row style={{ paddingTop: "0px" }}>
                        <span style={{ fontSize: "17pt", fontFamily: "Noto Sans Lao Defago" }}>
                          NAME:
                        </span><br />
                        <span style={{ fontSize: "20pt", fontFamily: "Noto Sans Lao Defago" }}>
                          {searchvalue.book.attendee.nick_name.toUpperCase()}
                        </span>
                      </Row>
                    </div>
                    <Button style={{ backgroundColor: '#de224c', width: '400px', height: '40px', borderRadius: '5px', marginTop: "10px" }}
                      htmlType="button" onClick={this.onDownloadBib}>
                      <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ BIB / Download BIB</span>
                    </Button>
                    <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    <Row type="flex" justify="center" gutter={48} style={{ marginTop: "50px" }}>
                      <Col>
                        <a onClick={e => { this.cancleRegister() }} href="javascript:;" >
                          <span
                            style={{ color: "orangered", textDecoration: "underline", fontSize: "13pt" }}
                            className="txt-bold txt-submit-btn"
                          >
                            ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </>
              }
              {
                !loading && paid_status === "FAILED" &&
                <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                  <Row type="flex" justify="center">
                    <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                  </Row>
                  <Row type="flex" justify="center" gutter={48}>
                    <Col>
                      <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                    </Col>
                  </Row>
                </div>
              }
            </Row>

          </div>
        }

        {isMobile &&
          <>
            <div className="container-round-border mg-top-20 mg-left-20 mg-right-20" >

              <Row type="flex" justify="center">
                {
                  loading &&
                  <img src={loadingPic} alt="loading" />
                }
                {
                  !loading && paid_status === "SUCCESS" &&
                  <>
                    <div style={{ paddingBottom: "25px", paddingTop: "20px" }}>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                      </Row>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                      <div style={{
                        backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "90vw", height: "auto", paddingTop: "20%",
                        paddingBottom: "40%"
                      }}
                        id="certificate"
                      >
                        <br />
                        <Row type="flex">
                          <span
                            style={{ fontSize: "28pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}
                          >
                            {searchvalue.book.attendee.bib_id}
                          </span>
                        </Row>
                        <Row>
                          <span style={{ fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                            NAME:
                          </span><br />
                          <span style={{ fontSize: "12pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                            {searchvalue.book.attendee.nick_name.toUpperCase()}
                          </span>
                        </Row>
                      </div>
                      <Button style={{ backgroundColor: '#de224c', width: '90vw', height: '40px', borderRadius: '5px', marginTop: "10px" }}
                        htmlType="button" onClick={this.onDownloadBib}>
                        <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ BIB / Download BIB</span>
                      </Button>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                    </div>
                  </>
                }
                {
                  !loading && paid_status === "FAILED" &&
                  <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                    </Row>
                    <Row type="flex" justify="center" gutter={48}>
                      <Col>
                        <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                      </Col>
                    </Row>
                  </div>
                }
              </Row>
            </div>

            <Row type="flex" justify="center" gutter={48} style={{ marginTop: "20px" }}>
              <Col>
                <a onClick={e => { this.cancleRegister() }} href="javascript:;" >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>
          </>
        }
      </div >
    )
  }
}

const mapStateToProps = state => ({
  search: state.search
})

export default connect(mapStateToProps)(SearchResult)