import { Card, Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title';
import React from 'react'
import { isMobile } from 'react-device-detect';

function getPodiumHeight(accumulated, top_accumulated) {
  let height = 0;
  height = 300 * accumulated / top_accumulated;
  height = height < 60 ? 60 : height;
  height += "px";
  return height;
}

export default function PodiumChart({ data }) {
  return (
    <div>
      <Row type="flex" justify="center" align="bottom" >
        {data[0] &&
          <Col lg={isMobile ? 8 : 4} xs={8} order={isMobile ? 1 : 2} >
            <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "20pt", marginBottom: "0" }} ellipsis={true}>{data[0].nick_name}</Title>
            <Title style={{ textAlign: "center", fontSize: isMobile ? "11pt" : "16pt", marginTop: "0" }} ellipsis={true}>{data[0].bib}</Title>
            <Card style={{ marginInline: "2vw", height: getPodiumHeight(data[0].accumulated, data[0].accumulated), backgroundColor: "gold" }}>
              <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "16pt", marginBottom: "0" }}>{data[0].accumulated} Km</Title>
            </Card>
            <Title style={{ textAlign: "center", paddingTop: "10px" }} level={3}>1st</Title>
          </Col>
        }

        {data[1] &&
          <Col lg={isMobile ? 8 : 4} xs={8} order={isMobile ? 0 : 1} >
            <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "20pt", marginBottom: "0" }} ellipsis={true}>{data[1].nick_name}</Title>
            <Title style={{ textAlign: "center", fontSize: isMobile ? "11pt" : "16pt", marginTop: "0" }} ellipsis={true}>{data[1].bib}</Title>
            <Card style={{ marginInline: "2vw", height: getPodiumHeight(data[1].accumulated, data[0].accumulated), backgroundColor: "silver" }}>
              <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "16pt", marginBottom: "0" }}>{data[1].accumulated} Km</Title>
            </Card>
            <Title style={{ textAlign: "center", paddingTop: "10px" }} level={3}>2nd</Title>
          </Col>
        }

        {data[2] &&
          <Col lg={isMobile ? 8 : 4} xs={8} order={isMobile ? 2 : 3} >
            <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "20pt", marginBottom: "0" }} ellipsis={true}>{data[2].nick_name}</Title>
            <Title style={{ textAlign: "center", fontSize: isMobile ? "11pt" : "16pt", marginTop: "0" }} ellipsis={true}>{data[2].bib}</Title>
            <Card style={{ marginInline: "2vw", height: getPodiumHeight(data[2].accumulated, data[0].accumulated), backgroundColor: "#F09259" }}>
              <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "16pt", marginBottom: "0" }}>{data[2].accumulated} Km</Title>
            </Card>
            <Title style={{ textAlign: "center", paddingTop: "10px" }} level={3}>3rd</Title>
          </Col>
        }

        {
          !isMobile &&
          <>
            {
              data[3] &&
              <Col lg={isMobile ? 8 : 4} order={0} >
                <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "20pt", marginBottom: "0" }} ellipsis={true}>{data[3].nick_name}</Title>
                <Title style={{ textAlign: "center", fontSize: isMobile ? "11pt" : "16pt", marginTop: "0" }} ellipsis={true}>{data[3].bib}</Title>
                <Card style={{ marginInline: "2vw", height: getPodiumHeight(data[3].accumulated, data[0].accumulated), backgroundColor: "#04D3FA" }}>
                  <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "16pt", marginBottom: "0" }}>{data[3].accumulated} Km</Title>
                </Card>
                <Title style={{ textAlign: "center", paddingTop: "10px" }} level={3}>4th</Title>
              </Col>
            }

            {
              data[4] &&
              <Col lg={isMobile ? 8 : 4} order={4} >
                <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "20pt", marginBottom: "0" }} ellipsis={true}>{data[4].nick_name}</Title>
                <Title style={{ textAlign: "center", fontSize: isMobile ? "11pt" : "16pt", marginTop: "0" }} ellipsis={true}>{data[4].bib}</Title>
                <Card style={{ marginInline: "2vw", height: getPodiumHeight(data[4].accumulated, data[0].accumulated), backgroundColor: "#01FA1B" }}>
                  <Title style={{ textAlign: "center", fontSize: isMobile ? "12pt" : "16pt", marginBottom: "0" }}>{data[4].accumulated} Km</Title>
                </Card>
                <Title style={{ textAlign: "center", paddingTop: "10px" }} level={3}>5th</Title>
              </Col>
            }
          </>
        }

      </Row>
    </div >
  )
}

