import React from "react";
import { axiosInstant } from "../../../../service/axios";
import {
  Card,
  Table,
  Tag,
  Modal,
  Input,
  Button,
  Icon,
  Tooltip,
  Divider,
  notification
} from "antd";
import Highlighter from "react-highlight-words";
import RegistrationDetail from "./RegistrationDetail";
// import { mergeInObjectArray } from "./../../../../helper";
import moment from "moment";
import EditAttendee from "./EditAttendee";
import EditAttendeeEmail from "./EditAttendeeEmail";
// import { StatusModal } from "./StatusModal";
import { QRModal } from "./QRModal";
import loadingPic from '../../../assets/images/Infinity.gif'
import errorPic from '../../../assets/images/500error.gif'
import { isMobile } from "react-device-detect";

const { confirm } = Modal;

class Register extends React.Component {
  state = {
    items: [],
    visibleModalDetail: false,
    item: {},
    searchText: "",
    bikePirceItem: [],
    editVisible: false,
    editEmailVisible: false,
    isLoading: true,
    statusModal: false,
    status: "Pending",
    qrModal: false,
    id: "",
    qr_img_path: "",
    selectedRowKeys: [],
    isError: false
  };
  showDetailModal = item => {
    this.setState({ item: item, visibleModalDetail: true });
  };
  handleCancel = () => {
    this.setState({
      visibleModalDetail: false, editVisible: false,
      editEmailVisible: false
    });
  };

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount() {
    axiosInstant.get('/books').then(({ data: { books } }) => {
      this.setState({
        items: books
          .filter(books => books.status !== "Cancel")
          .sort(function (a, b) {
            var keyA = new Date(a.created_at),
              keyB = new Date(b.created_at);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }), isLoading: false
      })
    }).catch(error => {
      this.setState(
        {
          isError: true,
          isLoading: false
        }
      )
    });

  }

  fetchData = () => {
    // const getData =this.state.items.reduce((prev, cur) => {
    //   const {status, created_at, id} = cur;
    //   const newItem = cur.attendees.map(item => ({attendees: item, status, created_at, id}))
    //   return prev.concat(newItem)
    // }, [])
    // const mergeData = getData.reduce((result, item) => {
    //   //First, take the name field as a new array result
    //       if (result.indexOf(item.id) < 0) {
    //           result.push(item.id)
    //       }
    //       return result
    //   }, []).reduce((result, id) => {
    //   //Take the data with the same id as a new array, and add a new field * * rowSpan inside it**
    //     const children = getData.filter(item => item.id === id);
    //     result = result.concat(
    //       children.map((item, index) => ({
    //         ...item,
    //         rowSpan: index === 0 ? children.length : 0,//Add the first row of data to the rowSpan field
    //       }))
    //     )
    //     return result;
    //   }, [])
    return this.state.items.map((item, i) => {
      const { attendee, created_at, id, updated_by, updated_at } = item;
      const age = this.getAge(moment(), moment(attendee.birth_day));
      return {
        key: id,
        number: i + 1,
        created_at: moment(created_at).format("DD/MM/YYYY H:mm:s"),
        full_name: `${attendee.first_name} ${attendee.last_name}`,
        nick_name: `${attendee.nick_name}`,
        code: id,
        gender: attendee.gender,
        email: attendee.contact.email,
        phone: attendee.contact.phone,
        shirt_size: attendee.shirt_size,
        bike_price: attendee.race.price,
        distance: attendee.race.name,
        emergency: attendee.contact.emergency,
        identity: attendee.identity,
        blood: attendee.blood,
        status: item,
        reward_status: item,
        action: item,
        bib_id: attendee.bib_id,
        total_submit_run: attendee.total_submit_run,
        modifield_by: `${updated_by}, ${moment(updated_at).format("MM/DD/YYYY, h:mm a")}`
      };
    })
  }

  chageStatus = (code, status) => {
    let newStatus;
    if (status !== "CANCELED") {
      newStatus = this.state.items.map(item => {
        if (item.code === code) {
          item.status = status;
        }
        return item;
      });
    } else {
      newStatus = this.state.items.filter(item => {
        return item.code !== code;
      });
    }
    this.setState({ items: newStatus });
  };
  settingCondition(item) {
    let status = "";
    switch (item.status) {
      case "Pending":
        status = <Tag color="yellow">ລໍຖ້າຈ່າຍເງິນ</Tag>;
        break;
      case "Success":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "ShirtSuccess":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess40KM":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess50KM":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess60KM":
        status = <Tag color="blue">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "Denied":
        status = <Tag color="red">ບໍ່ສາມາດໃຊ້ງານໄດ້</Tag>;
        break;
      case "Complete":
        status = <Tag color="green">ສຳເລັດແລ້ວ</Tag>;
        break;
      case "Cancel":
        status = <Tag color="red">ຍົກເລີກ</Tag>;
        break;
      case "BIB":
        status = <Tag color="purple">ຮັບບິບແລ້ວ</Tag>;
        break;
      default:
        status = "";
        break;
    }
    return status;
  }

  rewardCondition(item) {
    let status = "";
    switch (item.status) {
      case "Pending":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "Success":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "ShirtSuccess":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "RewardSuccess40KM":
        status = <Tag color="#4d4d4d">40 Km</Tag>;
        break;
      case "RewardSuccess50KM":
        status = <Tag color="#273469">50 Km</Tag>;
        break;
      case "RewardSuccess60KM":
        status = <Tag color="#de224c">60 Km</Tag>;
        break;
      case "Denied":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "Complete":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "Cancel":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      case "BIB":
        status = <Tag color="yellow">ລໍຖ້າ</Tag>;
        break;
      default:
        status = "";
        break;
    }
    return status;
  }

  columnCell = r => {
    return {
      onClick: () => {
        this.showDetailModal(r);
      }
    };
  };
  clickStatus = r => {
    return {
      onClick: () => {
        this.changeSatus(r)
      }
    }
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape={true}
        textToHighlight={text.toString()}
      />
    )
  });
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    this.setState({ searchText: "" });
  };
  showModalEditAttendee = (e, item) => {
    e.preventDefault();
    if (item.status === "Success") {
      this.setState({
        editEmailVisible: true,
        item: item
      });
    }
    else {
      this.setState({
        editVisible: true,
        item: item
      });
    }

  };
  changeSatus = (item) => {
    this.setState({ status: item.status.status, statusModal: true })
  }
  showConfirm = (e, id, type, message) => {
    e.preventDefault();
    confirm({
      title: message,
      okText: "ຕົກລົງ",
      cancelText: "ບໍ່ຕົກລົງ",
      style: { fontFamily: "Phetsarath OT" },
      onOk: () => {
        switch (type) {
          case "mail":
            axiosInstant
              .get(`/books/${id}/mail`)
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ສົ່ງເມວສຳເລັດແລ້ວ");
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ຍັງບໍ່ໄດ້ຈ່າຍເງິນ ບໍ່ສາມາດສົ່ງເມວໄດ້ອີກຄັ້ງ"
                );
              });
            break;
          case "cancel":
            axiosInstant
              .get(`/books/${id}/cancel`)
              .then(res => {
                if (res.status === 204) {
                  this.openNotificationWithIcon("success", "ຍົກເລີກສຳເລັດແລ້ວ");
                  this.setState({ items: this.state.items.filter(item => item.id !== id) })
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error",
                  "ຈ່າຍເງິນແລ້ວບໍ່ສາມາດຍົກເລີກໄດ້"
                );
              });
            break;
          case "confirm":
            axiosInstant
              .patch(`/books/${id}/status`, { status: "ON_HOLD" })
              .then(res => {
                if (res.status === 204) {
                  this.chageStatus(id, "ON_HOLD");
                  this.openNotificationWithIcon(
                    "success",
                    "confirm ສຳເລັດແລ້ວ"
                  );
                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error"
                );
              });
            break;
          case "getShirt":
            axiosInstant
              .get(`/books/${id}/shrit/success`)
              .then(res => {
                if (res.status === 204) {
                  // this.chageStatus(id, "ShirtSuccess");
                  this.openNotificationWithIcon(
                    "success",
                    "ເພິ່ມສະຖານະຮັບເສື້ອສຳເລັດແລ້ວ"
                  );

                  const newItems = [...this.state.items];
                  newItems.forEach(item => {
                    if (item.id === id) {
                      item.updated_by = localStorage.getItem('first_name');
                      item.status = "ShirtSuccess";
                    }
                  });
                  this.setState({
                    items: newItems,
                    editVisible: false,
                    changePasswordVisible: false,
                  });
                  // setTimeout(() => { window.location.reload(); }, 3000);

                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error"
                );
              });
            break;

          case "getMedal":
            axiosInstant
              .get(`/books/${id}/reward/success`)
              .then(res => {
                if (res.status === 204) {
                  // this.chageStatus(id, "COMPLETED");
                  this.openNotificationWithIcon(
                    "success",
                    "ເພິ່ມສະຖານະຮັບຫຼຽນສຳເລັດແລ້ວ"
                  );

                  const newItems = [...this.state.items];
                  newItems.forEach(item => {
                    if (item.id === id) {
                      item.updated_by = localStorage.getItem('first_name');
                      item.status = (item.attendee.total_submit_run >= 150
                        ? "RewardSuccess60KM" : item.attendee.total_submit_run >= 90
                          ? "RewardSuccess50KM" : item.attendee.total_submit_run >= 40
                            ? "RewardSuccess40KM" : "");
                    }
                  });
                  this.setState({
                    items: newItems,
                    editVisible: false,
                    changePasswordVisible: false,
                  });
                  // setTimeout(() => { window.location.reload(); }, 3000);

                }
              })
              .catch(error => {
                this.openNotificationWithIcon(
                  "error"
                );
              });
            break;

          case "cancel_all":
            this.state.selectedRowKeys.forEach(value => {
              axiosInstant
                .get(`/books/${value}/cancel`)
                .then(res => {
                  if (res.status === 204) {
                    this.setState({ items: this.state.items.filter(item => item.id !== value) })
                  }
                })
                .catch(error => {
                  this.openNotificationWithIcon(
                    "error",
                    "ບໍ່ສາມາດຍົກເລີກໄດ້"
                  );
                });
            })

            this.openNotificationWithIcon("success", "ຍົກເລີກສຳເລັດແລ້ວ");
            break;

          default:
            break;
        }
      },
      onCancel() { }
    });
  };
  getQR = (item) => {
    // axiosInstant.get(`/books/${item.id}/qr-code.png?couponCode=''`).then((res) => {
    //   if (res.data !== null) {
    //     this.setState({
    //       qr_img_path: `https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${res.data.emv_code}&choe=UTF-8`
    //     })

    //   }
    // })
    const path = `/invoices?bookingcode=${item.id}`
    window.open(`https://lvr.ewent.la${path}`, '_blank')

    this.setState({ id: item.id })
  }

  rewardConfirmTitle = (id) => {
    var selectItem = this.state.items.filter(val => val.id === id);
    let reward = [
      {
        status: "Success",
        lists: [
          {
            distance: 40,
            name: "ຫຼຽນ 40Km."
          }, {
            distance: 90,
            name: "ຫຼຽນ 50Km."
          }, {
            distance: 150,
            name: "ຫຼຽນ 60Km."
          },
          {
            distance: 150,
            name: "ເສື້ອ Finisher."
          }, {
            distance: 0,
            name: "ເສື້ອແລ່ນ."
          }
        ]
      }, {
        status: "ShirtSuccess",
        lists: [
          {
            distance: 40,
            name: "ຫຼຽນ 40Km."
          }, {
            distance: 90,
            name: "ຫຼຽນ 50Km."
          }, {
            distance: 150,
            name: "ຫຼຽນ 60Km."
          },
          {
            distance: 150,
            name: "ເສື້ອ Finisher."
          }
        ]
      }, {
        status: "RewardSuccess40KM",
        lists: [
          {
            distance: 90,
            name: "ຫຼຽນ 50Km."
          }, {
            distance: 150,
            name: "ຫຼຽນ 60Km."
          },
          {
            distance: 150,
            name: "ເສື້ອ Finisher."
          }
        ]
      }, {
        status: "RewardSuccess50KM",
        lists: [
          {
            distance: 150,
            name: "ຫຼຽນ 60Km."
          },
          {
            distance: 150,
            name: "ເສື້ອ Finisher."
          }
        ]
      }
    ]
    return (
      <>
        {
          selectItem.map((item, key) => {
            return (
              <div key={key} >
                <h1 style={{ color: "#ffc107" }}>ຢືນຢັນການຮັບຫຼຽນ ?</h1>
                <h2>ລາຍການທີ່ຈະໄດ້ຮັບ :</h2>
                <ol>
                  {
                    reward.filter(val => val.status === item.status)[0]
                      .lists.filter(l => l.distance <= item.attendee.total_submit_run).map((list, key) => {
                        return (
                          <>
                            <li key={key}>
                              {list.name}
                            </li>
                            <br />
                          </>
                        )
                      })
                  }
                </ol>
              </div>
            )
          })
        }
      </>
    )
  }

  settingButton = item => {
    let buttonAction;
    const buttonConfirmDetail = (
      <>
        <Divider type="vertical" />
        <Tooltip placement="top" title="Confirm">
          <Button
            onClick={e =>
              this.showConfirm(
                e,
                item.id,
                "confirm",
                "ຍືນຍັນຂໍ້ມູນຖືກຕ້ອງທັງໝົດແລ້ວຫຼືບໍ່?"
              )
            }
            type="primary"
          >
            <Icon
              style={{ fontSize: "2em" }}
              type="check-square"
              theme="twoTone"
              twoToneColor="#28a745"
            />
          </Button>
        </Tooltip>{" "}
      </>
    );
    const buttonEdit = (
      <Tooltip placement="top" title="ແກ້ໄຂ">
        <Button type="link" onClick={e => this.showModalEditAttendee(e, item)}>
          <Icon
            type="edit"
            style={{ fontSize: "2em" }}
            theme="twoTone"
            twoToneColor="#ffc107"
          />
        </Button>
      </Tooltip>
    );

    const buttonGetShirt = (
      <Tooltip placement="top" title="ຮັບເສື້ອ">
        <Button type="link" onClick={e =>
          this.showConfirm(
            e,
            item.id,
            "getShirt",
            "ຍືນຍັນການຮັບເສື້ອແລ່ນ"
          )
        }>
          <Icon
            type="skin"
            style={{ fontSize: "2em" }}
            theme="twoTone"
            twoToneColor="#2EBD24"
          />
        </Button>
      </Tooltip>
    );

    const buttonGetMedal = (
      <Tooltip placement="top" title="ຮັບຫຼຽນ">
        <Button type="link" onClick={e =>
          this.showConfirm(
            e,
            item.id,
            "getMedal",
            this.rewardConfirmTitle(item.id)
          )
        }>
          <Icon
            type="trophy"
            style={{ fontSize: "2em" }}
            theme="twoTone"
            twoToneColor="#ffc107"
          />
        </Button>
      </Tooltip>
    );

    switch (item.status) {
      case "Pending":
        buttonAction = (
          <>
            <Divider type="vertical" /> {buttonEdit}
            <Divider type="vertical" />{" "}
            <Tooltip placement="top" title="QRCode">
              <Button
                type="link"
                theme="twoTone"
                target="_blank"
                onClick={e => this.getQR(item)}
              >
                <Icon style={{ fontSize: "2em" }} type="qrcode" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />{" "}
            <Tooltip placement="top" title="ຍົກເລີກບັດ">
              <Button
                type="link"
                onClick={e =>
                  this.showConfirm(
                    e,
                    item.id,
                    "cancel",
                    "ທ່ານຕ້ອງການຍົກເລີກແທ້ຫຼືບໍ່?"
                  )
                }
              >
                <Icon
                  style={{ fontSize: "2em" }}
                  theme="twoTone"
                  twoToneColor="#dc3545"
                  type="close-circle"
                />
              </Button>
            </Tooltip>
          </>
        );
        break;
      case "PROCESSING":
        buttonAction = `${buttonEdit}${buttonConfirmDetail}`;
        break;

      case "Success":
        buttonAction = (
          <>
            <Divider type="vertical" /> {buttonEdit}
            <Divider type="vertical" /> {buttonGetShirt}
          </>
        )
        break;
      default:
        // buttonAction = (
        //   <>
        //     <Divider type="vertical" /> {buttonEdit} 
        //   </>
        // )
        break;
    }

    return (
      <>
        <Tooltip placement="top" title="Resend Email">
          <Button
            onClick={e =>
              this.showConfirm(
                e,
                item.id,
                "mail",
                "ຕ້ອງການຈະສົ່ງອີເມວອີກຄັ້ງຫຼືບໍ່?"
              )
            }
            type="link"
            theme="twoTone"
            target="_blank"
          >
            <Icon style={{ fontSize: "2em" }} type="mail" />
          </Button>
        </Tooltip>
        {buttonAction}
        {
          item.status_running !== "No"
            && (
              (item.status === "RewardSuccess40KM" && item.attendee.total_submit_run >= 90)
              || (item.status === "RewardSuccess50KM" && item.attendee.total_submit_run >= 150)
              || (item.status === "Success" && item.attendee.total_submit_run >= 40)
              || (item.status === "ShirtSuccess" && item.attendee.total_submit_run >= 40)
            )
            && item.status !== "RewardSuccess60KM" ?
            <>
              <Divider type="vertical" /> {buttonGetMedal}
            </>
            :
            <></>
        }
      </>
    );
  };
  openNotificationWithIcon = (type, messages) => {
    notification[type]({
      message: "ການແກ້ໄຂ",
      description: messages
    });
  };
  hideStatusModal = () => {
    this.setState({ statusModal: false, status: "Pending", qrModal: false, id: "" })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const columns = [
      {
        title: "ລ/ດ",
        dataIndex: "number",
        key: "number",
        onCell: this.columnCell,
        className: "column-click"
      },
      {
        title: "ວັນທີລົງທະບຽນ",
        dataIndex: "created_at",
        key: "created_at",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("created_at"),
        render: (_, { created_at, status }) => ({ children: created_at, props: { rowSpan: status.rowSpan } })
      },
      {
        title: "ລະຫັດລົງທະບຽນ",
        dataIndex: "code",
        key: "code",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("code"),
        render: (_, row) => {
          return {
            children: row.code,
            props: {
              rowSpan: row.status.rowSpan,
            }
          }
        }
      },
      {
        title: "ຊື່ ແລະ ນາມສະກຸນ",
        dataIndex: "full_name",
        key: "full_name",
        onCell: this.columnCell,
        className: "column-click",
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
        ...this.getColumnSearchProps("full_name")
      },
      {
        title: "ເພດ",
        dataIndex: "gender",
        key: "gender",
        onCell: this.columnCell,
        className: "column-click",
        ...this.getColumnSearchProps("gender")
      },
      {
        title: "ເບີໂທ",
        dataIndex: "phone",
        key: "phone",
        className: "column-click",
        sorter: (a, b) => a.phone - b.phone,
        ...this.getColumnSearchProps("phone"),
        render: (item, row) => {
          return <a href={`https://wa.me/85620${item.substr(-8, item.length)}`} target="_blank" rel="noopener noreferrer">{item.substr(-8, item.length)}</a>
        }
      },
      {
        title: "ເລກບິບ",
        dataIndex: "bib_id",
        key: "bib_id",
        onCell: this.columnCell,
        className: "column-click",
        sorter: (a, b) => a.bib_id.localeCompare(b.bib_id),
        ...this.getColumnSearchProps("bib_id")
      },
      {
        title: "ໄລຍະທາງສະສົມ (Km)",
        dataIndex: "total_submit_run",
        key: "total_submit_run",
        onCell: this.columnCell,
        className: "column-click"
      },
      {
        title: "ສະຖານະການຈ່າຍເງິນ",
        dataIndex: "status",
        key: "status",
        render: (item, row) => ({ children: this.settingCondition(item), props: { rowSpan: row.status.rowSpan } }),
        onCell: this.clickStatus
      },
      {
        title: "ຫຼຽນທີ່ຮັບແລ້ວ",
        dataIndex: "reward_status",
        key: "reward_status",
        render: (item, row) => ({ children: this.rewardCondition(item), props: { rowSpan: row.status.rowSpan } }),
        onCell: this.clickStatus
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "action",
        key: "action",
        style: { width: "100px" },
        render: (item, row) => ({ children: this.settingButton(item), props: { rowSpan: row.status.rowSpan } })
      }
      // , {
      //   title: "ແກ້ໄຂຊໍ້ມູນລ້າສຸດໂດຍ",
      //   dataIndex: "modifield_by",
      //   key: "modifield_by",
      //   onCell: this.columnCell,
      //   className: "column-click",
      //   render: (item, row) => {
      //     return item
      //   }
      // },
    ];

    const { selectedRowKeys, isLoading, isError } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const pageLength = this.state.items ? this.state.items.length : 0;
    return (
      <>

        {
          isError &&
          <div>
            <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
          </div>
        }

        {
          isLoading &&
          <div>
            <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
          </div>
        }

        {
          !isLoading && !isError &&

          < Card title={<><p>ລາຍການລົງທະບຽນ</p>
            <div >
              {/* <Button
                type="primary"
                className="login-form-button"
                onClick={(e) => {
                  this.showConfirm(e, "1", "checkpayment", "ທ່ານຕ້ອງການກວດສອບການຈ່າຍເງິນແທ້ຫຼືບໍ່?")
                }}
              >
                ກວດສອບການຈ່າຍເງິນ
          </Button> */}
            </div></>} >
            {selectedRowKeys.length > 0 && (
              <div>
                <Button
                  type="danger"
                  className="login-form-button"
                  onClick={(e) => {
                    this.showConfirm(e, "1", "cancel_all", "ທ່ານຕ້ອງການຍົກເລີກລາຍຊື່ທີ່ເລືອກແທ້ຫຼືບໍ່?")
                  }}
                >
                  ຍົກເລີກລາຍຊື່ທີ່ເລືອກ
          </Button>
              </div>
            )}
            <div style={{ overflow: "auto" }}>
              <Table
                columns={columns}
                dataSource={
                  this.state.items.length > 0 ? this.fetchData() : []
                }
                bordered
                rowSelection={rowSelection}
                pagination={{
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "100",
                    "150",
                    "200",
                    "300",
                    "500",
                    pageLength.toString()
                  ],
                  position: "both"
                }}
              ></Table>
            </div>
          </Card>
        }
        {
          this.state.visibleModalDetail && (
            <Modal
              width="968px"
              visible={this.state.visibleModalDetail}
              title="ລາຍລະອຽດ"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ fontFamily: "Phetsarath OT" }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <RegistrationDetail item={this.state.item} />
            </Modal>
          )
        }
        {
          this.state.editVisible && (
            <Modal
              width="968px"
              visible={this.state.editVisible}
              title="ລາຍລະອຽດ"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ fontFamily: "Phetsarath OT" }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <EditAttendee item={this.state.item} />
            </Modal>
          )
        }

        {
          this.state.editEmailVisible && (
            <Modal
              width="968px"
              visible={this.state.editEmailVisible}
              title="ແກ້ໄຂອີເມວ"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              style={{ fontFamily: "Phetsarath OT" }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <EditAttendeeEmail item={this.state.item} />
            </Modal>
          )
        }

        {/* <StatusModal item={this.state.status} handleCancel={this.hideStatusModal} visible={this.state.statusModal} /> */}
        <QRModal
          id={this.state.id}
          handleCancel={this.hideStatusModal}
          visible={this.state.qrModal}
          qr_img_path={this.state.qr_img_path}
        />
      </>
    );
  }
}

export default Register;
