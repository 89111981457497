import React, { Component } from "react";
import { Card, Table } from "antd";
// import logo from "./../../../assets/images/BFH_2020.png";
import moment from "moment";
import Barcode from "react-barcode";
class RegistrationDetail extends Component {
  state = {
    loading: false,
    attendee: {}
  };
  componentDidMount() {
    // axiosInstant
    //   .get(`/books/${this.props.item.code}/attendee`)
    //   .then(res => {
    //     this.setState({ loading: false, attendee: res.data });
    //   })
    //   .catch(error => { });
    // this.setState({ attendee: this.props.item.action })
    // console.log(this.props.item);
    // console.log(this.props.item);
  }
  fetchData = () => {
    const { attendee } = this.props.item.action;
    const { address } = attendee;
    // console.log(attendee);

    const { emergency_contact } = this.props.item.status;
    return [
      {
        key: attendee.id,
        gender: attendee.gender,
        birth_date: moment(attendee.birth_day).format("DD/MM/YYYY"),
        nationality: attendee.nationality,
        address: address.street,
        city: address.city,
        province: address.state,
        country: address.country,
        emergency_contact: emergency_contact,
      }
    ];
  };

  render() {
    const { item } = this.props;
    // const { bike_price } = this.props.item;
    const columns = [
      {
        title: "ເພດ",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "ວັນເດືອນປີເກີດ",
        dataIndex: "birth_date",
        key: "birth_date"
      },
      {
        title: "ສັນຊາດ",
        dataIndex: "nationality",
        key: "nationality"
      },
      {
        title: "ບ້ານ",
        dataIndex: "address",
        key: "address"
      },
      {
        title: "ເມືອງ",
        dataIndex: "city",
        key: "city"
      },
      {
        title: "ແຂວງ​",
        dataIndex: "province",
        key: "province"
      },
      {
        title: "ປະເທດ",
        dataIndex: "country",
        key: "country"
      },
      // {
      //   title: "ເບີຕິດຕໍ່ສຸກເຊີນ",
      //   dataIndex: "emergency_contact",
      //   key: "emergency_contact"
      // },
    ];
    return (

      <>
        {!this.state.loading && (
          <>
            <div style={{ textAlign: "center", paddingBottom: "5px" }}>
              {/* <img src={logo} width="200px" /> */}
            </div>
            <Card className="card-bill" style={{ width: "100%" }}>
              <div>
                <div className="float-right">
                  <a onClick={() => { window.open(`https://lvr.ewent.la/checkpayment?bookingcode=${this.props.item.action.id}`, '_blank') }}>
                    <Barcode
                      displayValue={true}
                      value={item.code}
                      height={60}
                      width={1}
                    />
                  </a>
                </div>
                <div style={{ paddingTop: "5px", fontSize: '12pt' }}>
                  ຊື່ ແລະ ນາມສະກຸນ : {item.full_name} <br /><br />
                  ຊື່ໃນ BIB : {item.nick_name} <br /><br />
                  ເລກ BIB : {item.bib_id} <br /><br />
                  ອີເມວ : {item.email} <br /><br />
                  ເບີໂທ : {item.phone} <br /><br />
                  ວັນທີລົງທະບຽນ : {item.created_at} <br /><br />
                  ລາຄາ: {item.bike_price} ກີບ
                  <br /><br />
                  ຂະໜາດເສື້ອແລ່ນ : {item.shirt_size.name_tshirt}
                  <br /><br />
                  ຂະໜາດເສື້ອ Finisher : {item.shirt_size.name_finisher}
                  <br /><br />
                </div>
              </div>
            </Card>
            <h3 className="text-center" style={{ padding: "10px" }}>
              <u>ຂໍ້ມູນ</u>
            </h3>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={this.fetchData()}
                pagination={false}
                bordered={true}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default RegistrationDetail;
