import React, { Component } from 'react'
import { Row, Col, Button, Icon } from 'antd'
import BannerComponent from '../components/banner'
import PowerBy from '../components/powerby'
import Navbar from '../components/navbar'

export default class CloseRegistration extends Component {
  checkattendee = () => {
    setTimeout(() => { window.open('/checkattendee', '_blank') }, 1000)
  }
  render() {
    return (
      <div>
        <BannerComponent />
        <Navbar />
        <div className="mg-top-20 ">
          <Row type="flex" justify="center">
            <Col>
              <Button
                onClick={this.checkattendee}
                style={{ borderRadius: '5px', color: '#9DB3C7', backgroundColor: 'red', width: 'auto', height: '45px' }} size="large">
                <Row type="flex" align="middle" gutter={10}>
                  <Col>
                    <Icon style={{ color: '#ffffff' }} type="search" />
                  </Col>
                  <Col>
                    <span className="form-label-10 white-txt">ກວດສອບລາຍການລົງທະບຽນ</span>
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mg-top-20 mg-bottom-20">
          <Row type="flex" justify="center">
            <Col>
              <span className="form-title-red txt-center" style={{ color: 'red', fontWeight: 'bold', fontSize: '24px' }}>ການລົງທະບຽນປິດແລ້ວ Registration Closed</span> <br />
            </Col>
          </Row>
        </div>
        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div>
    )
  }
}