import React, { Component } from 'react'
import { connect } from 'react-redux'
import Steps, { Step } from 'rc-steps'
import { Helmet } from 'react-helmet'
// imported component and reducers
import { changeCurrent } from './registration/actions'
import { savePaymentDetails } from './payment/actions'
import FormcomponentWrap from './registration/formpage'
import SummPage from './summary/Summ.page'
import PaymentMethod from './payment/payment.page'
import SuccessPage from './success/success.page'

// imported module
import { Row, Col, Button, Icon, Skeleton, Spin, notification } from 'antd'
import PowerBy from '../components/powerby'
import { isMobile } from 'react-device-detect'

import { axiosInstant } from './../../service/axios'



const steps = [
  {
    key: 0,
    icon: <Icon type="user" />, //style={{ color: '#ED6E26' }}
    title: <span className="txt-steps">ປ້ອນຂໍ້ມູນ</span>,
    content: <FormcomponentWrap />,
    status: 'finish',
    name: 'user',
    titlevalue: 'ປ້ອນຂໍ້ມູນ'
  },
  {
    key: 1,
    icon: <Icon type="solution" />, //style={{ color: '#ED6E26' }}
    title: <span className="txt-steps">ຢືນຢັນຂໍ້ມູນ</span>,
    content: <SummPage />,
    status: 'wait',
    name: 'solution',
    titlevalue: 'ຢືນຢັນຂໍ້ມູນ'
  },
  {
    key: 2,
    icon: <Icon type="credit-card" />, //style={{ color: '#ED6E26' }}
    title: <span className="txt-steps">ຊຳລະເງິນ</span>,
    content: <PaymentMethod />,
    status: 'wait',
    name: 'credit-card',
    titlevalue: 'ຊຳລະເງິນ'
  },
  {
    key: 3,
    icon: <Icon type="check-circle" />, //style={{ color: '#ED6E26' }}
    title: <span className="txt-steps">ສຳເລັດ</span>,
    content: <SuccessPage />,
    status: 'wait',
    name: 'check-circle',
    titlevalue: 'ສຳເລັດ'
  }
]

class Registration extends Component {

  state = {
    current: 0,
    disabledbtn: "true",
    btnNexttitle: "ຕໍ່ໄປ / Next",
    btnBacktitle: "ກັບຄືນ / Back",
    loading: false,
    postbtnstate: true,
  }

  handleClick = () => {
    if (this.state.current === 1) {
      const { attendee } = this.props.register.formvalue
      let postValue = Object.assign({
        attendee: attendee[0]
      })

      // console.log(postValue);

      try {
        this.setState({ loading: true })
        axiosInstant.post("books", JSON.stringify(postValue)).then((res, err) => {
          steps.icon = <Icon type="loading" />
          if (res && res.status === 201) {
            setTimeout(() => { this.setState({ loading: false }) }, 2000)
            steps.icon = <Icon type="finished" />
            this.props.savePaymentDetails(res.data)
            this.checkNext()
          } else {
            return notification.error({
              message: "ເກີດຂໍ້ຜິດພາດ! Error!",
              description: "ລອງໃຫມ່ອີກຄັ້ງ! Try again!",
              style: {
                backgroundColor: '#ffffffff',
                border: 'solid 1px red'
              },
              duration: 3
            })
          }
        })
      } catch (error) {
        return notification.error({
          message: "ເກີດຂໍ້ຜິດພາດ! Error!",
          description: "ລອງໃຫມ່ອີກຄັ້ງ! Try again!",
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px red'
          },
          duration: 3
        })
      }
    } else {
      this.checkNext()
    }
  }

  checkNext = () => {
    if (this.props.register.enableNext === true) {
      if (this.state.current === 0) {
        this.setState({ btnNexttitle: "ຢືນຢັນ / Confirm", btnBacktitle: "ແກ້ໄຂ / Edit" })
        this.next()
      } else {
        this.setState({ btnNexttitle: "ຕໍ່ໄປ / Next", btnBacktitle: "ກັບຄືນ / Back" })
        this.next()
      }
      // this.setState({btnNexttitle: "ສົ່ງຂໍ້ມູນ / Send Data"})
    } else {
      notification.warning({
        message: "ເກີດຂໍ້ຜິດພາດ! Error!",
        description: "Please Fill in the form completely! ກະລຸນາຕື່ມຂໍ້ມູນໃນຟອມໃຫ້ສຳເລັດກ່ອນ",
        style: {
          width: 550,
          marginLeft: 335 - 500,
          backgroundColor: '#ffffffff',
          border: 'solid 1px red'
        },
        duration: 3
      })
      this.setState({ btnNexttitle: "ຕໍ່ໄປ / Next" })
    }
  }

  disablePrev = () => {
    if (this.state.current === 2) {
      this.setState({ disablePrev: true })
    } else {
      this.setState({ disablePrev: false })
      this.prev()
    }
  }

  next() {

    const current = this.state.current + 1;
    console.log(current);
    this.setState({ current });
    steps.forEach(e => {
      if (e.key < current) {
        e.status = "finish"
        e.icon = <Icon type={e.name} style={{ color: '#ED6E26' }} />
        e.title = <span className="txt-steps txt-rfh">{e.titlevalue}</span>
      }
    })
    this.props.changeCurrent(current)
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    steps.forEach(e => {
      if (e.key > current) {
        e.status = "wait"
        e.icon = <Icon type={e.name} />
        e.title = <span className="txt-steps">{e.titlevalue}</span>
      }
    })
    this.props.changeCurrent(current)
  }


  componentDidUpdate() {
    const { register } = this.props
    // console.log(register);
    if (register.current > this.state.current) {
      this.next()
    }
  }

  componentDidMount() {
    // localStorage.removeItem("bookingCode")
    if (localStorage.getItem("bookingCode") !== null) {
      // console.log(localStorage.getItem("bookingCode"));
      const code = localStorage.getItem("bookingCode")
      const path = `/invoices?bookingcode=${code}`
      setTimeout(() => { window.location.href = path }, 1000)
    }

  }

  render() {

    const { current, btnNexttitle, btnBacktitle, loading } = this.state

    return (
      <div>
        <Helmet>
          <title>Registration</title>
        </Helmet>

        {
          this.state.postbtnstate === false &&
          <div className="steps-content hiddenoverflow" style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        }
        {
          this.state.postbtnstate === true &&
          <Skeleton loading={loading}>
            <div className="steps-content hiddenoverflow">{steps[this.state.current].content}</div>
          </Skeleton>
        }

        <div className="bt-nav pd-top-25 pd-bottom-25 hide-mobile hiddenoverflow">

          {
            !isMobile &&
            <Row type="flex" justify="center">
              <Col order={1}>
                {
                  this.state.current > 0 && this.state.current !== 2 && this.state.current !== 3
                  &&
                  <Button style={{ backgroundColor: '#de224c' }} onClick={() => this.disablePrev()}>
                    <span className="form-label-15 txt-white">{btnBacktitle}</span>
                  </Button>
                }
              </Col>
              {
                this.state.current !== 3 &&
                <Col xs={16} lg={16} order={2}>
                  <Steps size="small" current={current} labelPlacement="vertical" onChange={this.onChange}>
                    {steps.map(item => <Step icon={item.icon} status={item.status} key={item.key} title={item.title} />)}
                  </Steps>
                </Col>
              }
              <Col order={3}>
                {
                  this.state.loading === true && this.state.postbtnstate === false && <div>
                    {
                      this.state.postbtnstate === true &&
                      <Button style={{ backgroundColor: '#de224c' }} htmlType="submit" disabled onClick={this.handleClick}>
                        <span className="form-label-15 txt-white">{btnNexttitle}</span>
                      </Button>
                    }
                  </div>
                }
                {
                  this.state.current < steps.length - 1 && this.state.current > 0 && this.state.current !== 2 && this.state.current !== 3 && this.state.loading === false
                  && <div>
                    {
                      this.state.postbtnstate === true &&
                      <Button style={{ backgroundColor: '#de224c' }} htmlType="submit" onClick={this.handleClick}>
                        <span className="form-label-15 txt-white">{btnNexttitle}</span>
                      </Button>
                    }
                  </div>
                }
              </Col>
            </Row>
          }

          {
            isMobile &&
            <Row type="flex" justify="center">
              <Row type="flex" justify="space-between" gutter={48}>
                <Col order={1}>
                  {
                    this.state.current === 1
                    &&
                    <Button style={{ backgroundColor: '#de224c' }} onClick={() => this.disablePrev()}>
                      <span className="form-label-25 txt-white"><Icon type="left-circle" theme="filled" /></span>
                    </Button>
                  }
                </Col>
                <Col order={3}>
                  {
                    this.state.current === 1
                    &&
                    <Button style={{ backgroundColor: '#de224c' }} htmlType="submit" onClick={this.handleClick}>
                      <span className="form-label-25 txt-white"><Icon type="right-circle" theme="filled" /></span>
                    </Button>
                  }
                </Col>
              </Row>
              <Row type="flex" justify="center" className="mg-top-15">
                <Col order={1}></Col>
                <Col order={2}>
                  <Steps size="small" current={current} labelPlacement="vertical" onChange={this.onChange}>
                    {steps.map(item => <Step icon={item.icon} status={item.status} key={item.key} title={item.title} />)}
                  </Steps>
                </Col>
                <Col order={3}></Col>
              </Row>
            </Row>
          }
        </div>


        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  changeCurrent,
  savePaymentDetails,
}

const mapStateToProps = state => ({
  register: state.register
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)