import React, { Component, Fragment } from "react";
import { Form, Input, Button } from "antd";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleChangePassword({ ...values });
        this.props.form.resetFields();
      }
    });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    const { userData } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator("id", {
              initialValue: userData.id
            })(<Input type="hidden" />)}
          </Form.Item>
          {/* <Form.Item label="Username">
            {getFieldDecorator("username", {
              initialValue: userData.username,
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາປ້ອນ ຊື່ຜູ້ໃຊ້ Username!"
                }
              ]
            })(<Input />)}
          </Form.Item> */}
          <Form.Item label="ລະຫັດຜ່ານ" hasFeedback>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາປ້ອນລະຫັດຜ່ານ!"
                },
                { min: 8, message: "ລະຫັດຕ້ອງມີ 8 ຕົວ" },
                {
                  validator: this.validateToNextPassword
                }
              ]
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="ຍືນຍັນລະຫັດຜ່ານ" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາປ້ອນການຍືນຍັນລະຫັດຜ່ານ!"
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}

          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            ບັນທຶກ
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default Form.create()(ChangePassword);
