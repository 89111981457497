import React from 'react'
import { Modal } from 'antd'


export const QRModal = ({ visible, handleCancel,  qr_img_path }) => {

  const handleOk = () => {

  }


  return (
    <>
      <Modal
        title="QR Payment"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
      >
        <img style={{ width: '100%' }} src={qr_img_path} alt="" />
      </Modal>
    </>
  )
}