import React, { Component } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { axiosInstant } from "../../../../service/axios";

class EditDistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }
  componentDidMount() {
    // const shirtSizes = axiosInstant.get('/shirtsizes');
    // Promise.all([shirtSizes]).then(([shirtSizes]) => {
    //   this.setState({items: shirtSizes.data})
    // }).catch(e => {})
    // axiosInstant
    //   .get("/shirtsizes")
    //   .then(res => {
    //     this.setState({ items: res.data });
    //   })
    // .catch(e => {});
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          // email: values.email,
          // phone: values.phone,
          // emergency_contact: values.emergency_contact,
          attendee: {
            first_name: values.first_name,
            last_name: values.last_name,
            gender: values.gender,
            birth_date: values.birth_date,
            email: values.email,
            phone: values.phone,
            address: values.address,
            city: values.city,
            province: values.province,
            country: values.country,
            nationality: values.nationality,
            shirt_size_type: "xxx",
            // sub_class: parseInt(this.props.item.attendee.sub_class.id),
          }
        }
        axiosInstant
          .patch(`/books/${this.props.item.id}`, data)
          .then(res => {
            this.props.form.resetFields();
            if (res.status === 204) {
              window.location.reload()
            }
          })
          .catch(error => { });
      } else {
        console.log(err)
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const item = this.props.item;
    console.log(item);
    return (
      <>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div className="gutter-example">
            <Row gutter={16}>
              <Col span={8}>
                <div className="gutter-box">
                  <Form.Item label={<span>ຊື່ງານ Event Name</span>}>
                    {getFieldDecorator("name", {
                      initialValue: item.name,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນຊື່!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <Form.Item label={<span>ລາຄາ Price</span>}>
                  {getFieldDecorator("price", {
                    initialValue: item.price,
                    rules: [
                      {
                        required: true,
                        message: "ກະລຸນາປ້ອນລາຄາ!",
                        whitespace: true
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="ວັນທີ">
                  {getFieldDecorator("date", {
                    initialValue: item.policy.min_attendee_age,
                    rules: [
                      {
                        required: true,
                        message: "ກະລຸນາໃສ່ວັນທີ!",
                        whitespace: true
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}> */}
              {/* <Col span={8}>
                <Form.Item label="ວັນເດືອນປີເກີດ Date of Birth">
                  {getFieldDecorator("birth_date", {
                    initialValue: moment(item.attendees.birth_date),
                    rules: [
                      {
                        required: true,
                        message: "ກະລຸນາເລືອກວັນເດືອນປີເກີດ",
                      }
                    ]
                  })(<DatePicker style={{ width: "100%" }} />)}
                </Form.Item>
              </Col> */}


            {/* </Row> */}

            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              ບັນທຶກ
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default Form.create()(EditDistance);
