import React, { Component } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import { axiosInstant } from "./../../../../service/axios";
const { Option } = Select;
const { TextArea } = Input;
class AddCouPonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      items: []
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log(values);
      if (!err) {
        axiosInstant
          .post("/coupons", {
            // length: parseInt(values.length),
            // info: {
            name: values.name,
            type: values.kind,
            quantity: parseInt(values.length),
            expired_at: values.expire_at,
            discount: parseInt(values.value),
            // kind: values.kind
            // }
          })
          .then(res => {
            console.log(res);
            if (res.status === 201) {
              // this.props.bindinsert(res.data)
              window.location.reload();
            }
          })
          .catch(error => {

          });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const typCoupon = [
      {
        id: "VALUE",
        name: "ຈຳນວນເງິນ"
      },
      {
        id: "FREE",
        name: "ຝຣີ"
      },
      {
        id: "PERCENT",
        name: "ເປີເຊັນ"
      }
    ];
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="ປະເພດລາຄາ">
            {getFieldDecorator(`kind`, {
              validateTrigger: ["onChange", "onBlur"],
              initialValue: typCoupon[0].id,
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາເລືອກປະເພດສິນຄ້າ"
                }
              ]
            })(
              <Select style={{ width: "100%" }} >
                {/* <Option value="">ເລືອກປະເພດລາຄາ</Option> */}
                {typCoupon.map((item, key) => {
                  // console.log(key);
                  if (key === 0) {
                    return (
                      <Option key={item.id} value={item.id} >
                        {`${item.name}`}
                      </Option>
                    );
                  }
                  else {
                    return (
                      <Option key={item.id} value={item.id}>
                        {`${item.name}`}
                      </Option>
                    )
                  }
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="ຊື່ຄູປ໋ອງ">
            {getFieldDecorator(`name`, {
              validateTrigger: ["onChange", "onBlur"],
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາປ້ອນຊື່"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ຈຳນວນເງິນ">
            {getFieldDecorator(`value`, {
              validateTrigger: ["onChange", "onBlur"],
              initialValue: "",
              rules: [
                {
                  number: true,
                  message: "ປ້ອນໄດ້ພຽງແຕ່ຕົວເລກ"
                }
                // {
                //   required: true,
                //   pattern: "^[1-9][0-9]?$|^100$",
                //   message: "ປ້ອນໄດ້ພຽງແຕ່ 1 - 100"
                // }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ຈຳນວນ">
            {getFieldDecorator(`length`, {
              validateTrigger: ["onChange", "onBlur"],
              initialValue: "",
              rules: [
                {
                  required: true,
                  // pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g,
                  message: "ກະລຸນາໃສ່ວັນທີໝົດອາຍຸ"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="ວັນທິໝົດອາຍຸ">
            {getFieldDecorator(`expire_at`, {
              rules: [
                {
                  required: true,
                  message: "ກະລຸນາເລືອກວັນທີ",
                  type: "object"
                }
              ]
            })(
              <DatePicker
                showTime
                placeholder="Select Time"
                format="DD/MM/YYYY HH:mm:ss"
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
          <Form.Item label="ຄຳອະທິບາຍ">
            {getFieldDecorator(`description`, {})(<TextArea rows={4} />)}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              ບັນທຶກ
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default Form.create()(AddCouPonModal);
