import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker } from "antd";
import { axiosInstant } from "../../../../service/axios";
import moment from "moment";
import Axios from "axios";
const { Option } = Select;
class EditAttendee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      races: [],
      finisher_shirt_sizes: [],
      t_shirt_sizes: [],
      countries: [],
      nationalities: [],
      isLoading: true
    };
  }

  componentDidMount() {
    Promise.all([axiosInstant.get('/races'),
    axiosInstant.get('/shirt-sizes'),
    Axios.get('https://restcountries.eu/rest/v2/all')]
    ).then(([{ data: { races } }, { data: { shirts } }, { data }]) => {

      let finisher_shirt_sizes = shirts.filter(shirt => shirt.shirt_type_id === "FINISHER");;
      let t_shirt_sizes = shirts.filter(shirt => shirt.shirt_type_id === "TSHIRT");

      this.setState({
        races,
        finisher_shirt_sizes: finisher_shirt_sizes, // Finisher Shirt Sizes
        t_shirt_sizes: t_shirt_sizes,
        countries: data,
        nationalities: data,
        isLoading: false
      })
    }).catch(error => {

    })
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          attendee: {
            first_name: values.first_name,
            last_name: values.last_name,
            nick_name: values.nick_name,
            gender: values.gender,
            birth_day: moment(values.birth_date).format("YYYY-MM-DD"),
            contact: {
              email: values.email,
              phone: values.phone,
            },
            address: {
              street: values.address,
              city: values.city,
              state: values.province,
              country: values.country,
            },
            nationality: values.nationality,
            shirt_size: {
              tshirt: values.tshirt,
              finisher: values.finisher,
            },
            race: { id: values.race },
            bib_id: values.bib_id
          }
        }

        axiosInstant
          .put(`/books/${this.props.item.id}/attendees/${this.props.item.attendee.id}`, data)
          .then(res => {
            this.props.form.resetFields();
            if (res.status === 204) {
              axiosInstant
                .get(`/books/${this.props.item.id}/mail`)
                .then(res => {
                  if (res.status === 204) {
                    setTimeout(() => { window.location.reload() }, 2000)
                  }
                })
                .catch(error => {

                });
            }
          })
          .catch(error => { });
      } else {
        console.log(err)
      }
    });
  };
  render() {
    const { isLoading } = this.state
    const { getFieldDecorator } = this.props.form;
    const item = this.props.item;
    // console.log(item);
    return (

      <>
        {!isLoading &&
          <Form onSubmit={this.handleSubmit} className="login-form">
            <div className="gutter-example">
              <Row gutter={16}>
                <Col span={8}>
                  <div className="gutter-box">
                    <Form.Item label={<span>ຊື່ແທ້ Firstname</span>}>
                      {getFieldDecorator("first_name", {
                        initialValue: item.attendee.first_name,
                        rules: [
                          {
                            required: true,
                            message: "ກະລຸນາປ້ອນຊື່!",
                            whitespace: true
                          }
                        ]
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <Form.Item label={<span>ນາມສະກຸນ Lastname</span>}>
                    {getFieldDecorator("last_name", {
                      initialValue: item.attendee.last_name,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນຊື່!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={<span>ຊື່ໃນ BIB / Name in BIB</span>}>
                    {getFieldDecorator("nick_name", {
                      initialValue: item.attendee.nick_name,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນຊື່ໃນ BIB!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={8} style={{ display: "none" }}>
                  <Form.Item label={<span>ຊື່ໃນ BIB / Name in BIB</span>}>
                    {getFieldDecorator("bib_id", {
                      initialValue: item.attendee.bib_id,
                      rules: [
                        {
                          required: true,
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="ເພດ Gender">
                    {getFieldDecorator(`gender`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.gender,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກເພດ"
                        }
                      ]
                    })(
                      <Select className="form-label-15" style={{ width: "100%" }}>
                        <Option className="form-label-15" value="">
                          Choose gender
                      </Option>
                        <Option className="form-label-15" value="MALE">
                          Male / ຊາຍ
                      </Option>
                        <Option className="form-label-15" value="FEMALE">
                          Female / ຍິງ
                      </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ວັນເດືອນປີເກີດ Date of Birth">
                    {getFieldDecorator("birth_date", {
                      initialValue: moment(item.attendee.birth_date),
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກວັນເດືອນປີເກີດ",
                        }
                      ]
                    })(<DatePicker style={{ width: "100%" }} />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ເບີໂທລະສັບ Phone">
                    {getFieldDecorator("phone", {
                      initialValue: item.attendee.contact.phone,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ເບີໂທລະສັບ!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={<span>ອີເມລ Email (ຕ້ອງໃຊ້ເພື່ອຮັບໃບບິນຮັບເງິນ)</span>}
                  >
                    {getFieldDecorator("email", {
                      initialValue: item.attendee.contact.email,
                      rules: [
                        {
                          type: "email",
                          message: "ຮູບແບບອີເມວບໍຖືກຕ້ອງ!"
                        },
                        {
                          required: true,
                          message: "ກະລຸນາປ້ອນ ອີເມວ!"
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ປະເທດ Country">
                    {getFieldDecorator("country", {
                      initialValue: item.attendee.address.country,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ປະເທດ!",
                          whitespace: true
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="default">
                          Choose
                        </Option>
                        {this.state.countries.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.name}
                            value={item.name}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ສັນຊາດ Nationality">
                    {getFieldDecorator("nationality", {
                      initialValue: item.attendee.nationality,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ຊື່ທີມ!",
                          whitespace: true
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="">
                          Choose
                      </Option>
                        {this.state.nationalities.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.demonym}
                            value={item.demonym}
                          >
                            {item.demonym}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={16}>

                <Col span={8}>
                  <Form.Item label="ແຂວງ Province">
                    {getFieldDecorator("province", {
                      initialValue: item.attendee.address.state,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ແຂວງ!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ເມືອງ District">
                    {getFieldDecorator("city", {
                      initialValue: item.attendee.address.city,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ເມືອງ!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="ທີ່ຢູ່ Address">
                    {getFieldDecorator("address", {
                      initialValue: item.attendee.address.street,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາໃສ່ທີ່ຢູ່!",
                          whitespace: true
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} style={{ display: "none" }}>

                <Col span={8}>
                  <Form.Item label="ໄລຍະທາງ Distance">
                    {getFieldDecorator(`race`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.race.id,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກໄລຍະທາງ"
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="">
                          Choose
                      </Option>
                        {this.state.races.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.id}
                            value={item.id}
                          >
                            {item.name} - {item.description}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>

              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="ຂະໜາດເສື້ອແລ່ນ Running-shirt Size">
                    {getFieldDecorator(`tshirt`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.shirt_size.tshirt,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກຂະໜາດເສື້ອແລ່ນ"
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="">
                          Choose
                      </Option>
                        {this.state.t_shirt_sizes.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.code}
                            value={item.code}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="ຂະໜາດເສື້ອ Finisher / Finisher-shirt Size">
                    {getFieldDecorator(`finisher`, {
                      validateTrigger: ["onChange", "onBlur"],
                      initialValue: item.attendee.shirt_size.finisher,
                      rules: [
                        {
                          required: true,
                          message: "ກະລຸນາເລືອກຂະໜາດເສື້ອ Finisher"
                        }
                      ]
                    })(
                      <Select style={{ width: "100%" }}>
                        <Option className="form-label-15" key="0" value="">
                          Choose
                      </Option>
                        {this.state.finisher_shirt_sizes.map(item => (
                          <Option
                            className="form-label-15"
                            key={item.code}
                            value={item.code}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                ບັນທຶກ
            </Button>
            </div>
          </Form>}
      </>
    );
  }
}

export default Form.create()(EditAttendee);
