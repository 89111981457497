import moment from 'moment';
export const numberFormat =  (number) => {
    return  `${new Intl.NumberFormat().format(number)} KIP`;
}

export const usdFormat = (number) => {
    return `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)}`
}

export const casualFormat = (number) => {
    return `${Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)}`
}

export const dateFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
}