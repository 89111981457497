import React, { useState } from 'react'
import { Row, Col, Form, Icon, Input, Select, Modal } from 'antd'
// import data
import { isMobile } from 'react-device-detect'
import Finisher_png from '../../../assets/images/Finisher.png'
import Running_png from '../../../assets/images/Runing.png'


const FormItem = Form.Item
const { Option } = Select



export const CreateMainForm = ({
  regis_id,
  firstnameErr,
  lastnameErr,
  nick_nameErr,
  genderErr,
  nationalityErr,
  emailErr,
  phoneErr,
  addressErr,
  cityErr,
  provinceErr,
  countryErr,
  // racetypeErr,
  onGenderChange,
  // onRaceCatChange,
  onFinisherShirtChange,
  onTShirtChange,
  onCountryChange,
  onNationalityChange,
  form,
  // races,
  finisherShirtsizeErr,
  finisher_shirt_sizes,
  tShirtsizeErr,
  t_shirt_sizes,
  identityErr,
  countries,
  nationalities,
  laos,
  onChangeDate,
  onChangeMounth,
  onChangeYear,
  days,
  months,
  years,
  dateErr,
  monthErr,
  yearErr
}) => {

  const [showImageTShirtModal, setShowImageTShirtModal] = useState(false)
  const [showImageFinisherShirtModal, setShowImageFinisherShirtModal] = useState(false)

  return (
    <div key={regis_id}>
      <div className="mg-bottom-20">

        {isMobile &&
          <>
            <Row type="flex" style={{ marginTop: "20px" }} justify="center">
              <Col>
                <span className="form-title-blue txt-center" style={{ fontSize: "14pt", fontWeight: "bold", textAlign: "center" }}>
                  ລົງທະບຽນຮ່ວມງານ Laos Virtual RUn
                  </span>
              </Col>
            </Row>
          </>}

        {!isMobile &&
          <>
            <Row type="flex" style={{ marginTop: "20px" }} justify="center">
              <Col>
                <span className="form-title-blue txt-center" style={{ fontSize: "24pt", fontWeight: "bold" }}>ລົງທະບຽນຮ່ວມງານ LAOS Virtual Run</span>
              </Col>
            </Row>
          </>}
        <hr />
        <Row type="flex" justify="center" className="mg-top-20">
          <Col>
            <span className="form-title-blue txt-center" style={{ fontSize: "20pt" }}>ຂໍ້ມູນຜູ້ເຂົ້າຮ່ວມ / Attendee Information</span>
          </Col>
        </Row>
      </div>
      <div className="container-round-border" style={{ padding: isMobile ? "20px" : "50px" }}>
        <Row type="flex" gutter={48}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ຊື່ແທ້ Firstname <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={firstnameErr ? 'error' : ''} help={firstnameErr || ''} >
                {form.getFieldDecorator(`firstname_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ຊື່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your firstname.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input key={regis_id} placeholder="Firstname without Mr. or Mrs." />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ນາມສະກຸນ Lastname <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={lastnameErr ? 'error' : ''} help={lastnameErr || ''} >
                {form.getFieldDecorator(`lastname_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ນາມສະກຸນ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your lastname.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input key={regis_id} placeholder="Lastname" />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ຊື່ທີ່ສະແດງໃນ BIB<span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={nick_nameErr ? 'error' : ''} help={nick_nameErr || ''} >
                {form.getFieldDecorator(`nick_name_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ຊື່ທີ່ສະແດງໃນ BIB.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your name in BIB.</span></Row>
                        </Col>
                      </Row>
                  }, {
                    max: 12, message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ຊື່ທີ່ສະແດງໃນ BIB ບໍ່ເກີນ 12 ໂຕ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your name in BIB not more than 12 character.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input key={regis_id} placeholder="Nickname" />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ເພດ Gender <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={genderErr ? 'error' : ''} help={genderErr || ''} >
                {form.getFieldDecorator(`gender_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາເລືອກເພດ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please selectgender.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select key={regis_id} placeholder="Choose Gender" onChange={e => { onGenderChange(e) }} className="form-label-15" style={{ width: '100%' }}>
                    {/* <Option className="form-label-15" value="default">Choose gender</Option> */}
                    <Option className="form-label-15" value="MALE">Male / ຊາຍ</Option>
                    <Option className="form-label-15" value="FEMALE">Female / ຍິງ</Option>
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ວັນເດືອນປີເກີດ Date of Birth <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="form-label-25">
                <Row>
                  <FormItem validateStatus={dateErr ? 'error' : ''} help={dateErr || ''}>
                    {form.getFieldDecorator(`date_${regis_id}`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [{
                        required: true, message:
                          <Row type="flex" gutter={12}>
                            <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                            <Col>
                              <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ວັນເດືອນປີເກິດ.</span></Row>
                              <Row><span className="form-label-15 spn-red">Please enter date of birth.</span></Row>
                            </Col>
                          </Row>
                      }]
                    })(

                      <Select showSearch
                        placeholder="Date"
                        key={regis_id}
                        onChange={e => { onChangeDate(e) }}
                        className="form-label-15"
                        style={{ width: '100%' }}
                      >
                        {
                          days.map(value =>
                            <Option className="form-label-15" key={value} value={value}>{value}</Option>
                          )
                        }
                      </Select>
                    )}
                  </FormItem>
                </Row>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="form-label-25">
                <Row>
                  <FormItem validateStatus={monthErr ? 'error' : ''} help={monthErr || ''}>
                    {form.getFieldDecorator(`month_${regis_id}`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [{
                        required: true, message:
                          <Row type="flex" gutter={12}>
                            <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                            <Col>
                              <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ວັນເດືອນປີເກິດ.</span></Row>
                              <Row><span className="form-label-15 spn-red">Please enter date of birth.</span></Row>
                            </Col>
                          </Row>
                      }]
                    })(

                      <Select showSearch
                        placeholder="Month"
                        key={regis_id}
                        onChange={e => { onChangeMounth(e) }}
                        className="form-label-15"
                        style={{ width: '100%' }}
                      >
                        {
                          months.map(value =>
                            <Option className="form-label-15" key={value} value={value}>{value}</Option>
                          )
                        }
                      </Select>
                    )}
                  </FormItem>
                </Row>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="form-label-25">
                <Row>
                  <FormItem validateStatus={yearErr ? 'error' : ''} help={yearErr || ''}>
                    {form.getFieldDecorator(`year_${regis_id}`, {
                      validateTrigger: ['onChange', 'onBlur'],
                      rules: [{
                        required: true, message:
                          <Row type="flex" gutter={12}>
                            <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                            <Col>
                              <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ວັນເດືອນປີເກິດ.</span></Row>
                              <Row><span className="form-label-15 spn-red">Please enter date of birth.</span></Row>
                            </Col>
                          </Row>
                      }]
                    })(

                      <Select showSearch
                        placeholder="Year"
                        key={regis_id}
                        onChange={e => { onChangeYear(e) }}
                        className="form-label-15"
                        style={{ width: '100%' }}
                      >
                        {
                          years.map(value =>
                            <Option className="form-label-15" key={value} value={value}>{value}</Option>
                          )
                        }
                      </Select>
                    )}
                  </FormItem>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ເບີໂທ Contact Number <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={phoneErr ? 'error' : ''} help={phoneErr || ''} >
                {form.getFieldDecorator(`phone_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ເບີໂທຕິດຕໍ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Contact Number.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input type="number" placeholder="20xxxxxxxx" />
                )}
              </FormItem>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ອີເມລ Email <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={emailErr ? 'error' : ''} help={emailErr || ''} >
                {form.getFieldDecorator(`email_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ອີເມວ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Email.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input type="email" placeholder="Email" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ປະເທດ Country <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={countryErr ? 'error' : ''} help={countryErr || ''} >
                {form.getFieldDecorator(`country_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ທີ່ຢູ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your address.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select showSearch
                    key={regis_id}
                    placeholder="Choose Country"
                    onChange={e => { onCountryChange(e) }}
                    className="form-label-15"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                  >
                    {/* <Option className="form-label-15" key={regis_id} value="default">Choose</Option> */}
                    {
                      laos.map(({ name }, key) => <Option key={key} value={name}>{name}</Option>)
                    }
                    {
                      countries.map(({ name }, key) => <Option key={key} value={name}>{name}</Option>)
                    }
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ສັນຊາດ Nationality <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={nationalityErr ? 'error' : ''} help={nationalityErr || ''} >
                {form.getFieldDecorator(`nationality_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ສັນຊາດ.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your Nationality.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select showSearch
                    placeholder="Choose Nationality"
                    key={regis_id}
                    onChange={e => { onNationalityChange(e) }}
                    className="form-label-15"
                    style={{ width: '100%' }}
                  >
                    {/* <Option className="form-label-15" key={regis_id} value="default">Choose</Option> */}
                    {
                      laos.map(({ demonym }, key) => <Option key={key} value={demonym}>{demonym}</Option>)
                    }
                    {
                      nationalities.map((demonym, key) => <Option key={key} value={demonym}>{demonym}</Option>)
                    }
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ແຂວງ Province <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={provinceErr ? 'error' : ''} help={provinceErr || ''} >
                {form.getFieldDecorator(`province_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ທີ່ຢູ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your address.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="Province" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ເມືອງ District <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={cityErr ? 'error' : ''} help={cityErr || ''} >
                {form.getFieldDecorator(`city_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ທີ່ຢູ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your address.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="District" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ບ້ານ Village <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={addressErr ? 'error' : ''} help={addressErr || ''} >
                {form.getFieldDecorator(`address_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ທີ່ຢູ່.</span></Row>
                          <Row><span className="form-label-15 spn-red">Please input your address.</span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="Village" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ໜັງສືຜ່ານແດນ Passport ຫຼື ເລກບັດປະຈຳຕົວ <span style={{ color: "red" }}>*</span></Row>
            <Row>
              <FormItem validateStatus={identityErr ? 'error' : ''} help={identityErr || ''} >
                {form.getFieldDecorator(`identity_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາໃສ່ໜັງສືຜ່ານແດນ Passport ຫຼື / Or ເລກບັດປະຈຳຕົວ ID.</span></Row>
                          {/* <Row><span className="form-label-15 spn-red">Please input your address.</span></Row> */}
                        </Col>
                      </Row>
                  }]
                })(
                  <Input placeholder="ID Card" />
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ຂະໜາດເສື້ອ Finisher / Finisher Shirt Size<span style={{ color: "red" }}>*</span>     <a onClick={() => { setShowImageFinisherShirtModal(true) }}><Icon type="info-circle" /></a></Row>
            <Row>
              <FormItem validateStatus={finisherShirtsizeErr ? 'error' : ''} help={finisherShirtsizeErr || ''} >
                {form.getFieldDecorator(`FinisherShirtSize_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາເລືອກຂະໜາດເສື້ອ Finisher.</span></Row>
                          <Row><span className="form-label-15 spn-red"></span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select key={regis_id} placeholder='Choose Finisher Shirt Size' onChange={e => { onFinisherShirtChange(e) }} className="form-label-15" style={{ width: '100%' }}>
                    {/* <Option className="form-label-15" key={regis_id} value="default">Choose</Option> */}
                    {
                      finisher_shirt_sizes.map(({ code, name, bust }) => <Option key={regis_id} value={code}>{name} {bust}</Option>)
                    }
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
            <Row>ຂະໜາດເສື້ອແລ່ນ / Running-Shirt Size<span style={{ color: "red" }}>*</span>   <a onClick={() => { setShowImageTShirtModal(true) }}><Icon type="info-circle" /></a></Row>
            <Row>
              <FormItem validateStatus={tShirtsizeErr ? 'error' : ''} help={tShirtsizeErr || ''} >
                {form.getFieldDecorator(`TShirtSize_${regis_id}`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [{
                    required: true,
                    message:
                      <Row type="flex" gutter={12}>
                        <Col><span className="form-label-15 spn-red"><Icon theme="filled" style={{ color: '#ED6E26' }} type="info-circle" /></span></Col>
                        <Col>
                          <Row><span className="form-label-15 spn-red">ກະລຸນາເລືອກຂະໜາດເສື້ອແລ່ນ.</span></Row>
                          <Row><span className="form-label-15 spn-red"></span></Row>
                        </Col>
                      </Row>
                  }]
                })(
                  <Select key={regis_id} placeholder='Choose Running-Shirt Size' onChange={e => { onTShirtChange(e) }} className="form-label-15" style={{ width: '100%' }}>
                    {/* <Option className="form-label-15" key={regis_id} value="default">Choose</Option> */}
                    {
                      t_shirt_sizes.map(({ code, name, bust }) => <Option key={regis_id} value={code}>{name} {bust}</Option>)
                    }
                  </Select>
                )}
              </FormItem>
            </Row>
          </Col>

          <Modal
            visible={showImageFinisherShirtModal}
            onCancel={() => { setShowImageFinisherShirtModal(false) }}
            okButtonProps={{ hidden: true }}
          >
            <img style={{ width: '100%' }} src={Finisher_png} alt="" />
          </Modal>

          <Modal
            visible={showImageTShirtModal}
            onCancel={() => { setShowImageTShirtModal(false) }}
            okButtonProps={{ hidden: true }}
          >
            <img style={{ width: '100%' }} src={Running_png} alt="" />
          </Modal>

        </Row>
        {/* </Row> */}
      </div>
    </div >
  )
}