import React from "react";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Collapse
} from "antd";
import moment from "moment";
import { axiosInstant } from "./../../../../service/axios";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;
function callback(key) { }
function handleChangeSelect() { }
class ReportSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      races: []
    };
  }
  handleChangeDistance = value => {
    this.setState({ showClass: true });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axiosInstant
          .get("/books")
          .then(({ data: { books } }) => {
            const getData = books.sort(function (a, b) {
              var keyA = new Date(a.created_at),
                keyB = new Date(b.created_at);
              // Compare the 2 dates
              if (keyA > keyB) return -1;
              if (keyA < keyB) return 1;
              return 0;
            }).reduce((prev, cur) => {
              const { status, attendee, created_at, id } = cur;
              const newItem = { attendee, status, created_at, id }
              return prev.concat(newItem)
            }, [])
            let filtered = getData.reduce((result, item) => {
              if (result.indexOf(item.id) < 0) {
                result.push(item.id)
              }
              return result
            }, []).reduce((result, id) => {
              const children = getData.filter(item => item.id === id);
              result = result.concat(
                children.map((item, index) => ({
                  ...item,
                  rowSpan: index === 0 ? children.length : 0,
                }))
              )
              return result;
            }, [])
            const filterId = id => {
              return r => r.id === id;
            };
            const filterStatusPayment = payment => {
              return r => {
                switch (payment) {
                  case "Pending":
                    return r.status === "Pending";
                  case "Success":
                    return r.status === "Success" || r.status === "ShirtSuccess" || r.status === "RewardSuccess40KM" || r.status === "RewardSuccess50KM" || r.status === "RewardSuccess60KM";
                  case "Denied":
                    return r.status === "Denied";
                  case "Complete":
                    return r.status === "Complete";
                  default:
                    break;
                }
              };
            };


            let distan_search = "";
            const filterFirstName = first_name => {
              return r =>
                r.attendee.first_name.toLowerCase() ===
                first_name.toLowerCase();
            };
            const filterLastName = last_name => {
              return r =>
                r.attendee.last_name.toLowerCase() === last_name.toLowerCase();
            };
            const filterEmail = email => {
              return r =>
                r.attendee.contact.email === email;
            };
            const filterPhone = phone => {
              return r =>
                r.attendee.contact.phone === phone;
            };
            const filterDistance = distance => {

              distan_search = this.state.races.filter(race => race.id === distance)[0].name

              return r => r.attendee.race.id === distance;
            };
            const filterBikePrice = id => {
              return r => {
                const bike_price = r.attendee.bike_price;
                return bike_price !== undefined && bike_price.id === id;
              };
            };
            const filterAgeRange = age_range => {
              if (age_range === "16-29") {
                return r =>
                  this.getAge(moment(), moment(r.attendee.birth_day)) >= 16 && this.getAge(moment(), moment(r.attendee.birth_day)) <= 29;
              } else if (age_range === "30-39") {
                return r =>
                  this.getAge(moment(), moment(r.attendee.birth_day)) >= 30 && this.getAge(moment(), moment(r.attendee.birth_day)) <= 39;
              } else if (age_range === "40-49") {
                return r =>
                  this.getAge(moment(), moment(r.attendee.birth_day)) >= 40 && this.getAge(moment(), moment(r.attendee.birth_day)) <= 49;
              } else if (age_range === "50+") {
                return r =>
                  this.getAge(moment(), moment(r.attendee.birth_day)) >= 50;
              } else {
                return r =>
                  this.getAge(moment(), moment(r.attendee.birth_day)) <= 16;
              }
            }
            const filterCreated = created_at => {
              return r => {
                let start_date = moment(created_at[0]).format("YYYY-MM-DD");
                let end_date = moment(created_at[1]).format("YYYY-MM-DD");
                let date = moment(r.created_at).format("YYYY-MM-DD");
                return date >= start_date && date <= end_date;
              };
            };
            const filterGender = gender => {
              return r =>
                r.attendee.gender === gender;
            }
            if (values.id !== undefined && values.id !== "") {
              filtered = filtered.filter(filterId(values.id));
            }
            if (values.status_payment !== "") {
              filtered = filtered.filter(
                filterStatusPayment(values.status_payment)
              );
            }
            if (values.first_name !== undefined && values.first_name !== "") {
              filtered = filtered.filter(filterFirstName(values.first_name));
            }
            if (values.last_name !== undefined && values.last_name !== "") {
              filtered = filtered.filter(filterLastName(values.last_name));
            }
            if (values.email !== undefined && values.email !== "") {
              filtered = filtered.filter(filterEmail(values.email));
            }
            if (values.phone !== undefined && values.phone !== "") {
              filtered = filtered.filter(filterPhone(values.phone));
            }
            if (values.distance !== undefined && values.distance !== "") {
              filtered = filtered.filter(filterDistance(values.distance));
            }
            if (values.bike_price) {
              filtered = filtered.filter(filterBikePrice(values.bike_price));
            }
            if (values.gender !== undefined && values.gender !== "") {
              filtered = filtered.filter(filterGender(values.gender))
            }
            if (values.created_at !== undefined) {
              if (values.created_at.length > 0) {
                filtered = filtered.filter(filterCreated(values.created_at));
              }
            }
            if (values.age_range !== undefined && values.age_range !== "") {
              filtered = filtered.filter(filterAgeRange(values.age_range))
            }
            filtered = filtered.filter(item => {
              return item.cancel !== true;
            });
            this.props.bindData(filtered, distan_search);
          })
      } else {
      }
    });
  };

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount() {
    axiosInstant.get('/races').then(({ data: { races } }) => {
      this.setState({
        races
      })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Collapse
          defaultActiveKey={["1"]}
          onChange={callback}
          style={{ marginBottom: "20px" }}
        >
          <Panel header="ຕົວກອງ" key="1">
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ລະຫັດລົງທະບຽນ">
                    {getFieldDecorator("id")(<Input id="id" />)}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ຊື່">
                    {getFieldDecorator("first_name")(<Input id="first_name" />)}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ນາມສະກຸນ">
                    {getFieldDecorator("last_name")(<Input id="last_name" />)}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ອີເມວ">
                    {getFieldDecorator("email")(<Input id="email" type="email" />)}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ເບີໂທ">
                    {getFieldDecorator("phone")(<Input id="phone" type="phone" />)}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ສະຖານະການຈ່າຍ">
                    {getFieldDecorator("status_payment", {
                      initialValue: ""
                    })(
                      <Select onChange={handleChangeSelect}>
                        <Option value="">ເລືອກ</Option>
                        <Option value="Pending">ລໍຖ້າການຈ່າຍເງິນ</Option>
                        <Option value="Success">ຈ່າຍເງິນແລ້ວ</Option>
                        <Option value="Cancel">ຍົກເລີກ</Option>
                        <Option value="Complete">ສຳເລັດແລ້ວ</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ໄລຍະທາງ">
                    {getFieldDecorator("distance", {
                      initialValue: ""
                    })(
                      <Select onChange={this.handleChangeDistance}>
                        <Option value="">ເລືອກ</Option>
                        {this.state.races.map(race => {
                          return (
                            <Option key={race.id} value={race.id}>{race.name} - {race.description}</Option>
                          )
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={6} sm={12}>
                  <Form.Item label="ເພດ">
                    {getFieldDecorator("gender", {
                      initialValue: ""
                    })(
                      <Select onChange={handleChangeSelect}>
                        <Option value="">ເລືອກ</Option>
                        <Option value="FEMALE">ຍິງ</Option>
                        <Option value="MALE">ຊາຍ</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={12} sm={24}>
                  <Form.Item label="ລົງທະບຽນຈາກວັນທີ - ຫາວັນທີ">
                    {getFieldDecorator("created_at")(
                      <RangePicker format="DD/MM/YYYY" />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={24} sm={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      ຄົ້ນຫາ
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </>
    );
  }
}

export default Form.create()(ReportSearch);
