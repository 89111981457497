import Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { numberFormat } from "../../../assets/js/custom";

const checkRunStatus = status => {
  let statusName = "";
  switch (status) {
    case "Pending":
      statusName = "ລໍຖ້າ";
      break;
    case "Success":
      statusName = "ລໍຖ້າ";
      break;
    case "Complete":
      statusName = "ລໍຖ້າ";
      break;
    case "ShirtSuccess":
      statusName = "ຮັບເສື້ອແລ່ນແລ້ວ";
      break;
    case "Denied":
      statusName = "ຜິດພາດ";
      break;
    case "RewardSuccess40KM":
      statusName = "ສຳເລັດ 40 Km";
      break;
    case "RewardSuccess50KM":
      statusName = "ສຳເລັດ 50 Km";
      break;
    case "RewardSuccess60KM":
      statusName = "ສຳເລັດ 60 Km";
      break;
    case "Cancel":
      statusName = "ຍົກເລີກ";
      break;
    default:
      break;
  }
  return statusName;
};

const checkStatus = status => {
  let statusName = "";
  switch (status) {
    case "Pending":
      statusName = "ລໍຖ້າຈ່າຍເງິນ";
      break;
    case "Success":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "ShirtSuccess":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "RewardSuccess40KM":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "RewardSuccess50KM":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "RewardSuccess60KM":
      statusName = "ຈ່າຍເງິນສຳເລັດ";
      break;
    case "Denied":
      statusName = "ລໍຖ້າເອົາເຄື່ອງ";
      break;
    case "Complete":
      statusName = "ສຳເລັດແລ້ວ";
      break;
    case "Cancel":
      statusName = "ຍົກເລີກ";
      break;
    default:
      break;
  }
  return statusName;
};

const getAge = (dateNow, birthDate) => {
  var age = dateNow.format("YYYY") - birthDate.format("YYYY");
  var m = dateNow.format("MM") - birthDate.format("MM");
  if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
    age--;
  }
  return age;
};

export const exportExcelReport = items => {
  var workbook = new Excel.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  var worksheet = workbook.addWorksheet("Publications");
  worksheet.mergeCells("A1", "K1");
  worksheet.getCell("A1").value = "ລາຍງານການລົງທະບຽນ";
  worksheet.getRow(2).values = [
    "ວັນທີລົງທະບຽນ",
    "ລະຫັດລົງທະບຽນ",
    "ຊື່ ແລະ ນາມສະກຸນ",
    "ຊື່ໃນບິບ",
    "ເລກບິບ",
    "ເພດ",
    "ເບີໂທ",
    "ອີເມວ",
    "ລາຄາ",
    "ຂະໜາດເສື້ອແລ່ນ",
    "ຂະໜາດເສື້ອ Finisher",
    "ສະຖານະການຈ່າຍເງີນ",
    "ສະຖານະການແລ່ນ",
    "ໄລຍະທາງສະສົມ"
  ];
  worksheet.columns = [
    { key: "datetime", width: 15 },
    { key: "code", width: 25 },
    { key: "fullname" },
    { key: "nick_name" },
    { key: "bib_id" },
    { key: "gender" },
    { key: "phone" },
    { key: "email" },
    { key: "bike_price" },
    { key: "t_shirt_size" },
    { key: "finisher_shirt_size" },
    { key: "status", width: 12 },
    { key: "run_status", width: 12 },
    { key: "total_submit_run" },
  ];
  var firstRow = worksheet.getRow(1);
  firstRow.alignment = { vertical: "middle", horizontal: "center" };
  firstRow.height = 20;
  firstRow.font = { name: "Phetsarath OT", family: 4, size: 16, bold: true };

  var secondRow = worksheet.getRow(2);
  secondRow.font = { name: "Phetsarath OT", family: 4, size: 10, bold: true };
  secondRow.alignment = { vertical: "middle", horizontal: "center" };
  secondRow.height = 20;
  let count = 3, code = "";


  items.forEach(item => {

    worksheet.addRow({
      datetime: moment(item.created_at).format("DD/MM/YYYY H:mm:s"),
      code: item.id,
      fullname: item.attendee.first_name + " " + item.attendee.last_name,
      nick_name: item.attendee.nick_name,
      bib_id: item.attendee.bib_id,
      gender: item.attendee.gender === "MALE" ? "ຊາຍ" : "ຍິງ",
      phone: item.attendee.contact.phone,
      email: item.attendee.contact.email,
      t_shirt_size: item.attendee.shirt_size.name_tshirt,
      finisher_shirt_size: item.attendee.shirt_size.name_finisher,
      bike_price: numberFormat(item.attendee.race.price),
      status: checkStatus(item.status),
      run_status: checkRunStatus(item.status),
      total_submit_run: item.attendee.total_submit_run,
    });
    if (code !== item.id) {
      code = item.id
    } else {
      const mergeCount = count - 1
      worksheet.mergeCells(`B${mergeCount}`, `B${count}`)
      worksheet.mergeCells(`A${mergeCount}`, `A${count}`)
      worksheet.mergeCells(`J${mergeCount}`, `J${count}`)
      worksheet.getCell(`B${mergeCount}`).alignment = { vertical: "middle" }
      worksheet.getCell(`A${mergeCount}`).alignment = { vertical: "middle" }
      worksheet.getCell(`J${mergeCount}`).alignment = { vertical: "middle" }
    }
    worksheet.getRow(count++).font = {
      name: "Phetsarath OT",
      family: 4,
      size: 12
    };
  });
  workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(
      blob,
      `ລາຍງານການລົງທະບຽນ ${moment(new Date()).format("DD/MM/YYYY H:mm:s")}.xlsx`
    );
  });
};
