import { Button, Card, Col, Input, Modal, notification, Progress, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import BannerComponent from '../../components/banner'
import Navbar from '../../components/navbar'
import PowerBy from '../../components/powerby'
import { Img } from 'react-image'

import * as htmlToImage from 'html-to-image';

import loadingPic from './../../assets/images/Infinity.gif'

import { axiosInstant } from '../../../service/axios'
import Search from 'antd/lib/input/Search'

import LVR from '../../assets/images/LVR_5Km.png'
import Finisher from '../../assets/images/LVR_Finisher.png'
import moment from 'moment'
import download from 'downloadjs'

export default function Result(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [isBib, setIsBib] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);
  const [dataBar, setDataBar] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [imageLimit, setImageLimit] = useState(14);
  const [imageData, setImageData] = useState([]);
  const [allImageData, setAllImageData] = useState([]);

  const handleSearch = (e) => {
    getBooks(e);
  }

  const getBooks = (bib) => {
    setIsLoading(true);
    Promise.all([axiosInstant.get(`/seacrh/bib?bib=${bib}`
    )]
    ).then(([{ data: { result } }]) => {
      setIsLoading(false);
      setDataBar(result);
      setIsBib(true);
      setAllImageData(result.images);
      setImageData(result.images.filter((image, key) => key < imageLimit));

      if (result.accumulated >= 150) {
        setIsCertificate(true);
      }

    }).catch(error => {
      setIsLoading(false);
      notification.warning({
        message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
        description: <span className="txt-alert">ເລກບິບທີ່ທ່ານປ້ອນບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ !!<br />Your BIB number is wrong</span>,
        style: {
          backgroundColor: '#ffffffff',
          border: 'solid 1px red'
        },
        duration: 5
      })
    })

    // setDataBar(dataMock);
    // setIsBib(true);
    // setIsCertificate(true);
  }

  function getPercentage(distance) {
    let percent = distance * 100 / 150;
    return Math.floor(percent);
  }

  const onDownloadCertificate = () => {
    var certificate_div = document.getElementById('certificate');
    htmlToImage.toPng(certificate_div, { width: certificate_div.offsetWidth, pixelRatio: 1 })
      .then(function (dataUrl) {
        download(dataUrl, 'certificate.png');
      });
  }

  const onDownloadBib = () => {
    var bib_div = document.getElementById('bib')
    htmlToImage.toJpeg(bib_div, { width: bib_div.offsetWidth, pixelRatio: 1 })
      .then(function (dataUrl) {
        download(dataUrl, 'bib.jpg');
      });
  }

  const loadImageMore = () => {
    setImageData(allImageData.filter((image, key) => key < (imageLimit + 14)));
    setImageLimit(imageLimit + 10);
  }

  const handleClickImage = (image) => {
    setModalImage(image);
    setShowImageModal(true);
  }

  useEffect(() => {

    if (props.location.state) {
      getBooks(props.location.state.bib_id);
    }

  }, [])

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <BannerComponent />
      <Navbar />

      {
        isLoading &&
        <div>
          <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
        </div>
      }

      {
        !isLoading &&
        <>
          <Row style={{ marginTop: "50px" }} type="flex" justify="center" >
            <Col lg={18} xs={22}>
              <Row justify="center" type="flex">
                {/* <Col lg={18} xs={22}> */}
                <Search
                  placeholder="ປ້ອນເລກບິບ / BIB No."
                  onSearch={handleSearch}
                  style={{ width: isMobile ? "100%" : "30%" }}
                  size="large"
                />
                {/* </Col> */}
              </Row>
              {isBib &&
                <>
                  <Card style={{ marginBlock: "10px" }} bodyStyle={{ padding: "0" }}>
                    <Card bodyStyle={{ padding: "10px" }}>
                      <Row style={{ marginTop: "10px" }} type="flex" align="middle" >
                        <Col lg={8} xs={17}>
                          <p style={{ fontSize: "12pt", fontWeight: "bold", marginBottom: "0" }} >{dataBar.first_name} {dataBar.last_name} ({dataBar.nick_name})</p>
                        </Col>
                        <Col lg={8} xs={22}>
                          <Progress percent={getPercentage(dataBar.accumulated)} style={{ marginBottom: "5px" }} />
                        </Col>
                        <Col lg={8} xs={22}>
                          <p style={{ fontSize: "13pt", paddingLeft: isMobile ? "0" : "20px", fontWeight: "bold", marginBottom: "0" }} >ສົ່ງຜົນການແລ່ນລວມ : {dataBar.accumulated} Km</p>
                          <p style={{ fontSize: "11pt", paddingLeft: isMobile ? "0" : "20px", marginBottom: "0" }}>
                            ສົ່ງຜົນການແລ່ນລ່າສຸດ : {dataBar.submit_last ? moment(dataBar.submit_last).format("YYYY-MM-DD HH:mm:s") : "ຍັງບໍ່ໄດ້ເພິ່ມໄລຍະທາງ"}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Card>

                  <Row type="flex" justify="center" gutter={48}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                      {
                        !isMobile &&
                        <>
                          <div style={{
                            backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "400px", height: "400px", paddingLeft: "25px"
                          }}
                            id="bib"
                          >
                            <br />
                            <Row type="flex" style={{ paddingTop: "80px" }}>
                              <span
                                style={{ fontSize: "35pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center", }}
                              >
                                {dataBar.bib_id}
                              </span>
                            </Row>
                            <Row style={{ paddingTop: "0px" }}>
                              <span style={{ fontSize: "17pt", fontFamily: "Noto Sans Lao Defago" }}>
                                NAME:
                              </span>
                              <br />
                              <span style={{ fontSize: "20pt", fontFamily: "Noto Sans Lao Defago" }}>
                                {dataBar.nick_name.toUpperCase()}
                              </span>
                            </Row>
                          </div>
                        </>
                      }

                      {
                        isMobile &&
                        <div style={{
                          backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "90vw", height: "auto", paddingTop: "20%",
                          paddingBottom: "40%"
                        }}
                          id="bib"
                        >
                          <br />
                          <Row type="flex">
                            <span
                              style={{ fontSize: "28pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}
                            >
                              {dataBar.bib_id}
                            </span>
                          </Row>
                          <Row>
                            <span style={{ fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                              NAME:
                            </span>
                            <br />
                            <span style={{ fontSize: "12pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                              {dataBar.nick_name.toUpperCase()}
                            </span>
                          </Row>
                        </div>
                      }
                      <Button style={{ backgroundColor: '#de224c', width: isMobile ? "90vw" : "400px", height: '40px', borderRadius: '5px', marginTop: "10px" }}
                        htmlType="button" onClick={onDownloadBib}>
                        <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ BIB / Download BIB</span>
                      </Button>
                    </Col>

                    {isCertificate &&
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} className="form-label-25" style={{ paddingTop: isMobile ? "20px" : "10px" }}>
                        {
                          !isMobile &&
                          <div style={{
                            backgroundImage: `url(${Finisher})`, backgroundSize: "100% 100%", width: "500px", height: "395px",
                          }}
                            id="certificate"
                          >
                            <br />
                            <Row type="flex">
                              <span
                                style={{ fontSize: "40pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bolder", paddingLeft: "40px", paddingTop: "20px" }}
                              >
                                {dataBar.bib_id}
                              </span>
                            </Row>
                            <Row type="flex" justify="center">
                              <span style={{ fontSize: "20pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bolder", paddingTop: "220px" }}>
                                {dataBar.first_name} {dataBar.last_name}
                              </span>
                            </Row>
                          </div>
                        }

                        {
                          isMobile &&
                          <div style={{
                            backgroundImage: `url(${Finisher})`, backgroundSize: "100% 100%", fontWeight: "bolder", width: "90vw", height: "auto",
                          }}
                            id="certificate"
                          >
                            <br />
                            <Row type="flex" >
                              <span
                                style={{ fontSize: "25pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingTop: "7%", paddingLeft: "10%" }}
                              >
                                {dataBar.bib_id}
                              </span>
                            </Row>
                            <Row type="flex" justify="center">
                              <span style={{ fontSize: "18pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", paddingTop: "50%", paddingBottom: "2%" }}>
                                {dataBar.first_name} {dataBar.last_name}
                              </span>
                            </Row>
                          </div>

                        }
                        <Button style={{ backgroundColor: '#de224c', width: isMobile ? "90vw" : "500px", height: '40px', borderRadius: '5px', marginTop: "10px" }}
                          htmlType="button" onClick={onDownloadCertificate}>
                          <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ Certificate / Download Certificate</span>
                        </Button>
                      </Col>
                    }
                  </Row>
                  <Row style={{ marginTop: "40px" }} type="flex" justify="center">
                    {imageData.map((image, key) => {
                      return (
                        <Col className="imageContainner" key={key} xl={3} lg={3} md={7} sm={10} xs={10} style={{ margin: "5px" }}
                          onClick={() => handleClickImage(image.image)}
                        >
                          <Img src={image.image} style={{ width: "100%", height: "auto", maxHeight: "250px", minHeight: "250px", objectFit: "cover" }} />
                          <div
                            className="overlayImage"
                          >
                            <span style={{ fontSize: isMobile ? "10pt" : "12pt" }}> BIB : {image.bib_id}</span><br />
                            <span style={{ fontSize: isMobile ? "10pt" : "12pt" }}> ໄລຍະທາງ : {image.distance} Km</span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                  <Modal
                    visible={showImageModal}
                    onCancel={() => { setShowImageModal(false) }}
                    okButtonProps={{ hidden: true }}
                  >
                    <img style={{ width: '100%' }} src={modalImage} alt="" />
                  </Modal>
                  <Row type="flex" justify="center">
                    <Col>
                      <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '40px', borderRadius: '5px' }}
                        htmlType="button"
                        onClick={loadImageMore}
                      >
                        <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ເພີ່ມເຕີມ / More</span>
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            </Col>
          </Row>
        </>
      }
      <PowerBy />
    </div >
  )
}
