import React, { Component } from 'react'
import BannerComponent from '../../../components/banner'
import PowerBy from '../../../components/powerby'

import loadingPic from './../../../assets/images/Infinity.gif'
import { Row, Col, Button } from 'antd'
import { axiosInstant } from '../../../../service/axios'
import history from '../../../../history'

import LVR from '../../../assets/images/LVR_5Km.png'
import * as htmlToImage from 'html-to-image';

import { isMobile } from 'react-device-detect'
import Barcode from 'react-barcode'
import Navbar from '../../../components/navbar'
import download from 'downloadjs'

class CheckPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      books: '',
      paid_status: 'SUCCESS',
      // paid_status: 'FAILED',
      paid_data: [],
      book: {}
      // paid_data : {result:0,id:3597112,txtime:"29/08/2020 14:26:29",age:434391.0000000000000000000000000000000012,name:"JALANA VONGXAY",phone:77805085,userid:"3AE0D7675BFB287FE054D8D385BC8CF0",ticket:"X59MNZK07WMT",fccref:"160FTBP20242A2K7",amount:5000,ccy:"LAK",lakamount:5000,iid:"59SWX6RZ",uuid:"HJQ8O6GU",description:"HJQ8O6GU",voidable:1}
    }

  }

  cancleRegister() {
    history.push({
      pathname: '/'
    })
  }

  onDownloadBib() {
    var bib_div = document.getElementById('bib')
    htmlToImage.toJpeg(bib_div, { width: bib_div.offsetWidth, pixelRatio: 1 })
      .then(function (dataUrl) {
        download(dataUrl, 'bib.jpg');
      });
  }

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount = () => {
    // const { books } = this.props.location.state

    // this.setState({ books: books }, () => {
    //     setTimeout(() => {
    //         this.setState({ loading: false })
    //         axiosInstant.get(`books/${books}/onepay`).then((res) => {
    //             console.log(res.status);
    //             this.setState({
    //                 // paid_data: res.data,
    //                 loading: false
    //             }, () => {
    //                 // const { paid_data } = this.state
    //                 if (res.status === 204) {
    //                     // console.log(paid_data);
    //                     this.setState({ paid_status: "SUCCESS" })
    //                     localStorage.removeItem("bookingCode")
    //                 }
    //                 else if (res.status !== 204) {
    //                     this.setState({ paid_status: "FAILED" })
    //                 }
    //             })
    //         }).catch(() => {
    //             this.setState({ paid_status: "FAILED" })
    //         })
    //     }, 1000)
    //     // setTimeout(() => {
    //     //     axiosInstant.get(`books/${books}`).then((res) => {
    //     //         this.setState({
    //     //             book: res.data.book,
    //     //             loading: false
    //     //         })
    //     //     })
    //     // }, 1000)

    // })
    var qry_params = window.location.search
    var qry = new URLSearchParams(qry_params)
    var books = qry.get('bookingcode')
    localStorage.removeItem("bookingCode");
    axiosInstant.get(`/books/${books}`).then((res) => {
      if (res.data !== null) {
        if (res.data.book.status !== "Pending" || res.data.book.status !== "Denied") {
          this.setState({ paid_data: res.data.book, loading: false, books: books })
        } else {
          this.setState({ falsed: true })
        }
      } else {
        this.setState({ falsed: true })
      }
    }).catch((err) => {
      if (err) {
        this.setState({ falsed: true })
      }
    })

  }
  render() {
    const { loading, paid_status, paid_data } = this.state
    // console.log(attendees);
    return (
      <div>
        <BannerComponent />
        <Navbar />

        {!isMobile &&
          <div className="container-round-border mg-top-20" style={{ marginLeft: "20%", marginRight: "20%" }} >

            <Row type="flex" justify="center">
              {
                loading &&
                <img src={loadingPic} alt="loading" />
              }
              {
                !loading && paid_status === "SUCCESS" &&
                <>
                  <div style={{ paddingBottom: "50px", paddingTop: "30px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                    </Row>
                    <Row type="flex" justify="center">
                      <span style={{ color: "green", fontSize: "18pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                    </Row>
                    <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                    <div style={{
                      backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "400px", height: "400px", paddingLeft: "25px"
                    }}
                      id="bib"
                    >
                      <br />
                      <Row type="flex" style={{ paddingTop: "80px" }}>
                        <span
                          style={{ fontSize: "35pt", fontFamily: "Noto Sans Lao Defago", fontWeight: "bold", textAlign: "center", }}
                        >
                          {this.state.paid_data.attendee.bib_id}
                        </span>
                      </Row>
                      <Row style={{ paddingLeft: "5px" }}>
                        <span style={{ fontSize: "17pt", fontFamily: "Noto Sans Lao Defago" }}>
                          NAME:
                        </span>
                        <br />
                        <span style={{ fontSize: "20pt", fontFamily: "Noto Sans Lao Defago" }}>
                          {this.state.paid_data.attendee.nick_name.toUpperCase()}
                        </span>
                      </Row>
                    </div>
                    <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '40px', borderRadius: '5px', marginTop: "10px" }}
                      htmlType="button" onClick={this.onDownloadBib}>
                      <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ BIB / Download BIB</span>
                    </Button>
                    <hr style={{ marginLeft: "10%", marginRight: "10%" }} />

                    <Row type="flex" justify="center" gutter={48}>
                      <Col>
                        <a onClick={e => { this.cancleRegister() }} href="javascript:;" >
                          <span
                            style={{ color: "orangered", textDecoration: "underline", fontSize: "13pt" }}
                            className="txt-bold txt-submit-btn"
                          >
                            ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </>
              }
              {
                !loading && paid_status === "FAILED" &&
                <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                  <Row type="flex" justify="center">
                    <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                  </Row>
                  <Row type="flex" justify="center" gutter={48}>
                    <Col>
                      <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                    </Col>
                  </Row>
                </div>
              }
            </Row>

          </div>
        }

        {isMobile &&
          <>
            <div className="container-round-border mg-top-20 mg-left-20 mg-right-20" >

              <Row type="flex" justify="center">
                {
                  loading &&
                  <img src={loadingPic} alt="loading" />
                }
                {
                  !loading && paid_status === "SUCCESS" &&
                  <>
                    <div style={{ paddingBottom: "25px", paddingTop: "20px" }}>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>ການຊຳລະເງິນຂອງທ່ານສຳເລັດແລ້ວ</span>
                      </Row>
                      <Row type="flex" justify="center">
                        <span style={{ color: "green", fontSize: "16pt", fontFamily: "Noto Sans Lao Defago" }}>Your Payment is Completed</span>
                      </Row>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                      <div style={{
                        backgroundImage: `url(${LVR})`, backgroundSize: "100% 100%", width: "90vw", height: "auto", paddingTop: "20%",
                        paddingBottom: "40%"
                      }}
                        id="bib"
                      >
                        <br />
                        <Row type="flex">
                          <span
                            style={{ fontSize: "28pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}
                          >
                            {this.state.paid_data.attendee.bib_id}
                          </span>
                        </Row>
                        <Row>
                          <span style={{ fontSize: "10pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                            NAME:
                          </span>
                          <br />
                          <span style={{ fontSize: "12pt", fontWeight: "bold", fontFamily: "Noto Sans Lao Defago", paddingLeft: "7%" }}>
                            {this.state.paid_data.attendee.nick_name.toUpperCase()}
                          </span>
                        </Row>
                      </div>
                      <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '40px', borderRadius: '5px', marginTop: "10px" }}
                        htmlType="button" onClick={this.onDownloadBib}>
                        <span className="white-txt txt-bold txt-submit-btn" style={{ fontSize: "12pt" }}>ດາວໂຫຼດ BIB / Download BIB</span>
                      </Button>
                      <hr style={{ marginLeft: "10%", marginRight: "10%" }} />
                    </div>
                  </>
                }
                {
                  !loading && paid_status === "FAILED" && paid_status === "Pending" &&
                  <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                    <Row type="flex" justify="center">
                      <span style={{ color: "red", fontSize: "34px", fontFamily: "Noto Sans Lao Defago" }}>ເກີດຂໍ້ຜິດພາດ ກະລຸນາຈ່າຍເງິນອີກຄັ້ງ.</span>
                    </Row>
                    <Row type="flex" justify="center" gutter={48}>
                      <Col>
                        <Button style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }} onClick={() => { window.history.back() }}  ><span className="white-txt txt-bold txt-submit-btn">ຍ້ອນກັບ</span></Button>
                      </Col>
                    </Row>
                  </div>
                }
              </Row>
            </div>

            <Row type="flex" justify="center" gutter={48}>
              <Col>
                <a onClick={e => { this.cancleRegister() }} href="javascript:;" >
                  <span
                    style={{ color: "#FA4700", textDecoration: "underline", fontSize: "12pt" }}
                    className="txt-bold txt-submit-btn"
                  >
                    ກັບສູ່ໜ້າລົງທະບຽນ / Back to Register Form
                                                    </span>
                </a>
              </Col>
            </Row>
          </>
        }

        <div className="mg-top-20 mg-bottom-20 footer-container">
          <PowerBy />
        </div>
      </div >
    )
  }
}

export default CheckPayment