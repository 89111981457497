import axios from "axios";

import { endpoint } from "./../config";
import LocalStorageService from "./LocalStorageService";
import history from './../history'
export const axiosInstant = axios.create({
  baseURL: endpoint,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": "no-cache"
  }
});

export const axiosInstantMultipart = axios.create({
  baseURL: endpoint,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    "Cache-Control": "no-cache"
  }
});

let isRefreshing = false;
let failedQueue = [];

axiosInstant.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
axiosInstant.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response === undefined) {
      return Promise.reject(error)
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === `${endpoint}/users/newtoken`
    ) {
      // window.location.replace(`${clientEndpoint}/nimda/login`)
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }
      history.push('/nimda/login')
      originalRequest._retry = true;
      isRefreshing = true;
    }
    return Promise.reject(error);
  }
);

axiosInstantMultipart.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
axiosInstantMultipart.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response === undefined) {
      return Promise.reject(error)
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === `${endpoint}/users/newtoken`
    ) {
      // window.location.replace(`${clientEndpoint}/nimda/login`)
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }
      history.push('/nimda/login')
      originalRequest._retry = true;
      isRefreshing = true;
    }
    return Promise.reject(error);
  }
);
