import * as actionTypes from './../../../constants/actionTypes'

const initialState = {
  agreement: false,
  formvalue: {
    attendee: [
      {
        first_name: "",
        last_name: "",
        nick_name: "",
        gender: "",
        nationality: "",
        shirt_size: {
          finisher: "xxx",
          tshirt: "xxx",
        },
        birth_day: "",
        identity: "",
        race: {
          id: "123",
          price: ""
        },
        contact: {
          phone: "",
          email: "",
        },
        address: {
          street: "",
          city: "",
          state: "",
          country: ""
        }
      }
    ]
  },
  panes: {
    key: 0,
    split: []
  },
  activeKey: "0",
  Agreechecked: false,
  enableNext: false,
  current: 0,
  race: { data: [] },
  finisher_shirt_size: { data: [] },
  t_shirt_size: { data: [] },
}

export default function regisReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTRATION:
      state = Object.assign({}, state, { formvalue: action.payload })
      return state
    case actionTypes.ENABLENEXT:
      state = Object.assign({}, state, { enableNext: action.payload })
      return state
    case actionTypes.SAVE_PANES:
      state = Object.assign({}, state, { panes: action.payload })
      return state
    case actionTypes.CHANGE_AFTERSUBMIT:
      state = Object.assign({}, state, { current: action.payload })
      return state
    case actionTypes.RACE:
      state = Object.assign({}, state, { race: action.payload })
      return state
    case actionTypes.FINISHER_SHIRT_SIZES:
      state = Object.assign({}, state, { finisher_shirt_size: action.payload })
      return state
    case actionTypes.T_SHIRT_SIZES:
      state = Object.assign({}, state, { t_shirt_size: action.payload })
      return state
    default:
      return state
  }
}

