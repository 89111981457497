import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Row } from 'antd';
import Image from 'react-image-webp'
import Cover from './../assets/images/banner/LVR_Web.png'
import CoverWebp from './../assets/images/banner/LVR_Web.webp'
import { isMobile } from 'react-device-detect';
import CoverMobile from './../assets/images/banner/LVR_Mobile.png'
import CoverMobileWebp from './../assets/images/banner/LVR_Mobile.webp'

class BannerComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <Row >
          {!isMobile &&
            <Image
              src={Cover}
              webp={CoverWebp}
              alt="LVR"
              style={{ width: '100%', height: 'auto' }}
            />
          } 
          {isMobile &&
            <Image
              src={CoverMobile}
              webp={CoverMobileWebp}
              alt="LVR"
              style={{ width: '100%', height: 'auto' }}
            />
          }
        </Row>
      </React.Fragment>
    )
  }
}

export default BannerComponent