import React from "react";
import { Card, Button, Modal, Table, Divider } from "antd";
import { axiosInstant } from "../../../../service/axios";
import EditDistance from "./EditDistance";
import loadingPic from '../../../assets/images/Infinity.gif'
import errorPic from '../../../assets/images/500error.gif'
import { isMobile } from "react-device-detect";

class Distance extends React.Component {
  state = {
    visibleModal: false,
    visibleEditModal: false,
    races: [],
    race: {},
    isLoading: true,
    isError: false
  };
  componentDidMount() {
    axiosInstant.get(`/races`).then(({ data: { races } }) => {
      this.setState({ races, isLoading: false })
    }).catch(e => {
      this.setState({
        isError: true,
        isLoading: false
      })
    });
  }

  showUpdate = (e, item) => {
    this.setState({ visibleEditModal: true, race: item });
  }

  showModal = () => {
    this.setState({ visibleModal: true })
  }
  handleCancel = () => {
    this.setState({ visibleModal: false })
  }
  render() {
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ໄລຍະທາງ",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "ລາຄາ",
        dataIndex: "price",
        key: "price"
      },
      {
        title: "ລາຍລະອຽດ",
        dataIndex: "policy",
        key: 'policy'
      },
      {
        title: "ຕັ້ງຄ່າ",
        dataIndex: "setting",
        key: "setting",
        render: item => {
          return (
            <span>
              <Button type="link" onClick={e => this.showUpdate(e, item)}>ແກ້ໄຂ</Button>{" "}
              <Divider type="vertical" />
              <Button type="link" onClick={e => this.showDeleteConfirm(e, item.code)}>ລຶບ</Button>
            </span>
          );
        }
      }
    ];
    const { races } = this.state
    const { race, isError, isLoading } = this.state
    // console.log(races);
    return (
      <>
        {
          isError &&
          <div>
            <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
          </div>
        }

        {
          isLoading &&
          <div>
            <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
          </div>
        }

        {
          !isLoading && !isError &&
          <Card
            title="ລາຍການໄລຍະທາງ"
            extra={
              <Button type="primary" onClick={this.showModal}>
                ເພີ່ມໄລຍະທາງ
            </Button>
            }
          >
            <Table columns={columns} dataSource={
              races.map((race, index) => (
                {
                  key: index + 1,
                  number: index + 1,
                  name: race.name,
                  policy: `ອາຍຸຕ້ອງຫຼາຍກວ່າ ${race.policy.min_attendee_age}`,
                  price: race.price,
                  setting: race
                }
              ))
            } />
          </Card>
        }
        {this.state.visibleModal && (
          <Modal
            title="ເພີ່ມໄລຍະທາງ"
            visible={this.state.visibleModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{ fontFamily: "Phetsarath OT" }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            {/* <AddCouPonModal bindinsert={this.bindInsert} /> */}
          </Modal>
        )}
        {this.state.visibleEditModal && (
          <EditDistance item={race} />
        )}
      </>
    );
  }
}
export default Distance;
