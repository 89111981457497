import React, { Component } from "react";
import { axiosInstant } from "../../../../service/axios";
import moment from "moment";
import { Card, Table, Tag, Icon, Divider, Button, Row, Col } from "antd";
import { exportExcelReport } from "./ExportExcelReport";
import { exportExcelReportAll } from "./ExportExcelReportAll"
import ReportSearch from "./ReportSearch";
import { isMobile } from "react-device-detect";
import loadingPic from '../../../assets/images/Infinity.gif'
import errorPic from '../../../assets/images/500error.gif'

class ReportRegister extends Component {
  state = {
    items: [],
    showClass: false,
    searching: false,
    shirt_sizes: [],
    t_shirt_count: [],
    finisher_shirt_count: [],
    distance_search: "",
    isLoading: true,
    isError: false
  };

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  componentDidMount() {
    Promise.all([axiosInstant.get(`/books`), axiosInstant.get("/shirt-sizes")])
      .then(([{ data: { books } }, { data: { shirts } }]) => {
        this.setState({
          items: books.sort(function (a, b) {
            var keyA = new Date(a.created_at),
              keyB = new Date(b.created_at);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }),
          t_shirt_count: this.countTShirt(books),
          finisher_shirt_count: this.countFinisherShirt(books),
          shirt_sizes: shirts,
          isLoading: false
        });

      })
      .catch(error => {
        this.setState({
          isError: true,
          isLoading: false
        })
      });

  }
  fetchData = () => {
    return this.state.items.map((item, i) => {
      const { attendee, created_at, id } = item;
      // const age = this.getAge(moment(), moment(item.attendee.birth_day));
      return {
        key: attendee.id,
        number: i + 1,
        created_at: moment(created_at).format("DD/MM/YYYY"),
        code: id,
        full_name: `${attendee.first_name} ${attendee.last_name}`,
        nick_name: item.attendee.nick_name,
        bib_id: item.attendee.bib_id,
        email: attendee.contact.email,
        phone: attendee.contact.phone,
        t_shirt_size: attendee.shirt_size.name_tshirt,
        finisher_shirt_size: attendee.shirt_size.name_finisher,
        bike_price: attendee.race.price,
        status: item,
        rowSpan: item.rowSpan
      };
    })
  }
  settingCondition(item) {
    let status = "";
    switch (item.status) {
      case "Pending":
        status = <Tag color="orange">ລໍຖ້າຈ່າຍເງິນ</Tag>;
        break;
      case "Success":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "ShirtSuccess":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess40KM":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess50KM":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "RewardSuccess60KM":
        status = <Tag color="green">ຈ່າຍເງິນສຳເລັດ</Tag>;
        break;
      case "Denied":
        status = <Tag color="red">ບໍ່ສາມາດໃຊ້ງານໄດ້</Tag>;
        break;
      case "Complete":
        status = <Tag color="green">ສຳເລັດແລ້ວ</Tag>;
        break;
      case "Cancel":
        status = <Tag color="red">ຍົກເລີກ</Tag>;
        break;
      default:
        status = "";
        break;
    }
    return status;
  }
  exportExcel = () => {
    exportExcelReport(this.state.items);
  };
  exportExcelAll = () => {
    console.log(this.state.items);
    exportExcelReportAll(this.state.items)
  }

  countTShirt = (items) => {

    let checkShirtSize = [],
      countDataShirtSize = [];

    items.forEach(item => {
      let shirtSize = item.attendee.shirt_size.tshirt;
      let checkInclude = checkShirtSize.includes(shirtSize);
      if (!checkInclude) {
        checkShirtSize.push(shirtSize);
        countDataShirtSize[shirtSize] = 1;
      } else {
        countDataShirtSize[shirtSize] =
          countDataShirtSize[shirtSize] + 1;
      }
    })

    return countDataShirtSize;

  }

  countFinisherShirt = (items) => {

    let checkShirtSize = [],
      countDataShirtSize = [];

    items.forEach(item => {
      let shirtSize = item.attendee.shirt_size.finisher;
      let checkInclude = checkShirtSize.includes(shirtSize);
      if (!checkInclude) {
        checkShirtSize.push(shirtSize);
        countDataShirtSize[shirtSize] = 1;
      } else {
        countDataShirtSize[shirtSize] =
          countDataShirtSize[shirtSize] + 1;
      }
    })

    return countDataShirtSize;

  }

  bindData = (items, distance_search) => this.setState({
    items,
    searching: true,
    distance_search,
    t_shirt_count: this.countTShirt(items),
    finisher_shirt_count: this.countFinisherShirt(items),
    shirt_sizes: this.state.shirt_sizes
  }); // BindData
  renderRow = (value, row) => ({ children: value, props: { rowSpan: row.rowSpan } })
  render() {
    const columns = [
      {
        title: "ລຳດັບ",
        dataIndex: "number",
        key: "number"
      },
      {
        title: "ວັນທີລົງທະບຽນ",
        dataIndex: "created_at",
        key: "created_at",
        render: this.renderRow
      },
      {
        title: "ລະຫັດລົງທະບຽນ",
        dataIndex: "code",
        key: "code",
        render: this.renderRow
      },
      {
        title: "ຊື່ ແລະ ນາມສະກຸນ",
        dataIndex: "full_name",
        key: "full_name"
      },
      {
        title: "ຊື່ໃນບິບ",
        dataIndex: "nick_name",
        key: "nick_name"
      },
      {
        title: "ເລກບິບ",
        dataIndex: "bib_id",
        key: "bib_id"
      },
      {
        title: "ອີເມວ",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "ເບີໂທ",
        dataIndex: "phone",
        key: "phone"
      },
      {
        title: "ຂະໜາດເສື້ອແລ່ນ",
        dataIndex: "t_shirt_size",
        key: "t_shirt_size"
      },
      {
        title: "ຂະໜາດເສື້ອ Finisher",
        dataIndex: "finisher_shirt_size",
        key: "finisher_shirt_size",
      },
      {
        title: "ລາຄາ",
        dataIndex: "bike_price",
        key: "bike_price",
      },
      {
        title: "ສະຖານະການຈ່າຍເງິນ",
        dataIndex: "status",
        key: "status",
        render: (item, row) => this.renderRow(<span>{this.settingCondition(item)}
        </span>, row)
      }
    ];
    const pageLength = this.state.items ? this.state.items.length : 0;
    const { shirt_sizes, t_shirt_count, finisher_shirt_count, isError, isLoading } = this.state
    return (
      <>
        {
          isError &&
          <div>
            <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
          </div>
        }

        {
          isLoading &&
          <div>
            <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
          </div>
        }

        {
          !isLoading && !isError && (
            <Card
              title="ລາຍການລົງທະບຽນ"
              extra={
                <>
                  {" "}
                  <Button type="link" onClick={this.exportExcel}>
                    <Icon type="file-excel" style={{ fontSize: "20px" }} />
                  </Button>{" "}
                  <Divider type="vertical" />{" "}
                  <Button type="link" onClick={this.exportExcelAll}>
                    ExportAll
                </Button>
                </>
              }
            >
              <ReportSearch {...this.state} bindData={this.bindData} />
              <hr />
              <br />

              <Row type="flex" style={{ justifyContent: "center" }}>
                <Col>
                  <span
                    style={{ fontSize: "15pt", fontWeight: "bold" }}
                  >
                    {moment().format("MM/DD/YYYY, h:mm a")}
                  </span>
                </Col>
              </Row>
              <Row type="flex" style={{ justifyContent: "center" }}>
                <Col>
                  <span style={{ fontSize: "14pt", fontWeight: "bold" }}>ຂະໜາດເສື້ອແລ່ນ :<br />
                    {
                      shirt_sizes
                        .filter(val => val.shirt_type_id === "TSHIRT")
                        .map((shirt) => shirt.name + ` : ${t_shirt_count[shirt.code] === undefined ? 0
                          : t_shirt_count[shirt.code]} , `)
                    }
                  </span><br /><br />
                  <span style={{ fontSize: "14pt", fontWeight: "bold" }}>ຂະໜາດເສື້ອ Finisher : <br />
                    {
                      shirt_sizes
                        .filter(val => val.shirt_type_id === "FINISHER")
                        .map((shirt) => shirt.name + ` : ${finisher_shirt_count[shirt.code] === undefined ? 0
                          : finisher_shirt_count[shirt.code]} , `)
                    }
                  </span>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={this.fetchData()}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      "100",
                      "150",
                      "200",
                      "300",
                      "500",
                      pageLength.toString()
                    ],
                    position: "both"
                  }}
                ></Table>
              </div>
            </Card>
          )}
      </>
    );
  }
}

export default ReportRegister;
