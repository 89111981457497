import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Form, Checkbox, notification } from 'antd'
import moment from 'moment'

// import axios from 'axios'

import { axiosInstant } from './../../../service/axios'

import BannerComponent from './../../components/banner';

// import form 
import { CreateMainForm } from './components/mainregis'

// import action
import { register, race, finisher_shirt_size, t_shirt_size, enableNext, changeCurrent, savePanes } from './actions'
import history from '../../../history'
import Axios from 'axios'
import { isMobile } from 'react-device-detect'
import Navbar from '../../components/navbar'

import loadingPic from './../../assets/images/Infinity.gif'
import errorPic from './../../assets/images/500error.gif'


const MainRegis = ({
  regis_id,
  firstnameErr,
  lastnameErr,
  nick_nameErr,
  genderErr,
  nationalityErr,
  emailErr,
  phoneErr,
  addressErr,
  cityErr,
  provinceErr,
  countryErr,
  // racetypeErr,
  onDateofBirthChange,
  onGenderChange,
  onRaceCatChange,
  onFinisherShirtChange,
  onTShirtChange,
  onCountryChange,
  onNationalityChange,
  attendeeAge,
  attendeeGender,
  form,
  races,
  finisherShirtsizeErr,
  finisher_shirt_sizes,
  tShirtsizeErr,
  t_shirt_sizes,
  identityErr,
  countries,
  nationalities,
  laos,
  onChangeDate,
  onChangeMounth,
  onChangeYear,
  days,
  months,
  years,
  dateErr,
  monthErr,
  yearErr
}) => {
  return <CreateMainForm
    regis_id={regis_id}
    firstnameErr={firstnameErr}
    lastnameErr={lastnameErr}
    nick_nameErr={nick_nameErr}
    genderErr={genderErr}
    nationalityErr={nationalityErr}
    emailErr={emailErr}
    phoneErr={phoneErr}
    addressErr={addressErr}
    cityErr={cityErr}
    provinceErr={provinceErr}
    countryErr={countryErr}
    // racetypeErr={racetypeErr}
    onDateofBirthChange={onDateofBirthChange}
    onGenderChange={onGenderChange}
    onRaceCatChange={onRaceCatChange}
    onFinisherShirtChange={onFinisherShirtChange}
    onTShirtChange={onTShirtChange}
    onCountryChange={onCountryChange}
    onNationalityChange={onNationalityChange}
    attendeeAge={attendeeAge}
    attendeeGender={attendeeGender}
    form={form}
    races={races}
    finisherShirtsizeErr={finisherShirtsizeErr}
    finisher_shirt_sizes={finisher_shirt_sizes}
    tShirtsizeErr={tShirtsizeErr}
    t_shirt_sizes={t_shirt_sizes}
    identityErr={identityErr}
    countries={countries}
    nationalities={nationalities}
    laos={laos}
    onChangeDate={onChangeDate}
    onChangeMounth={onChangeMounth}
    onChangeYear={onChangeYear}
    days={days}
    months={months}
    years={years}
    dateErr={dateErr}
    monthErr={monthErr}
    yearErr={yearErr}
  />
}

class FormPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      panes: {
        split: [],
        key: 0
      },
      agreement: false,
      formvalue: {
        attendee: [
          {
            first_name: "",
            last_name: "",
            nick_name: "",
            gender: "",
            nationality: "",
            shirt_size: {
              finisher: "",
              tshirt: "",
            },
            birth_day: "",
            identity: "",
            race: {
              id: ""
            },
            contact: {
              phone: "",
              email: "",
            },
            address: {
              street: "",
              city: "",
              state: "",
              country: ""
            }
          }
        ]
      },
      attendeeAge: 0,
      attendeeGender: "",
      raceCate: [],
      activeKey: "0",
      Agreechecked: false,
      enableNext: false,
      current: 0,
      showbutton: false,
      races: [],
      finisher_shirt_sizes: [],
      t_shirt_sizes: [],
      countries: [],
      nationalities: [],
      race: { data: [] },
      finisher_shirt_size: { data: [] },
      t_shirt_size: { data: [] },
      laos: [],
      isLoading: true,
      isError: false,
      days: [],
      months: [],
      years: [],
      year: moment().year(),
      month: 1,
      day: 1,
      all_race: [],
      no_limit_age_race: [],
      prev_race_id: '',
      isFull: false
    }
  }

  getAge = (dateNow, birthDate) => {
    var age = dateNow.format("YYYY") - birthDate.format("YYYY");
    var m = dateNow.format("MM") - birthDate.format("MM");
    if (m < 0 || (m === 0 && dateNow.format("DD") < birthDate.format("DD"))) {
      age--;
    }
    return age;
  };

  onChangeDate = (e) => {
    this.setState({ day: e })
  }

  onChangeMounth = (e) => {
    this.setState({ month: e })
    if (e === 2) {
      if (this.state.year % 4 === 0) {
        this.dateSelectOption(29);
      } else {
        this.dateSelectOption(28)
      }
    }
    else if (e === 4 || e === 6 || e === 9 || e === 11) {
      this.dateSelectOption(30);
    }
    else {
      this.dateSelectOption(31);
    }
  }

  onChangeYear = (e) => {
    const { month } = this.state;

    if (month === 4 || month === 6 || month === 9 || month === 11) {
      this.dateSelectOption(30);
    }
    else {
      this.dateSelectOption(31);
    }

    if (e % 4 === 0) {
      if (month === 2) {
        this.dateSelectOption(29);
      }
    }
    else {
      if (month === 2) {
        this.dateSelectOption(28);
      }
    }

    this.setState({ year: e })
  }

  dateSelectOption = (day) => {

    // date Select:
    let d = [];
    for (let i = 1; i <= day; i++) {
      d.push(i);
      if (i === day) {
        this.setState({ days: d })
      }
    }

    let m = [];
    for (let i = 1; i <= 12; i++) {
      m.push(i);
      if (i === 12) {
        this.setState({ months: m })
      }
    }

    let max_year = moment().year();
    let y = [];
    for (let i = max_year; i >= 1950; i--) {
      y.push(i);
      if (i === 1950) {
        this.setState({ years: y })
      }
    }
  }

  onChange = value => {
    this.setState({ activeKey: value, loading: true })
  }

  checkedbox = e => {
    // const { formvalue } = this.props.regis
    if (e.target.checked === true) {
      this.setState({
        agreement: e.target.checked,
        enableNext: e.target.checked,
        current: 1,
        showbutton: true,
      })
    } else {
      this.setState({
        agreement: e.target.checked,
        enableNext: e.target.checked,
        current: 0,
        showbutton: false,
      })
    }
  }

  onDateofBirthChange = (e) => {
    var agediff = moment().diff(e, 'years', true)
    var age = Math.floor(agediff)
    this.setState({
      attendeeAge: age
    })
  }

  onGenderChange = (e) => {
    this.setState({
      attendeeGender: e
    })
  }

  onRaceCatChange = (e) => {
    this.setState({
      raceCate: e
    })
  }

  onFinisherShirtChange = (e) => {
    // console.log(e);
    this.setState({
      shirt_size: {
        finisher: e,
        tshirt: this.state.tshirt
      }
    })
  }

  onTShirtChange = (e) => {
    // console.log(e);
    this.setState({
      shirt_size: {
        tshirt: e,
        finisher: this.state.finisher
      }
    })
  }

  onCountryChange = (e) => {
    // console.log(e);

    if (e !== "default") {
      let nat = [];
      const { setFieldsValue } = this.props.form

      if (this.state.countries.filter(country => country.name === e).length > 0) {
        nat = this.state.countries.filter(country => country.name === e)[0].demonym

        this.setState({
          country: e,
          nationality: nat
        })

        setFieldsValue({
          ["nationality_" + 0]: nat,
        })
      }
      else {
        nat = this.state.laos.filter(country => country.name === e)[0].demonym

        this.setState({
          country: e,
          nationality: nat
        })

        setFieldsValue({
          ["nationality_" + 0]: nat,
        })
      }

    }

  }

  onNationalityChange = (e) => {
    // console.log(e);
    this.setState({
      nationality: e
    })
  }


  onClickTermsAndConditions() {
    this.props.form.validateFields((err, fieldsValue) => {
      const { formvalue } = this.state
      const key = Object.keys(fieldsValue)
      const index = []
      key.shift()
      key.forEach(element => {
        const lastChar = element[element.length - 1]
        index.push(parseInt(lastChar))
      })
      const attendeeNum = new Set(index)
      attendeeNum.forEach(value => {
        if (value === 0) {

          this.setState(Object.assign(formvalue, {
            attendee: [
              {
                first_name: fieldsValue.firstname_0 === undefined ? '' : fieldsValue.firstname_0,
                last_name: fieldsValue.lastname_0 === undefined ? '' : fieldsValue.lastname_0,
                nick_name: fieldsValue.nick_name_0 === undefined ? '' : fieldsValue.nick_name_0,
                gender: fieldsValue.gender_0 === undefined ? '' : fieldsValue.gender_0,
                nationality: fieldsValue.nationality_0 === undefined ? '' : fieldsValue.nationality_0,
                shirt_size: {
                  finisher: fieldsValue.ShirtSize_0 === undefined ? '' : fieldsValue.ShirtSize_0,
                  tshirt: fieldsValue.TShirtSize_0 === undefined ? '' : fieldsValue.TShirtSize_0,
                },
                birth_day: moment(`${fieldsValue.month_0}-${fieldsValue.date_0}-${fieldsValue.year_0}`, 'MMDDYYYY').format("YYYY-MM-DD") === undefined ? ''
                  : moment(fieldsValue.dateOfBirth_0).format("YYYY-MM-DD"),
                identity: fieldsValue.identity_0,
                race: {
                  id: fieldsValue.Racetype_0 === undefined ? '' : fieldsValue.Racetype_0
                },
                contact: {
                  phone: fieldsValue.phone_0 === undefined ? '' : fieldsValue.phone_0,
                  email: fieldsValue.email_0 === undefined ? '' : fieldsValue.email_0.trim()
                },
                address: {
                  street: fieldsValue.address_0 === undefined ? '' : fieldsValue.address_0,
                  city: fieldsValue.city_0 === undefined ? '' : fieldsValue.city_0,
                  state: fieldsValue.province_0 === undefined ? '' : fieldsValue.province_0,
                  country: fieldsValue.country_0 === undefined ? '' : fieldsValue.country_0,
                }
              }
            ]
          }))
        } else
          return false
      })
    });

    this.props.register(this.state.formvalue)
    history.push({
      pathname: '/TermsAndConditions'
    })
  }

  formSubmit = (e) => {
    // console.log(moment(`${this.state.year}-${this.state.month}-${this.state.day}`).format("YYYY-MM-DD"));
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (!err) {
        // rearrange data format to match the api
        const { formvalue } = this.state
        const key = Object.keys(fieldsValue)
        const index = []
        key.shift()
        key.forEach(element => {
          const lastChar = element[element.length - 1]
          index.push(parseInt(lastChar))
        })
        const attendeeNum = new Set(index)
        attendeeNum.forEach(value => {
          if (value === 0) {
            this.setState(Object.assign(formvalue, {
              attendee: [
                {
                  first_name: fieldsValue.firstname_0.replace(/\s/g, ''),
                  last_name: fieldsValue.lastname_0.replace(/\s/g, ''),
                  nick_name: fieldsValue.nick_name_0,
                  gender: fieldsValue.gender_0,
                  nationality: fieldsValue.nationality_0,
                  shirt_size: {
                    finisher: fieldsValue.FinisherShirtSize_0,
                    tshirt: fieldsValue.TShirtSize_0,
                  },
                  birth_day: moment(`${fieldsValue.month_0}-${fieldsValue.date_0}-${fieldsValue.year_0}`, 'MMDDYYYY').format("YYYY-MM-DD"),
                  identity: fieldsValue.identity_0,
                  race: {
                    id: this.state.races[0].id,
                    price: this.state.races[0].price
                  },
                  contact: {
                    phone: fieldsValue.phone_0,
                    email: fieldsValue.email_0.trim()
                  },
                  address: {
                    street: fieldsValue.address_0.replace(/\s/g, ''),
                    city: fieldsValue.city_0.replace(/\s/g, ''),
                    state: fieldsValue.province_0.replace(/\s/g, ''),
                    country: fieldsValue.country_0.replace(/\s/g, ''),
                  }
                }
              ]
            }))

            const { race } = this.state
            this.setState(Object.assign(race, {
              data: this.state.races
            }));

            const { finisher_shirt_size } = this.state
            this.setState(Object.assign(finisher_shirt_size, {
              data: this.state.finisher_shirt_sizes
            }));

            const { t_shirt_size } = this.state
            this.setState(Object.assign(t_shirt_size, {
              data: this.state.t_shirt_sizes
            }));

            this.setState({
              enableNext: true,
              current: 1
            })
            this.props.changeCurrent(this.state.current)
          } else
            return false
        })
        notification.success({
          message: "ບັນທຶກສຳເລັດ! Save successful!",
          description: "Your data have been save! ຂໍ້ມູນຂອງທ່ານໄດ້ຖືກບັນທຶກແລ້ວ",
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px green'
          },
          duration: 3
        })
      } else {
        notification.warning({
          message: <span className="txt-alert">ເກີດຂໍ້ຜິດພາດ! Error!</span>,
          description: <span className="txt-alert">Please Fill in the form completely! ກະລຸນາຕື່ມຂໍ້ມູນໃນຟອມໃຫ້ສຳເລັດກ່ອນ</span>,
          style: {
            backgroundColor: '#ffffffff',
            border: 'solid 1px red'
          },
          duration: 3
        })
      }
      this.props.register(this.state.formvalue)
      this.props.race(this.state.race)
      this.props.finisher_shirt_size(this.state.finisher_shirt_size)
      this.props.t_shirt_size(this.state.t_shirt_size)

      this.props.enableNext(this.state.enableNext)
      this.props.savePanes(this.state.panes)
    })
  }

  checkattendee = () => {
    setTimeout(() => { window.open('/checkattendee', '_blank') }, 1000)
  }

  componentDidMount = () => {
    Promise.all([axiosInstant.get(`/races`),
    axiosInstant.get('/shirt-sizes'),
    Axios.get('https://restcountries.eu/rest/v2/all'),
    axiosInstant.get(`/runnings`)]
    ).then(([{ data: { races } }, { data: { shirts } }, { data }, { data: { runs } }]) => {
      let no_lao = data.filter(data => data.name !== "Lao People's Democratic Republic");
      let lao = data.filter(data => data.name === "Lao People's Democratic Republic");
      const unique = [...new Set(data.map(item => item.demonym))];

      let finisher_shirt_sizes = shirts.filter(shirt => shirt.shirt_type_id === "FINISHER");;
      let t_shirt_sizes = shirts.filter(shirt => shirt.shirt_type_id === "TSHIRT");

      this.setState({
        races,
        all_race: races,
        no_limit_age_race: races.filter(race => race.name === "5 KM"),
        finisher_shirt_sizes: finisher_shirt_sizes, // Finisher Shirt Sizes
        t_shirt_sizes: t_shirt_sizes,
        countries: no_lao,
        laos: lao,
        nationalities: unique,
        isFull: runs.length < 100 ? false : true,
        isLoading: false,
      })

      this.dateSelectOption(31);

      const { attendee } = this.props.Register.formvalue

      const { setFieldsValue } = this.props.form

      attendee.map(attendee => {
        if (attendee.first_name !== "") {
          setFieldsValue({
            ["firstname_" + 0]: attendee.first_name,
            ["lastname_" + 0]: attendee.last_name,
            ["nick_name_" + 0]: attendee.nick_name,
            ["email_" + 0]: attendee.contact.email,
            ["phone_" + 0]: attendee.contact.phone,
            ["phoneEmergency_" + 0]: attendee.contact.phone,
            ["gender_" + 0]: attendee.gender,
            ["FinisherShirtSize_" + 0]: attendee.shirt_size.finisher,
            ["TShirtSize_" + 0]: attendee.shirt_size.tshirt,
            ["address_" + 0]: attendee.address.street,
            ["city_" + 0]: attendee.address.city,
            ["province_" + 0]: attendee.address.state,
            ["country_" + 0]: attendee.address.country,
            ["date_" + 0]: moment(attendee.birth_day, "YYYY-MM-DD").date(),
            ["month_" + 0]: moment(attendee.birth_day, "YYYY-MM-DD").month() + 1,
            ["year_" + 0]: moment(attendee.birth_day, "YYYY-MM-DD").year(),
            ["nationality_" + 0]: attendee.nationality,
            ["Racetype_" + 0]: attendee.race.id,
            ["identity_" + 0]: attendee.identity,
          })
        }
      })

    }).catch(error => {
      this.setState({
        isError: true,
        isLoading: false
      });
    })

    // Promise.all([axiosInstant.get('/races'), (axiosInstant.get('/shirt-sizes'))]).then(([{ data: { races } }, { data: { shirts } }]) => {
    //   this.setState({
    //     races,
    //     shirt_sizes: shirts,
    //     isLoading: true
    //   })
    // }).catch(error => {

    // })
  }

  componentWillUnmount = () => {
  }

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;

    const firstnameErr = isFieldTouched(`firstname_0`) && getFieldError(`firstname_0`);
    const lastnameErr = isFieldTouched(`lastname_0`) && getFieldError(`lastname_0`);
    const nick_nameErr = isFieldTouched(`nick_name_0`) && getFieldError(`nick_name_0`);
    const genderErr = isFieldTouched(`gender_0`) && getFieldError(`gender_0`);
    const nationalityErr = isFieldTouched(`nationality_0`) && getFieldError(`nationality_0`);
    const emailErr = isFieldTouched(`email_0`) && getFieldError(`email_0`);
    const phoneErr = isFieldTouched(`phone_0`) && getFieldError(`phone_0`);
    const addressErr = isFieldTouched(`address_0`) && getFieldError(`address_0`);
    const cityErr = isFieldTouched(`city_0`) && getFieldError(`city_0`);
    const provinceErr = isFieldTouched(`province_0`) && getFieldError(`province_0`);
    const countryErr = isFieldTouched(`country_0`) && getFieldError(`country_0`);
    // const racetypeErr = isFieldTouched(`Racetype_0`) && getFieldError(`Racetype_0`);
    const finisherShirtsizeErr = isFieldTouched(`FinisherShirtSize_0`) && getFieldError(`FinisherShirtSize_0`);
    const tShirtsizeErr = isFieldTouched(`TShirtSize_0`) && getFieldError(`TShirtSize_0`);
    const identityErr = isFieldTouched(`identity_0`) && getFieldError(`identity_0`);

    const dateErr = isFieldTouched(`date_0`) && getFieldError(`date_0`);
    const monthErr = isFieldTouched(`month_0`) && getFieldError(`month_0`);
    const yearErr = isFieldTouched(`year_0`) && getFieldError(`year_0`);


    // User agreement
    const AgreeErr = isFieldTouched('Agreement') && getFieldError('Agreement')
    const { agreement, isLoading, isError, isFull } = this.state
    let color
    (agreement === true) ? color = "green" : color = "red"

    return (
      <>
        <BannerComponent />

        <Navbar />

        {
          isError &&
          <div>
            <img src={errorPic} alt="loading" style={{ display: "block", marginInline: "auto", width: isMobile ? "100vw" : "50vw" }} />
          </div>
        }

        {
          isLoading &&
          <div>
            <img src={loadingPic} alt="loading" style={{ display: "block", marginInline: "auto" }} />
          </div>
        }

        {
          !isLoading && !isError && !isFull &&
          <div style={{ overflowY: "hidden" }}>

            <Row type="flex" className={isMobile ? "mg-left-20 mg-right-20" : "mg-left-50 mg-right-50"}>
              <Form style={{ width: '100%' }} onSubmit={this.formSubmit}>
                <MainRegis
                  regis_id={0}
                  firstnameErr={firstnameErr}
                  lastnameErr={lastnameErr}
                  nick_nameErr={nick_nameErr}
                  genderErr={genderErr}
                  nationalityErr={nationalityErr}
                  emailErr={emailErr}
                  phoneErr={phoneErr}
                  addressErr={addressErr}
                  cityErr={cityErr}
                  provinceErr={provinceErr}
                  countryErr={countryErr}
                  // racetypeErr={racetypeErr}
                  onDateofBirthChange={e => { this.onDateofBirthChange(e) }}
                  onGenderChange={e => { this.onGenderChange(e) }}
                  onRaceCatChange={e => { this.onRaceCatChange(e) }}
                  onFinisherShirtChange={e => { this.onFinisherShirtChange(e) }}
                  onTShirtChange={e => { this.onTShirtChange(e) }}
                  onCountryChange={e => { this.onCountryChange(e) }}
                  onNationalityChange={e => { this.onNationalityChange(e) }}
                  attendeeAge={this.state.attendeeAge}
                  attendeeGender={this.state.attendeeGender}
                  form={this.props.form}
                  races={this.state.races}
                  finisherShirtsizeErr={finisherShirtsizeErr}
                  finisher_shirt_sizes={this.state.finisher_shirt_sizes}
                  tShirtsizeErr={tShirtsizeErr}
                  t_shirt_sizes={this.state.t_shirt_sizes}
                  identityErr={identityErr}
                  countries={this.state.countries}
                  nationalities={this.state.nationalities}
                  laos={this.state.laos}
                  onChangeDate={e => { this.onChangeDate(e) }}
                  onChangeMounth={e => { this.onChangeMounth(e) }}
                  onChangeYear={e => { this.onChangeYear(e) }}
                  days={this.state.days}
                  months={this.state.months}
                  years={this.state.years}
                  dateErr={dateErr}
                  monthErr={monthErr}
                  yearErr={yearErr}
                />

                <div className="container mg-top-30">
                  <Row type="flex" gutter={12}>
                    <Col>
                      <Form.Item validateStatus={AgreeErr ? 'error' : ''} help={AgreeErr || ''}>
                        {getFieldDecorator('Agreement', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{ required: true }],
                          valuePropName: 'checked',
                        })(
                          <Checkbox onChange={this.checkedbox}></Checkbox>
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Row type="flex"><span className="form-label-25" style={{ color: color }}>ກະລຸນາກົດເພື່ອຍອມຮັບ<a onClick={() => {
                        this.onClickTermsAndConditions();
                      }}><span className="form-label-25" style={{ textDecoration: "underline" }}> ເງື່ອນໄຂການເຂົ້າຮ່ວມງານ.</span></a></span></Row>
                      <Row type="flex"><span className="form-label-25" style={{ color: color }}>Please check to accept our <a onClick={() => {
                        this.onClickTermsAndConditions();
                      }}><span className="form-label-25" style={{ textDecoration: "underline" }}> terms and conditions.</span></a></span></Row>
                    </Col>
                  </Row>
                </div>
                {
                  this.state.showbutton &&
                  <div className="mg-top-20">
                    <Row type="flex" justify="center" align="middle">
                      <Button
                        style={{ backgroundColor: '#de224c', width: 'auto', height: '50px', borderRadius: '10px' }}
                        htmlType="submit">
                        <span className="white-txt txt-bold txt-submit-btn">ບັນທຶກຂໍ້ມູນ / Save</span>
                      </Button>
                    </Row>
                  </div>
                }
              </Form>
            </Row>
          </div>}

        {
          !isLoading && !isError && isFull &&

          <Row type="flex" justify="center" className={isMobile ? "mg-left-20 mg-right-20" : "mg-left-50 mg-right-50" + " mg-top-50 mg-bottom-50"}>
            <Col>
              <span className="form-title-red txt-center" style={{ color: 'red', fontWeight: 'bold', fontSize: '24px' }}>ການລົງທະບຽນປິດແລ້ວ Registration Closed</span> <br />
            </Col>
          </Row>
        }
      </>
    )
  }
}

const mapDispatchToProps = {
  register,
  race,
  finisher_shirt_size,
  t_shirt_size,
  enableNext,
  changeCurrent,
  savePanes
}

const mapStateToProps = (state) => ({
  Register: state.register
})

const FormcomponentWrap = Form.create()(FormPage);

export default connect(mapStateToProps, mapDispatchToProps)(FormcomponentWrap)