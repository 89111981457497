import { Icon, Menu, Row } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import "./style.css";

const path_names = [
  {
    path: "/home",
    name: "ໜ້າຫຼັກ",
  },
  {
    path: "/register",
    name: "ລົງທະບຽນ",
  },
  {
    path: "/submitresult",
    name: "ສົ່ງຜົນການແລ່ນ",
  },
  {
    path: "/result",
    name: "ຄົ້ນຫາຜົນທີ່ສົ່ງແລ້ວ",
  },
  {
    path: "/checkattendee",
    name: "ກວດສອບລາຍການລົງທະບຽນ",
  },
]

export default function Navbar() {

  const history = useHistory();
  useEffect(() => {
    // console.log(history.location.pathname); 
  }, [])

  return (
    <>
      {!isMobile &&
        <Row justify="center" type="flex" style={{ backgroundColor: "#fff" }}>
          <Menu selectedKeys={history.location.pathname} mode="horizontal" theme="light">
            {path_names.map(path_name => {
              return (
                <Menu.Item key={path_name.path} style={{ paddingBlock: "10px", fontWeight: "bold", fontSize: "12pt" }}>
                  <Link to={path_name.path}>
                    {path_name.name}
                  </Link>
                </Menu.Item>
              )
            })}
          </Menu>
        </Row>
      }
      {isMobile &&
        <div>
          <Menu selectedKeys={history.location.pathname} mode="inline" theme="dark">
            <SubMenu key="sub1" title={
              <Icon type="unordered-list" style={{ fontSize: "16pt" }} />
            } >
              {path_names.map(path_name => {
                return (
                  <Menu.Item key={path_name.path}>
                    <Link to={path_name.path}>
                      <Icon style={{ color: '#ffffff' }} type={path_name.icon} />
                      {path_name.name}
                    </Link>
                  </Menu.Item>
                )
              })}
            </SubMenu>
          </Menu>
        </div>
      }
    </>
  )
}
