import { Row, Typography } from 'antd'
import React from 'react'
import { isMobile } from 'react-device-detect';
import BannerComponent from '../../components/banner'
import Navbar from '../../components/navbar';
import PowerBy from '../../components/powerby'

const { Title } = Typography;

export default function CloseSubmit() {
  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <BannerComponent />
      <Navbar />
      <Row type="flex" justify="center" style={{ paddingTop: "20vh", paddingInline: "20px" }}>
        <Title style={{ color: "#de224c" }} level={isMobile ? 3 : 1}>
          ທ່ານສາມາດສົ່ງຜົນການແລ່ນໄດ້ພາຍຫຼັງ ວັນທີ 1 ພຶດສະພາ 2021 ເປັນຕົ້ນໄປ.
      </Title>
      </Row>
      <PowerBy />
    </div>
  )
}
